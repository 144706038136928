import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getServiceApi, getCategoryApi, helpNannyRegisterApi } from './Action';

import Family from "../assets/img/family.jpg";
import Right_Arrow from "../assets/img/right_arrow.svg";
import Nanny from "../assets/img/nanny.jpg";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { registerNannyHelp } from "../Utils/ValidatianSchema";
import RenderLoader from "../Components/RenderLoader";
import { toast } from "react-hot-toast";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const Register = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState(false);
  const [registerNanny, setRegisterNanny] = useState({});
  const [registerEmployer, setRegisterEmployer] = useState({});
  const [jobCategoryList, setJobCategoryList] = useState([]);

  const serviceApi = async () => {
    await getServiceApi('register-for-job').then(response => {
      if (response.status) {
        setRegisterEmployer(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })

    await getServiceApi('register-nanny').then(response => {
      if (response.status) {
        setRegisterNanny(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };

  const categoryList = async () => {
    setLoader(true);
    await getCategoryApi().then(async response => {
      if (response.status) {
        setJobCategoryList(response?.data);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true);
    await helpNannyRegisterApi(values).then(response => {
      if (response.status) {
        toast.success(response.message);
        resetForm();
        setShow(false);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
    // console.log(values);
  };

  useEffect(() => {
    window.document.title="Register : Maids & Nanny";
    window.scroll(0, 0);
    categoryList();
    serviceApi();
  }, []);
  return (
    <>
      <div className="main_sec">

        {
          loader ?
            <>
              <section className="register_s">
                <div className="container">

                  <RenderLoader trans={true} />
                  <Skeleton count={5} height={30} highlightColor="#D3D3D3" enableAnimation="true" />
                </div>
              </section>
            </>
            : <>
              <section className="register_s">
                <div className="container">
                  <div className="register_s_in">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="register_s_img">
                          <img src={Family} />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="register_s_con">
                          <div className="heading_sec2">
                            <h3>{registerNanny?.title} </h3>
                          </div>
                          <div className="register_s_pera">
                            <p dangerouslySetInnerHTML={{ __html: registerNanny?.description }}></p>
                            <Link
                              to="/registerformnanny"
                              className="btn btn-type2 mt-2"
                            >
                              <span>Register Now</span>
                              <i>
                                <img src={Right_Arrow} />
                              </i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="register_s_in">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="register_s_img">
                          <img src={Nanny} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="register_s_con">
                          <div className="heading_sec2">
                            <h3>{registerEmployer?.title}</h3>
                          </div>
                          <div className="register_s_pera">
                            <p dangerouslySetInnerHTML={{ __html: registerEmployer?.description }}></p>
                            <Link to="/registerforjob" className="btn btn-type2 mt-2">
                              <span>Register Now</span>
                              <i>
                                <img src={Right_Arrow} />
                              </i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="registration_prb bg_type1">
                <div className="container">
                  <div className="registration_prb_in">
                    <div className="heading_sec1">
                      <h3>Contact Us</h3>
                    </div>
                    <div className="registration_prb_con">
                      <p>
                      Facing issues? Get in touch with our team. 
                      </p>
                      <button className="btn btn-type2 mt-2" onClick={handleShow}>
                        <span>Contact Us</span>
                        <i>
                          <img src={Right_Arrow} />
                        </i>
                      </button>
                    </div>
                    <div>
                      <Modal
                        className="search-modal"
                        show={show}
                        onHide={handleClose}
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>

                          <div className="modal-body">
                            <div className="heading_sec1">
                              <h3>Contact Now</h3>
                              <p>
                              Facing issues? Get in touch with our team.
                              </p>
                            </div>
                            <div className="modal_content">
                              <Formik
                                initialValues={{
                                  job_type: "",
                                  mobile: "",
                                  whatsAppNumber: "",
                                  message: "",
                                }}
                                validationSchema={registerNannyHelp}
                                onSubmit={handleSubmit}
                              >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                  <Form onSubmit={handleSubmit}>
                                    <div className="row">

                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Field
                                            as="select"
                                            name="job_type"
                                            className="form-control"
                                          >
                                            <option value="">Select job type</option>
                                            {jobCategoryList?.length > 0 ?
                                              jobCategoryList?.map((item, index) =>
                                                <option value={item?._id} key={index}>{item?.name}</option>
                                              )
                                              : ""}
                                          </Field>
                                          <ErrorMessage
                                            name="job_type"
                                            component="div"
                                            className="error"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Field
                                            type="text"
                                            name="mobile"
                                            className="form-control"
                                            placeholder="Enter Mobile Number"
                                            autoComplete="off"
                                          />
                                          <ErrorMessage
                                            name="mobile"
                                            component="div"
                                            className="error"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Field
                                            type="text"
                                            name="whatsAppNumber"
                                            className="form-control"
                                            placeholder="Enter whats app Number"
                                            autoComplete="off"
                                          />
                                          <ErrorMessage
                                            name="whatsAppNumber"
                                            component="div"
                                            className="error"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <Field
                                            as="textarea"
                                            name="message"
                                            className="form-control"
                                            rows="4"
                                            placeholder="your message"
                                            autoComplete="off"
                                          />
                                          <ErrorMessage
                                            name="message"
                                            component="div"
                                            className="error"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group mb-0 mt-1 text-center">
                                          <button type="submit" className="btn btn-type1" disabled={isSubmitting}>
                                            Send Message
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </section>
            </>
        }

      </div>
    </>
  );
};

export default Register;
