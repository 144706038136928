import React, { useEffect } from "react";
import ServicePlanSection from "./Home/ServicePlanSection";
import { useNavigate } from "react-router-dom";

const ServicePlan = () => {
  const history = useNavigate();
  useEffect(() => {
    window.document.title="Service Plan : Maids & Nanny";
    window.scroll(0, 0);

    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (sessionStorage.getItem('token') && userDetails?.role == 2) {
      history("/");
    }

  }, [])
  return (
    <>
      <div className="main_sec">

        <section className="plan_sec">
          <div className="container">
            <div className="plan_sec_in">
              <ServicePlanSection />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServicePlan;
