import React, { useState, useEffect, useRef } from "react";
import Upload from "../assets/img/upload.svg";
import Calender1 from "../assets/img/calender1.svg";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { editEmployerProfile, otpValidation } from "../Utils/ValidatianSchema";
import {
  getCategoryApi,
  getLocationApi,
  editUserProfileApi,
  verfyOtpApi,
  getEditUser,
  getNationalityApi,
  getReferenceApi,
} from "./Action";
import SelectField from "./CustomSelect";
import MobileField from "./CustomMobile";
import TextBoxField from "./CustomTextBox";
import RenderLoader from "../Components/RenderLoader";
import toast from "react-hot-toast";
import { EmployerSidebar } from "../Pages/LoginSidebar";

const EmployerEdit = () => {
  const history = useNavigate();
  const [loader, setLoader] = useState(true);
  const [inputValues, setInputValues] = useState({});
  const [categoryOption, setCategoryOption] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [selectedOptionsLocation, setSelectedOptionsLocation] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [referenceListOption, setReferenceListOption] = useState([]);

  const categoryList = async () => {
    setLoader(true);
    await getCategoryApi().then(async (response) => {
      if (response.status) {
        const categoryArr = await makeReactSelect(response?.data);
        setCategoryOption(categoryArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const locationList = async () => {
    setLoader(true);
    await getLocationApi().then(async (response) => {
      if (response.status) {
        const locationArr = await makeReactSelect(response?.data);
        setLocationOption(locationArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, value: item?._id, key: index };
      arr.push(obj);
    });
    return arr;
  };

  // // for  modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // for email modal
  const [showEmail, setShowEmail] = useState(false);
  const handleCloseEmail = () => setShowEmail(false);

  const handleVerifyMobile = async (values) => {
    setLoader(true);
    // console.log("***mobile otp verify***", values);
    await verfyOtpApi(values).then((response) => {
      if (response.status) {
        toast.success(response.message);
        setShowEmail(true);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };

  const handleVerifyEmail = async (values) => {
    setLoader(true);
    // console.log("***email otp verify***", values);
    await verfyOtpApi(values).then((response) => {
      if (response.status) {
        toast.success(response.message);
        setShowEmail(false);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };

  const handleSubmitForm = async (values) => {
    if(values?.job_array){
      values.job_category = values?.job_array?.map((item) => {
        return item?.value;
      });
    }else{
      values.job_category=[];
    }
    if(values?.location_array){
      values.location = values?.location_array?.map((item) => {
        return item?.value;
      });
    }else{
      values.location=[];
    }
    values.prefer_nationality = (values?.prefer_nationality)?values?.prefer_nationality:null;
    setLoader(true);
    await editUserProfileApi(values).then((response) => {
      if (response.status) {
        setLoader(false);
        toast.success(response.message);
        history("/empProfile");
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
    // console.log(values);
  };

  const fetchData = async () => {
    setLoader(true);
    await getEditUser().then(async (response) => {
      if (response.status) {
        setLoader(false);
        setInputValues(response.data);
        const categoryOpt = await makeReactSelect(response.data?.job_category);
        setSelectedOptions(categoryOpt);
        const locationOpt = await makeReactSelect(response.data?.location);
        setSelectedOptionsLocation(locationOpt);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });

    //------fetching Nationality list------//
    await getNationalityApi().then((response) => {
      if (response?.status) {
        setNationalityList(response.data);
      }
    });

    //------reference list------//
    await getReferenceApi().then(async (response) => {
      if (response?.status) {
        const liveArr = await makeArrayReactSelect(response?.data);
        setReferenceListOption(liveArr);
      }
    });
  };

  const makeArrayReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item, key: index, value: item };
      arr.push(obj);
    });
    return arr;
  };

  useEffect(() => {
    window.document.title = "Edit profile : Maids & Nanny";
    fetchData();
    categoryList();
    locationList();
  }, []);
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">
            <EmployerSidebar />
            {loader ? <RenderLoader trans={true} /> : null}

            <div className="profile_content">
              <div className="profile_form">
                <h4>Edit Profile</h4>
                <Formik
                  initialValues={{
                    role: 1,
                    first_name: inputValues?.first_name,
                    last_name: inputValues?.last_name,
                    email: inputValues?.email,
                    mobile: inputValues?.mobile,
                    whatsAppNumber: inputValues?.whatsAppNumber,
                    job_array: selectedOptions,
                    location_array: selectedOptionsLocation,
                    offer_salary: inputValues?.offer_salary,
                    prefer_nationality: inputValues?.prefer_nationality,
                    other_requirement: inputValues?.other_requirement,
                    referenceby: inputValues?.referenceby,
                  }}
                  validationSchema={editEmployerProfile}
                  onSubmit={handleSubmitForm}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>
                              First Name <red>*</red>
                            </label>
                            <Field
                              type="text"
                              name="first_name"
                              placeholder="Enter First Name"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>
                              Last Name <red>*</red>
                            </label>
                            <Field
                              type="text"
                              name="last_name"
                              placeholder="Enter Last Name"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>
                              Mobile Number <red>*</red>
                            </label>

                            <Field
                              component={MobileField}
                              name="mobile"
                              type="tel"
                              required="true"
                            />
                            <ErrorMessage
                              name="mobile"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>
                              WhatsApp Number <red>*</red>
                            </label>
                            <Field
                              component={MobileField}
                              name="whatsAppNumber"
                              type="tel"
                              required="true"
                            />
                            <ErrorMessage
                              name="whatsAppNumber"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>
                              Email Address <red>*</red>
                            </label>
                            <Field
                              type="text"
                              name="email"
                              placeholder="Enter Email Address"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            {/* <label>Offered Salary (Monthly – AED)</label> */}
                            <label>
                              Salary Range (monthly - AED)
                            </label>
                            <Field
                              type="text"
                              name="offer_salary"
                              placeholder="Enter Salary Range (Monthly) like 1200-1500"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="offer_salary"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label htmlFor="job_array">
                              Looking For
                            </label>
                            <Field
                              component={SelectField}
                              name="job_array"
                              options={categoryOption}
                            />
                            <ErrorMessage
                              name="job_array"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label htmlFor="location_array">
                              Location
                            </label>
                            <Field
                              component={SelectField}
                              name="location_array"
                              options={locationOption}
                            />
                            <ErrorMessage
                              name="location_array"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Any preference in terms of nationality?
                            </label>
                            {/* <Field
                              type="text"
                              name="prefer_nationality"
                              placeholder="Enter Prefer nationality name"
                              className="form-control"
                              autoComplete="off"
                            /> */}
                            <Field
                              as="select"
                              name="prefer_nationality"
                              className="form-control"
                            >
                              <option value="">Select Nationality</option>
                              {nationalityList?.length > 0
                                ? nationalityList?.map((item, index) => (
                                    <option key={index} value={item?._id}>
                                      {item?.name}
                                    </option>
                                  ))
                                : null}
                            </Field>
                            <ErrorMessage
                              name="prefer_nationality"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              How did you hear about us?
                            </label>
                            {/* <Field
                              type="text"
                              name="referenceby"
                              placeholder="Enter your source"
                              className="form-control"
                              autoComplete="off"
                            /> */}
                            <Field
                              as="select"
                              name="referenceby"
                              className="form-control"
                            >
                              <option value="">Select an option</option>
                              {referenceListOption?.length > 0
                                ? referenceListOption?.map((item, index) => (
                                    <option key={index} value={item.value}>
                                      {item?.value}
                                    </option>
                                  ))
                                : null}
                            </Field>
                            <ErrorMessage
                              name="referenceby"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Other requirements
                            </label>
                            <Field
                              component={TextBoxField}
                              name="other_requirement"
                            />
                            <ErrorMessage
                              name="other_requirement"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-12">
                          <div className="form-group mt-1">
                            <button
                              type="submit"
                              className="btn btn-type1"
                              disabled={isSubmitting}
                            >
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmployerEdit;
