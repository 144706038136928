import { postMethod, putMethod, deleteMethod, getMethod } from '../Utils/Service';



const blogListingApi = async (data) => {
    const returnData = await postMethod('common/blogs', data)
    return returnData;
}

const blogDetailsApi = async (id) => {
    const returnData = await getMethod('common/blogs/' + id)
    return returnData;
}
const getCategoryApi = async () => {
    const returnData = await getMethod('common/jobCategory')
    return returnData;
}

const getPositionApi = async () => {
    const returnData = await getMethod('common/live_status')
    return returnData;
}

const getReferenceApi = async () => {
    const returnData = await getMethod('common/referenceList')
    return returnData;
}

const getVisaOptionListApi = async () => {
    const returnData = await getMethod('common/visaOptionList')
    return returnData;
}

const getLocationApi = async () => {
    const returnData = await getMethod('common/location')
    return returnData;
}

const getStateListApi = async () => {
    const returnData = await getMethod('common/stateList')
    return returnData;
}

const getNationalityApi = async () => {
    const returnData = await getMethod('common/nationality')
    return returnData;
}

const getReligionApi = async () => {
    const returnData = await getMethod('common/religion')
    return returnData;
}

const getExperienceWithKidApi = async () => {
    const returnData = await getMethod('common/experienceWithKid')
    return returnData;
}

const getServiceApi = async (slug) => {
    const returnData = await getMethod('common/service/' + slug)
    return returnData;
}
const getTotalNanniesApi = async (slug) => {
    const returnData = await getMethod('common/totalNannies')
    return returnData;
}

const getLastPostOnApi = async () => {
    const returnData = await getMethod('common/lastPostOn')
    return returnData;
}

const getPlantypeApi = async (slug) => {
    const returnData = await getMethod('common/plan_slug/' + slug)
    return returnData;
}

const getPlantypeByIdApi = async (id) => {
    const returnData = await getMethod('common/plan/' + id)
    return returnData;
}

const getLatestBlogsApi = async (data) => {
    const returnData = await postMethod('common/latestBlogs', data)
    return returnData;
}

const getFAQsApi = async (slug) => {
    const returnData = await getMethod('common/faqs')
    return returnData;
}

const getCountryApi = async () => {
    const returnData = await getMethod('common/country')
    return returnData;
}

const getStateApi = async (country) => {
    const returnData = await getMethod('common/state/' + country)
    return returnData;
}

const getCityApi = async (state) => {
    const returnData = await getMethod('common/city/' + state)
    return returnData;
}

const getLangaugeApi = async () => {
    const returnData = await getMethod('common/langauge')
    return returnData;
}

const getSkillApi = async () => {
    const returnData = await getMethod('common/skill')
    return returnData;
}

const nannyProfileListingApi = async (data) => {
    const returnData = await postMethod('common/nanny', data)
    return returnData;
}

const getNannyDetailsApi = async (id) => {
    const returnData = await getMethod('common/nanny/' + id)
    return returnData;
}

const contactUsApi = async (data) => {
    const returnData = await postMethod('common/contactus', data)
    return returnData;
}

const otherActionApi = async (data) => {
    const returnData = await postMethod('common/other', data)
    return returnData;
}

const helpNannyRegisterApi = async (data) => {
    const returnData = await postMethod('common/helpNannyRegister', data)
    return returnData;
}
const addVisaApi = async (data) => {
    const returnData = await postMethod('common/visa/add', data)
    return returnData;
}

const addEmployerApi = async (data) => {
    const returnData = await postMethod('user/addEmployer', data)
    return returnData;
}

const editUserProfileApi = async (data) => {
    const returnData = await postMethod('registerUser/userUpdate', data)
    return returnData;
}

const addNannyApi = async (data) => {
    const returnData = await postMethod('user/addNanny', data)
    return returnData;
}

const verfyOtpApi = async (data) => {
    const returnData = await postMethod('user/verifyMobileOtp', data)
    return returnData;
}

const updateNannyStatus = async (data) => {
    const returnData = await postMethod('user/updateStatus', data)
    return returnData;
}

const changePasswordApi = async (data) => {
    const returnData = await postMethod('user/changePassword', data)
    return returnData;
}

const forgotPinApi = async (data) => {
    const returnData = await postMethod('user/forgotPasswordLoginPin', data)
    return returnData;
}

const forgotPasswordApi = async (data) => {
    const returnData = await postMethod('user/forgotPassword', data)
    return returnData;
}

const shortlistedListApi = async (data) => {
    const returnData = await postMethod('shortlisted', data)
    return returnData;
}

const addShortlistedListApi = async (data) => {
    const returnData = await postMethod('shortlisted/add', data)
    return returnData;
}

const resendOtpApi = async (data) => {
    const returnData = await postMethod('user/resendOtp', data)
    return returnData;
}

const showNannyVideo = async (id) => {
    const returnData = await getMethod('common/show_video/' + id)
    return returnData;
}

const showNannyContactInfo = async (id) => {
    const returnData = await getMethod('common/show_contact/' + id)
    return returnData;
}

const likeNannyProfilesShow = async (id) => {
    const returnData = await getMethod('common/show_like/' + id)
    return returnData;
}

const getUserDetails = async () => {
    const returnData = await getMethod('user/getUser');
    return returnData;
}

const getSubscribePlanDetails = async () => {
    const returnData = await getMethod('user/getSubscribePlan');
    return returnData;
}

const getEditUser = async () => {
    const returnData = await getMethod('registerUser/userEdit');
    return returnData;
}

const couponApplyApi = async (data) => {
    const returnData = await postMethod('common/applyCoupon', data)
    return returnData;
}

const stripeChargeApi = async (data) => {
    const returnData = await postMethod('common/stripe/charge', data)
    return returnData;
}

const paymentStatusApi = async (data) => {
    const returnData = await postMethod('common/paymentStatus', data)
    return returnData;
}


export {
    blogListingApi,
    blogDetailsApi,
    getCategoryApi,
    getPositionApi,
    getReferenceApi,
    getVisaOptionListApi,
    getLocationApi,
    getStateListApi,
    getNationalityApi,
    getReligionApi,
    getExperienceWithKidApi,
    getServiceApi,
    getTotalNanniesApi,
    getLastPostOnApi,
    getPlantypeApi,
    getPlantypeByIdApi,
    getLatestBlogsApi,
    getFAQsApi,
    nannyProfileListingApi,
    getNannyDetailsApi,
    contactUsApi,
    helpNannyRegisterApi,
    addVisaApi,
    addEmployerApi,
    verfyOtpApi,
    getCountryApi,
    getCityApi,
    getStateApi,
    addNannyApi,
    getLangaugeApi,
    getSkillApi,
    otherActionApi,
    getUserDetails,
    getSubscribePlanDetails,
    updateNannyStatus,
    changePasswordApi,
    forgotPinApi,
    forgotPasswordApi,
    shortlistedListApi,
    getEditUser,
    editUserProfileApi,
    addShortlistedListApi,
    resendOtpApi,
    showNannyVideo,
    showNannyContactInfo,
    likeNannyProfilesShow,
    couponApplyApi,
    stripeChargeApi,
    paymentStatusApi

};