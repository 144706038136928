import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { paymentDetails } from "../Utils/ValidatianSchema";
import RenderLoader from "../Components/RenderLoader";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MasterCard from "../assets/img/MasterCard.svg";
import visa from "../assets/img/visa.svg";
import stripeImage from "../assets/img/stripe.svg";
import thankyou from "../assets/img/thankyou.svg";
import premium_icon from "../assets/img/premium-icon.svg";
import Modal from "react-bootstrap/Modal";
import { convertDateFomrat, dateFormatConverter } from "../Utils/Logic";

// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { stripeChargeApi, paymentStatusApi } from "./Action";
import { GuestUserModalContext } from "./Context/GuestUserModalContext";

function StripeContainer(props) {
  const { showGuestModal } = useContext(GuestUserModalContext);
  const [loader, setLoader] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [subscribePlanDetails, setSubscribePlanDetails] = useState({});

  const [btn, setPaybtn] = useState(false);
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "black",
        fontWeight: 400,
        padding: "1px",
        border: "1px solid #C19444",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "22px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "black" },
        "::placeholder": { color: "gray" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "red",
      },
    },
  };

  const handlePaymentSubmit = async (values) => {
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (
      sessionStorage.getItem("token") &&
      sessionStorage.getItem("user_details") &&
      userDetails?.role == 1
    ) {
      if (!stripe || !elements) {
        return;
      }

      setLoader(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(
          CardCvcElement,
          CardExpiryElement,
          CardNumberElement
        ),
      });
      if (!error) {
        setPaybtn(true);
        const { id } = paymentMethod;
        const { clientSecret, subscribe } = await stripeChargeApi({
          payment_method: id,
          planId: props.planId,
          coupon: props.coupon,
        }).then(async (response) => {
          if (response.status) {
            return {
              clientSecret: response.data,
              subscribe: response.subscribe,
            };
          } else {
            toast.error(response.message);
            setLoader(false);
            showGuestModal();
            return;
          }
        });
        if (clientSecret) {
          const { error: stripeError, paymentIntent } =
            await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: elements.getElement(
                  CardCvcElement,
                  CardExpiryElement,
                  CardNumberElement
                ),
              },
            });

          if (stripeError) {
            // Show error to your customer (e.g., insufficient funds)
            const postData = {
              paymentId: stripeError?.payment_intent?.id,
              subscribeId: subscribe,
            };
            paymentStatus(postData);
            setPaybtn(false);
            toast.error(stripeError.message);
            //payment failed code comes here like popup or something else
            setLoader(false);
            return;
          }

          if (paymentIntent.status === "succeeded") {
            const postData = {
              paymentId: paymentIntent?.id,
              subscribeId: subscribe,
            };
            paymentStatus(postData);
            toast.success(paymentIntent.status);
            setLoader(false);
          }
        }
      } else {
        setLoader(false);
        setPaybtn(false);
        toast.error(error.message);
        console.log(error.message);
      }
    } else {
      showGuestModal();
      return;
    }
  };

  const paymentStatus = async (postData) => {
    setLoader(true);
    await paymentStatusApi(postData).then(async (response) => {
      if (response.status) {
        // console.log("*******response*******", response);
        setSubscribePlanDetails(response.data);
        sessionStorage.removeItem("coupon");
        sessionStorage.setItem("plan_subscribed", true);
        //------set check for subscription -------//
        handleShow();
        setLoader(false);
      } else {
        // toast.error(response.message);
        // console.log("*******response.message*******", response.message);
        setLoader(false);
      }
    });
  };
  useEffect(() => {}, []);

  return (
    <>
      {loader ? <RenderLoader trans={true} /> : null}
      <Formik
        initialValues={{
          cardnumber: "",
          exp_month: "",
          exp_year: "",
          cvc: "",
        }}
        onSubmit={handlePaymentSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="col-md-12">
              <div className="payment_method form-group">
                <label>Enter Card Details:</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <CardNumberElement options={CARD_OPTIONS} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <CardExpiryElement options={CARD_OPTIONS} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <CardCvcElement options={CARD_OPTIONS} />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-0 mt-1 text-center">
                  {btn == false ? (
                    <button
                      type="submit"
                      disabled={btn}
                      className="btn btn-type1"
                    >
                      Pay Now
                    </button>
                  ) : (
                    <button disabled={btn} className="btn btn-type1">
                      Please Wait
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Modal className="search-modal" show={show}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="thank_you_image">
              <img src={thankyou} />
            </div>
            <div className="heading_sec1">
              <h3>Thank You!</h3>
              <p>
              Your subscription plan has been successfully activated with us. You can now view the contact details and connect with Maids and househelp.
              </p>
            </div>
            <div className="modal_content">
              <div className="plan_modal_detail">
                <p>Plan Detail:</p>
                {subscribePlanDetails?.startDate
                  ? "Start Date : " +
                    dateFormatConverter(subscribePlanDetails?.startDate) +
                    " - End Date :" +
                    dateFormatConverter(subscribePlanDetails?.endDate)
                  : ""}
                <div className="plan_modal_detail_in">
                  <img src={premium_icon} />
                  <span>{subscribePlanDetails?.planId?.name + " Plan"}</span>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="form-group mb-0 mt-1 text-center">
                    <Link to="/" className="btn btn-type1">
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StripeContainer;
