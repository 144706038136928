import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getServiceApi, getFAQsApi } from '../Action';
import faqImage from "../../assets/img/faq.png";
import { Accordion } from "react-bootstrap";
import RenderLoader from "../../Components/RenderLoader";



function FAQ() {

    const [loader, setLoader] = useState(false);
    const [faqText, setFaqText] = useState({});
    const [faqData, setFaqData] = useState([]);
    const [activeKey, setActiveKey] = useState(null);

    const serviceApi = async () => {
        await getServiceApi('faqs').then(response => {
            if (response.status) {
                setFaqText(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };

    const getFaqData = async () => {
        await getFAQsApi().then(response => {
            if (response.status) {
                setFaqData(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };


    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    useEffect(() => {
        serviceApi();
        getFaqData();
    }, []);
    return (
        <section className="faq_sec">
            <div className="container">
                <div className="faq_sec_in">
                    <div className="heading_sec1">
                        <h3>{faqText?.title}</h3>
                        {/* <p dangerouslySetInnerHTML={{ __html: faqText?.description }}></p> */}
                    </div>
                    {
                        loader ?
                            <RenderLoader trans={true} />
                            : null
                    }
                    <div className="faq_sec_cn">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="faq_sec_img">
                                    <img src={faqImage} />
                                </div>
                            </div>
                            {/* Accordion */}
                            <div className="col-lg-6">
                                <div className="faq_sec_itms">
                                    <Accordion activeKey={activeKey}>

                                        {faqData?.length > 0 ?
                                            faqData?.map((item, index) =>
                                                <Accordion.Item eventKey={index} key={index}>
                                                    <Accordion.Header onClick={() => toggleAccordion(index)} >
                                                        {item?.question}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p dangerouslySetInnerHTML={{ __html: item?.answer }}></p>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            ) : null}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ;
