import React, { useState, useEffect, useContext, useRef } from "react";
import logo from "../../assets/img/logo.svg";
import user_ic from "../../assets/img/user_ic.svg";
import Dropdown from "react-bootstrap/Dropdown";
import toogleImage from "../../assets/img/toggle.svg";
// import Button from "react-bootstrap/Button";

// React-router-dom for navigation
import { Link, useLocation, useNavigate } from "react-router-dom";
// react-bootstap
import { Container, Image, Nav, NavLink, Navbar } from "react-bootstrap";
import { getCookies } from "../../Utils/cookies";
import { LoginModalContext } from "../../Pages/Context/LoginModalContext"
import $ from 'jquery';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [mobieView, setMobieView] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const { handleShow } = useContext(LoginModalContext);
  const myElementRef = useRef(null);
  const myElementAddRef = useRef(null);
  const [isEmployer, setIsEmployer] = useState(true);

  const toggleNav = () => {
    const myElement = $(myElementRef.current);
    myElement.removeClass('show');
    myElement.addClass('navbar-collapse collapse');

    const myElementAdd = $(myElementAddRef.current);
    myElementAdd.addClass('collapsed');
  };

  const showNav = () => {
    const myElement = $(myElementRef.current);
    if (myElement.hasClass('show')) {
      myElement.removeClass('show');
    } else {
      myElement.addClass('show');
    }

    const myElementAdd = $(myElementAddRef.current);
    myElementAdd.removeClass('collapsed');
  }


  const handleMediaChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      // Call your function or perform any desired action
      // when the view changes to mobile
      setMobieView(true);
    } else {
      setMobieView(false);
    }
  };


  useEffect(() => {
    // ---------show hide toggle------------------ 
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    handleMediaChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaChange);
    // --------show hide toggle------------------- 


    const loginUser = sessionStorage.getItem('user_details') ? JSON.parse(sessionStorage.getItem('user_details')) : '';
    setUserDetails(loginUser);

    //------------hide plan service when nanny login----------------------
    if (sessionStorage.getItem('token') && loginUser?.role == 2) {
      setIsEmployer(false);
    }
  }, []);
  return (
    <>
      <Navbar expand="lg" className="hdr">
        <Container>
          <Navbar.Brand>
            <NavLink onClick={() => navigate("/")} className="p-0">
              <Image
                src={logo}
                alt={"Flite Logo"}
                fluid={true}
                className={"Hlogo"}
              />
            </NavLink>
          </Navbar.Brand>
          {/* <Navbar ref={myElementAddRef} onClick={showNav}>
            <img src={toogleImage} alt="" />
          </Navbar> */}
          {mobieView &&
            <Navbar aria-controls="basic-navbar-nav" onClick={showNav}>
              <img src={toogleImage} alt="" />
            </Navbar>
          }

          <Navbar.Collapse id="basic-navbar-nav" ref={myElementRef}>
            <Nav className="ms-auto gx-5 menu-links">
              <NavLink className={location?.pathname == "/" ? "active" : ""} onClick={(e) => { toggleNav(), navigate("/") }}>
                Home
              </NavLink>

              <NavLink className={location?.pathname == "/searchnannies" || location?.pathname.split("/")[1] == "nannydetails" ? "active" : ""} onClick={(e) => { toggleNav(); navigate("/searchnannies"); }}>
                Search Nannies
              </NavLink>
              {
                isEmployer ?
                  <NavLink className={location?.pathname == "/serviceplan" || location?.pathname.split("/")[1] == "payment" ? "active" : ""} onClick={(e) => { toggleNav(); navigate("/serviceplan"); }}>
                    Service Plans
                  </NavLink>
                  :
                  ''}

              <NavLink className={location?.pathname == "/visa" || location?.pathname == "/otherAction" ? "active" : ""}>
                <Dropdown className="drp-bt">
                  <Dropdown.Toggle id="dropdown-basic">
                    Other Services
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="drp-bt-nemu">
                    <Dropdown.Item onClick={(e) => { toggleNav(); navigate("/visa"); }}>
                      Visa & Sponsorship
                    </Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { toggleNav(); navigate("/otherAction"); }}>Other Service</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </NavLink>
              <NavLink className={location?.pathname == "/blog" || location?.pathname.split("/")[1] == "blogdetails" ? "active" : ""} onClick={(e) => { toggleNav(); navigate("/blog"); }}>Blog</NavLink>
              <NavLink className={location?.pathname == "/about" ? "active" : ""} onClick={(e) => { toggleNav(); navigate("/about"); }}>About Us</NavLink>
              <NavLink className={location?.pathname == "/contactUs" ? "active" : ""} onClick={(e) => { toggleNav(); navigate("/contactUs"); }}>
                Contact Us
              </NavLink>
            </Nav>
            <Nav className="mbl-marg align-items-center">
              <span className="p-2 lne-mtr d-flex align-items-center">|</span>

              {getCookies("_isJwtFront") === 'true' && sessionStorage.getItem('token') ?
                <>
                  <NavLink className={location?.pathname == "/changepassword" || location?.pathname == "/empProfile" || location?.pathname == "/nannypro" || location?.pathname == "/employerEdit" || location?.pathname == "/nannyEdit" ? "active" : ""}
                    onClick={() => {
                      toggleNav();
                      userDetails?.role === 2 ?
                        navigate("/nannypro")
                        :
                        navigate("/empProfile")
                    }
                    }>

                    <img src={user_ic} />&nbsp;&nbsp;
                    <span>{userDetails ? userDetails?.first_name + " " + userDetails?.last_name : 'user'}</span>
                  </NavLink>

                </>
                :
                <>
                  <NavLink
                    className={location?.pathname == "/register" || location?.pathname == "/registerformnanny" || location?.pathname == "/registerforjob" ? "active" : "" + " nav-link"}
                    onClick={(e) => { toggleNav(); navigate("/register"); }}
                  >
                    Register
                  </NavLink>
                  <NavLink type="button" className="btn-type1" onClick={handleShow}>
                    Login
                  </NavLink>
                </>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >

      {
        loader ?
          <RenderLoader trans={true} />
          : null
      }
    </>
  );
};

export default Header;
