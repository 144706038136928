import { setSession } from "../Utils/storage";

import Axios from "axios";
const BASE_IMG_URL = process.env.REACT_APP_IMG_URL;
const BASE_IMG_URL2 = process.env.REACT_APP_IMG_URL2;
const AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
  timeout: 30000,
  headers: {
    Authorization: sessionStorage.getItem("token"),
  },
});
const setAuthorizationToken = (token) => {
  AxiosInstance.defaults.withCredentials = token ? true : false;
};
const postMethod = async (endpoint, data) => {
  return new Promise((resolve) => {
    var config = {
      method: "post",
      url: endpoint,
      data: data,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        resolve(error.response.data);
      }
    );
  });
};
async function putMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      method: "put",
      url: endpoint,
      data: data,
    };
    //console.log('Data L ' + JSON.stringify(config))
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        resolve(error.response.data);
      }
    );
  });
}

const getMethod = async (path, params = {}) => {
  return new Promise((resolve) => {
    var config = {
      method: "get",
      url: path,
      params: params,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (!error.response || error.code === "ECONNABORTED") {
          resolve({ status: false, message: "error!" });
        } else {
          resolve(error.response.data);
        }
      }
    );
  });
};

async function deleteMethod(path) {
  return new Promise((resolve) => {
    var config = {
      method: "delete",
      url: path,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        resolve(error.response.data);
      }
    );
  });
}

const formSubmit = async (url, method, data) => {
  let form = document.createElement("form");
  form.classList.add("d-none");
  form.id = "invisibleForm";
  form.action = process.env.REACT_APP_MAIN_API_URL + url;
  form.method = method;
  for (let key in data) {
    let input = document.createElement("input");
    input.name = key;
    input.value = data[key];
    input.type = "hidden";
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
};

export {
  BASE_IMG_URL,
  BASE_IMG_URL2,
  formSubmit,
  setAuthorizationToken,
  postMethod,
  putMethod,
  getMethod,
  deleteMethod,
};
