import React, { useState, useEffect } from "react";
import { getCategoryApi, getServiceApi } from "./Action";
import RenderLoader from "../Components/RenderLoader";
import HomeSearch from "./Home/HomeSearch";
import HomeSection1 from "./Home/HomeSection1";
import JobCategorySection from "./Home/JobCategorySection";
import LastPostedOnSection from "./Home/LastPostedOnSection";
import HowItWorks from "./Home/HowItWorks";
import ServicePlanSection from "./Home/ServicePlanSection";
import CustomerFeedback from "./Home/CustomerFeedback";
import GuideToFindNanny from "./Home/GuideToFindNanny";
import FAQ from "./Home/FAQ";
import TrustedBy from "./Home/TrustedBy";


const Home = () => {
  const [loader, setLoader] = useState(false);
  const [jobCategoryList, setJobCategoryList] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [planTypeText, setPlanTypeText] = useState({});
  const [isEmployer, setIsEmployer] = useState(true);



  const categoryList = async () => {
    await getCategoryApi().then(async response => {
      if (response.status) {
        setJobCategoryList(response?.data);
        const categoryArr = await makeReactSelect(response?.data);
        setCategoryOption(categoryArr);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, key: index, value: item?._id };
      arr.push(obj);
    })
    return arr;
  }
  const serviceApi = async () => {
    await getServiceApi('service-plan').then(response => {
      if (response.status) {
        setPlanTypeText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })

    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (sessionStorage.getItem('token') && userDetails?.role == 2) {
      setIsEmployer(false);
    }
  };


  useEffect(() => {
    window.document.title = "Home : Maids & Nanny";
    window.scroll(0, 0);
    categoryList();
    serviceApi();
  }, [])
  return (
    <>
      <div className="main_sec">
        <HomeSearch categoryOption={categoryOption} />
        {
          loader ?
            <RenderLoader trans={true} />
            : null
        }
        <HomeSection1 jobCategoryList={jobCategoryList} />
        <JobCategorySection jobCategoryList={jobCategoryList} />
        <LastPostedOnSection />
        <HowItWorks />

        {/* //-----service section--------// */}
        {isEmployer ?
          <section className="plan_sec bg_type2">
            <div className="container">
              <div className="plan_sec_in">
                <div className="heading_sec1">
                  <h3>{planTypeText?.title}</h3>
                  {/* <p dangerouslySetInnerHTML={{ __html: planTypeText?.description }}></p> */}
                </div>

                <ServicePlanSection />

              </div>
            </div>
          </section>
          : ''}

        <CustomerFeedback />
        <GuideToFindNanny />
        <FAQ />
        <TrustedBy />
      </div >
    </>
  );
};

export default Home;
