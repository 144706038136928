import React from "react";
import "./loader.css";

const LoaderPage = () => {
  return (
    <>
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default LoaderPage;
