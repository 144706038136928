import React from 'react';
import { ThreeHorseLoading } from 'react-loadingg';
const RenderLoader = (props) => {
    return (
        <>
            <div className='vcDiv' style={{ backgroundColor: props?.trans ? 'rgb(255 255 255 / 26%)' : '#fff', width: '100%', height: '100%', position: props?.style ? 'relative' : 'fixed', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0px', left: '0px' }}>
                <ThreeHorseLoading style={{ width: '100px' }} />
            </div>
        </>
    )
}
export default RenderLoader;