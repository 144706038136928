import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import thankyouImage from "../assets/img/thankyou.svg";
// validation
import { Formik, Form, Field, ErrorMessage } from "formik";
import { registerformnannyformtwo } from "../Utils/ValidatianSchema";
import { addEmployerApi, getNationalityApi, getReferenceApi } from "./Action";
import Modal from "react-bootstrap/Modal";
import RenderLoader from "../Components/RenderLoader";
import toast from "react-hot-toast";
import { LoginModalContext } from "../Pages/Context/LoginModalContext";
import { InputValidationContext } from "../Pages/Context/InputValidationContext";
import { setAuthorizationToken } from "../Utils/Service";
import { setCookies } from "../Utils/cookies";

const RegisterFormNannyStep2 = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const { handleShow } = useContext(LoginModalContext);
  const [nationalityList, setNationalityList] = useState([]);
  const [referenceListOption, setReferenceListOption] = useState([]);

  const {
    handleAlphabetKeyPress,
    handleNumberKeyPress,
    handleAlphaNumKeyPress,
  } = useContext(InputValidationContext);

  const handleSubmitForm2 = async (values, { resetForm }) => {
    // console.log("****form 2****", values);
    setLoader(true);
    await addEmployerApi(values).then((response) => {
      if (response.status) {
        setLoader(false);
        resetForm();
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("user_details", JSON.stringify(response.data));
        setCookies("_isJwtFront", true);
        setLoader(true);
        // toast.success(response.message);
        setAuthorizationToken(true);
        setThankYou(true);
        // navigate("/");
        // window.open('/', '_self');
      } else {
        toast.error(response.message);
        setLoader(false);
        navigate("/registerformnanny");
      }
    });
  };

  const fetch = async () => {
    //------fetching Nationality list------//
    await getNationalityApi().then((response) => {
      if (response?.status) {
        setNationalityList(response.data);
      }
    });

    //------reference list------//
    await getReferenceApi().then(async (response) => {
      if (response?.status) {
        const liveArr = await makeArrayReactSelect(response?.data);
        setReferenceListOption(liveArr);
      }
    });
  };

  const makeArrayReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item, key: index, value: item };
      arr.push(obj);
    });
    return arr;
  };

  useEffect(() => {
    window.document.title = "Register For Nanny : Maids & Nanny";
    fetch();
  }, []);
  return (
    <div className="register_s_form">
      <h4>Register to get access to contact details.</h4>

      {loader ? <RenderLoader trans={true} /> : null}
      {/* //---form two---// */}
      <Formik
        initialValues={{
          step: 2,
          mobile: props?.mobile,
          email: props?.email,
          offer_salary: "",
          prefer_nationality: "",
          other_requirement: "",
          referenceby: "",
          acceptTerms: false,
        }}
        validationSchema={registerformnannyformtwo}
        onSubmit={handleSubmitForm2}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Salary Range (monthly - AED)
                  </label>
                  <Field
                    type="text"
                    name="offer_salary"
                    placeholder="Enter Salary Range like 1200-1500"
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="offer_salary"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Any preference in terms of nationality?
                  </label>
                  <Field
                    type="text"
                    name="prefer_nationality"
                    placeholder="Enter Prefer nationality name"
                    className="form-control"
                    autoComplete="off"
                    onKeyDown={handleAlphabetKeyPress}
                  />
                  <ErrorMessage
                    name="prefer_nationality"
                    component="div"
                    className="error"
                  />
                </div>
              </div> */}

              {/* Prefer Nationality */}
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Any preference in terms of nationality? <red>*</red>
                  </label>
                  <Field
                    as="select"
                    name="prefer_nationality"
                    className="form-control"
                  >
                    <option value="">Select Nationality</option>
                    {nationalityList?.length > 0
                      ? nationalityList?.map((item, index) => (
                          <option key={index} value={item?._id}>
                            {item?.name}
                          </option>
                        ))
                      : null}
                  </Field>
                  <ErrorMessage
                    name="prefer_nationality"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Other requirements <red>*</red>
                  </label>
                  <Field
                    as="textarea"
                    name="other_requirement"
                    placeholder="Write other requirements"
                    className="form-control"
                    autoComplete="off"
                    rows="4"
                    onKeyDown={handleAlphabetKeyPress}
                  />
                  <ErrorMessage
                    name="other_requirement"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* <div className="col-md-12">
                <div className="form-group">
                  <label>How did you hear about us?</label>
                  <Field
                    type="text"
                    name="referenceby"
                    placeholder="Enter your source"
                    className="form-control"
                    autoComplete="off"
                    onKeyDown={handleAlphabetKeyPress}
                  />
                  <ErrorMessage
                    name="referenceby"
                    component="div"
                    className="error"
                  />
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    How did you hear about us? <red>*</red>
                  </label>
                  <Field
                    as="select"
                    name="referenceby"
                    className="form-control"
                  >
                    <option value="">Select an option</option>
                    {referenceListOption?.length > 0
                      ? referenceListOption?.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item?.value}
                          </option>
                        ))
                      : null}
                  </Field>
                  <ErrorMessage
                    name="referenceby"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="custom_checkbox_itms">
                    <label className="custom_checkbox">
                      I Accept <red>*</red>{" "}
                      <Link to="/">Terms and Conditions</Link>
                      <Field type="checkbox" name="acceptTerms" />
                      <span className="checkmark"></span>
                    </label>
                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-1">
                  {/* <Link to="/registerformnanny" className="btn btn-type1">Back</Link> */}
                  <button type="submit" className="btn btn-type1 ms-2">
                    Submit
                  </button>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-0">
                  <label className="mb-0">
                    If you already have an account?&nbsp;
                    <Link to="#" onClick={handleShow}>
                      Login
                    </Link>
                  </label>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Modal className="search-modal" show={thankYou}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="thank_you_image">
              <img src={thankyouImage} />
            </div>
            <div className="heading_sec1">
              <h3>Thank You!</h3>
              <p>
                Your registration is successful. Start searching your most
                suitable household profiles.
              </p>
            </div>
            <div className="modal_content">
              <div className="col-md-12">
                <div className="form-group mb-0 mt-1 text-center">
                  <Link
                    to="#"
                    onClick={(e) => window.open("/searchnannies", "_self")}
                    className="btn btn-type1"
                  >
                    {/* <Link to="/searchnannies" className="btn btn-type1"> */}
                    See Profiles
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to="#"
                    onClick={(e) => window.open("/serviceplan", "_self")}
                    className="btn btn-type1"
                  >
                    {/* <Link to="/serviceplan" className="btn btn-type1"> */}
                    Choose Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterFormNannyStep2;
