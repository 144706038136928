import React, { useState, useEffect, useContext } from "react";
import { getServiceApi, contactUsApi, getCategoryApi } from './Action';
import ContactUsSection from "../Pages/Home/ContactUsSection";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { contactUs } from "../Utils/ValidatianSchema";
import RenderLoader from "../Components/RenderLoader";
import toast from 'react-hot-toast';
import { InputValidationContext } from "../Pages/Context/InputValidationContext";
import MobileField from "./CustomMobile";

const ContactUs = (props) => {

  const { handleAlphabetKeyPress, handleNumberKeyPress, handleAlphaNumKeyPress } =
    useContext(InputValidationContext);

  const [loader, setLoader] = useState(false);
  const [contactUsText, setContactUsText] = useState({});
  const [jobCategoryList, setJobCategoryList] = useState([]);


  const serviceApi = async () => {
    await getServiceApi('contact-us').then(response => {
      if (response.status) {
        setContactUsText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };

  const categoryList = async () => {
    setLoader(true);
    await getCategoryApi().then(async response => {
      if (response.status) {
        setJobCategoryList(response?.data);

        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true);
    await contactUsApi(values).then(response => {
      if (response.status) {
        resetForm();
        toast.success("Your request sent successfully.");
        setLoader(false);
      } else {
        toast.success(response.message);
        setLoader(false)
      }
    })
    // console.log(values);
  };

  useEffect(() => {
    window.document.title="Contact us : Maids & Nanny";
    window.scroll(0, 0);
    serviceApi();
    categoryList();
  }, []);

  return (
    <>
      <div className="main_sec">
        <section className="contact_sec">
          <div className="container">
            <div className="contact_sec_in">
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact_sec_form">
                    <div className="heading_sec2">
                      <h3>{contactUsText?.title}</h3>
                      {props?.aboutus!=='hide' ? 
                        <p dangerouslySetInnerHTML={{ __html: contactUsText?.description }}></p>
                        :''
                      }
                    </div>

                    {
                      loader ?
                        <RenderLoader trans={true} />
                        : null
                    }
                    <Formik
                      initialValues={{
                        first_name: "",
                        last_name: "",
                        mobile: "",
                        email: "",
                        job_type: "",
                        message: "",
                      }}
                      validationSchema={contactUs}
                      onSubmit={handleSubmit}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="first_name">First Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                  autoComplete="off"
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="last_name">Last Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  autoComplete="off"
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                           
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="mobile">Mobile Number <red>*</red></label>
                                <Field
                                  component={MobileField}
                                  name="mobile"
                                  type="tel"
                                  required="true"
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="email">Email Address <red>*</red></label>
                                <Field
                                  type="text"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email Address"
                                  autoComplete="off"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="job_type">Looking For <red>*</red></label>
                                <Field
                                  as="select"
                                  name="job_type"
                                  className="form-control"
                                >
                                  <option value="">Select Looking For</option>
                                  {jobCategoryList?.length > 0 ?
                                    jobCategoryList?.map((item, index) =>
                                      <option value={item?._id} key={index}>{item?.name}</option>
                                    )
                                    : ""}
                                </Field>
                                <ErrorMessage
                                  name="job_type"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="message">Your Message <red>*</red></label>
                                <Field
                                  as="textarea"
                                  name="message"
                                  className="form-control"
                                  rows="4"
                                  placeholder="Tell us about you"
                                  autoComplete="off"
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="message"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group mb-0">
                                <button type="submit" className="btn btn-type1" disabled={isSubmitting}>
                                  Send Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <ContactUsSection />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
