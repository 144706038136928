// register for nannays
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Register_For_Job from "../assets/img/register_for_job.jpg";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { registerjobstep1, otpValidation } from "../Utils/ValidatianSchema"

import { getServiceApi, verfyOtpApi, getCountryApi, getCityApi, getStateApi, addNannyApi, getCategoryApi, resendOtpApi } from './Action';
import MobileField from "./CustomMobile";
import RegisterForJobStep2 from "./RegisterForJobStep2";
import RenderLoader from "../Components/RenderLoader";
import toast from 'react-hot-toast';
import { LoginModalContext } from "../Pages/Context/LoginModalContext"
import { InputValidationContext } from "../Pages/Context/InputValidationContext"
import SelectField from "./CustomSelect";

const RegisterForJobStep1 = () => {
  const { handleShow } = useContext(LoginModalContext);
  const { handleAlphabetKeyPress, handleNumberKeyPress, handleAlphaNumKeyPress } =
    useContext(InputValidationContext);

  const [loader, setLoader] = useState(false);
  const [registerJobText, setRegisterJobText] = useState({});
  const [values, setValues] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [jobCategoryList, setJobCategoryList] = useState([]);
  const [jobCategoryOption, setJobCategoryOption] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [formTwo, setFormTwo] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleCountryChange = async (e) => {
    const selectedCountry = e.target.value;
    if (selectedCountry != "") {
      await getStateApi(selectedCountry).then(response => {
        if (response.status) {
          setStateList(response.data);
          setLoader(false);
        } else {
          setLoader(false)
        }
      })
    } else {
      setStateList([]);
    }
  }

  const handleStateChange = async (e) => {
    const selectedCountry = e.target.value;
    if (selectedCountry != "") {
      await getCityApi(selectedCountry).then(response => {
        if (response.status) {
          setCityList(response.data);
          setLoader(false);
        } else {
          setLoader(false)
        }
      })
    } else {
      setCityList([]);
    }
  }

  const serviceApi = async () => {

    //---fetching dynamic text-----/
    await getServiceApi('register-for-job').then(response => {
      if (response.status) {
        setRegisterJobText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })

    //------fetching country list------//
    await getCountryApi().then((response) => {
      if (response?.status) {
        setCountryList(response.data);
      }
    })

    //------fetching job category list------//
    await getCategoryApi().then(async (response) => {
      if (response?.status) {
        setJobCategoryList(response.data);
        const jobArr = await makeReactSelect(response?.data);
        setJobCategoryOption(jobArr);
      }
    })

  };

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, value: item?._id, key: index };
      arr.push(obj);
    })
    return arr;
  }

  const handleVerifyMobile = async (values) => {
    // console.log("***mobile otp verify***", values);
    await verfyOtpApi(values).then(response => {
      if (response.status) {
        toast.success(response.message);
        setShow(false);
        setFormTwo(true);
        setLoader(false);
        setCountdown(process.env.REACT_APP_RESEND_COUNTER);
        startTimer();
        setCompleted(true);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
  }

  //------------count down to resend oto----------//
  const [countdown, setCountdown] = useState(5);
  const [completed, setCompleted] = useState(true);

  const handleResendOtp = async (type) => {
    const obj = {
      type: type,
      mobile: values?.mobile
    };
    console.log("***********", obj);
    await resendOtpApi(obj).then(response => {
      if (response.status) {
        setCountdown(process.env.REACT_APP_RESEND_COUNTER);
        startTimer();
        setCompleted(true);
        toast.success(response.message);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
  }

  const startTimer = () => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCompleted(false);
    }
  }
  useEffect(() => {
    startTimer();
  }, [countdown])
  //------------count down to resend oto----------//

  // validation // form
  const handleSubmit = async (values) => {
    values.job_category = values?.job_array?.map((item) => { return item?.value })
    setLoader(true);
    await addNannyApi(values).then(response => {
      if (response.status) {
        setValues(values);
        
        //-----for now disable the otp verification section ---also remove some code in api panel user.js line 209//
        // toast.success(response.message);
        // setShow(true);
        //-------take the user direct to 2nd page-------//
        setFormTwo(true);

        setCountdown(process.env.REACT_APP_RESEND_COUNTER);
        startTimer();
        setCompleted(true);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
    // console.log("*****values*****", values);
  };

  useEffect(() => {
    window.document.title="Register For a Job : Maids & Nanny";
    serviceApi();
  }, []);
  return (
    <>
      <section className="register_s">
        <div className="container">
          <div className="register_s_in">
            <div className="row">
              <div className="col-md-6">
                <div className="register_s_img">
                  <img src={Register_For_Job} />
                </div>
                {
                  loader ?
                    <RenderLoader trans={true} />
                    : null
                }
                <div className="register_s_con mt-3">
                  <div className="heading_sec2">
                    <h3>{registerJobText?.title}</h3>
                  </div>
                  <div className="register_s_pera">
                    <p dangerouslySetInnerHTML={{ __html: registerJobText?.description }}></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">

                {formTwo ?
                  <RegisterForJobStep2 mobile={values?.mobile} login_name={values?.login_name} state={values?.state} />
                  :
                  <div className="register_s_form">
                    <h4>Register to get contacted directly by families</h4>
                    <Formik
                      initialValues={{
                        step: 1,
                        first_name: "",
                        last_name: "",
                        job_array: "",
                        country: "",
                        state: "",
                        city: "",
                        mobile: "",
                        whatsAppNumber: "",
                        login_name: "",
                        login_pin: ""
                      }}
                      validationSchema={registerjobstep1}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="row">

                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>First Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="first_name"
                                  placeholder="Enter First Name"
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  value={values.first_name}
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Last Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="last_name"
                                  placeholder="Enter Last Name"
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  value={values.last_name}
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>

                            {/* <div className="col-xl-6">
                              <div className="form-group">
                                <label htmlFor="job_category">Job Type</label>
                                <Field
                                  as="select"
                                  name="job_category"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.job_category}
                                >
                                  <option value="">Select job type</option>
                                  {
                                    jobCategoryList?.length ?
                                      jobCategoryList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                      : null
                                  }

                                </Field>
                                <ErrorMessage
                                  name="job_category"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div> */}

                            <div className="col-xl-12">
                              <div className="form-group">
                                <label htmlFor="job_array">Job Type <red>*</red></label>
                                <Field
                                  component={SelectField}
                                  name="job_array"
                                  options={jobCategoryOption}
                                />
                                <ErrorMessage
                                  name="job_array"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-xl-12">
                              <div className="form-group">
                                <label htmlFor="country">Country <red>*</red></label>
                                <Field
                                  as="select"
                                  name="country"
                                  className="form-control"
                                  onChange={(e) => { handleChange(e), handleCountryChange(e), setFieldValue('state', ''), setFieldValue('city', '') }}
                                  value={values.country}
                                >
                                  <option value="">Select an country</option>
                                  {
                                    countryList?.length ?
                                      countryList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                      : null
                                  }
                                </Field>
                                <ErrorMessage
                                  name="country"
                                  component="div"
                                  className="error"
                                />

                              </div>
                            </div>

                            <div className="col-xl-6">
                              <div className="form-group">
                                <label htmlFor="state">States / Emirates <red>*</red></label>
                                <Field
                                  as="select"
                                  name="state"
                                  className="form-control"
                                  onChange={(e) => { handleChange(e), handleStateChange(e), setFieldValue('city', '') }}
                                  value={values.state}
                                >
                                  <option value="">Select an state</option>
                                  {
                                    stateList?.length ?
                                      stateList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                      : null
                                  }
                                </Field>
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  className="error"
                                />

                              </div>
                            </div>

                            <div className="col-xl-6">
                              <div className="form-group">
                                <label htmlFor="city">City <red>*</red></label>
                                <Field
                                  as="select"
                                  name="city"
                                  className="form-control"
                                  onChange={handleChange}
                                  value={values.city}
                                >
                                  <option value="">Select city</option>
                                  {
                                    cityList?.length ?
                                      cityList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                      : null
                                  }

                                </Field>
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>


                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Mobile Number <red>*</red></label>

                                <Field
                                  component={MobileField}
                                  name="mobile"
                                  type="tel"
                                  required="true"
                                  onChange={handleChange}
                                  value={values.mobile}
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="error"
                                />

                              </div>
                            </div>

                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>WhatsApp Number <red>*</red></label>
                                <Field
                                  component={MobileField}
                                  name="whatsAppNumber"
                                  type="tel"
                                  required="true"
                                  onChange={handleChange}
                                  value={values.whatsAppNumber}
                                />
                                <ErrorMessage
                                  name="whatsAppNumber"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>


                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Create Login Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="login_name"
                                  placeholder="Set a login name"
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  value={values.login_name}
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="login_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="form-group">
                                <label>Create Login Pin <red>*</red></label>
                                <Field
                                  type="text"
                                  name="login_pin"
                                  placeholder="Set login pin"
                                  className="form-control"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  value={values.login_pin}
                                  onKeyDown={handleNumberKeyPress}
                                />
                                <ErrorMessage
                                  name="login_pin"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-xl-12">
                              <div className="form-group mt-1">
                                <button type="submit" className="btn btn-type1" >
                                  Continue to Register
                                </button>
                              </div>
                            </div>

                            <div className="col-xl-12">
                              <div className="form-group mb-0">
                                <label className="mb-0">
                                  If you already have an account?&nbsp;
                                  <Link
                                    to="#"
                                    onClick={handleShow}
                                  >
                                    Login
                                  </Link>
                                </label>
                              </div>
                            </div>

                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                }





                {/* Mobile Otp Verfy */}
                <div>
                  <Modal
                    className="search-modal"
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      {/* verify Number */}
                      <div className="modal-body">
                        <div className="heading_sec1">
                          <h3>Verify Number</h3>
                          <p>
                          Please enter OTP sent to your registered mobile number XXX
                            XXXX XX{values?.mobile?.slice(-3)}
                          </p>
                        </div>
                        <div className="modal_content">
                          <Formik
                            initialValues={{
                              otp: "",
                              mobile: values?.mobile,
                              type: "mobile",
                            }}
                            onSubmit={handleVerifyMobile}
                            validationSchema={otpValidation}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <div className="row">

                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        name="otp"
                                        className="form-control"
                                        placeholder="Please enter OTP sent to your registered mobile number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.otp}
                                        onKeyDown={handleNumberKeyPress}
                                      />
                                      {errors.otp && touched.otp && (
                                        <div className="error">{errors.otp}</div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mt-1 text-center">
                                      <button type="submit" className="btn btn-type1" disabled={isSubmitting}>
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-center mb-0">
                                      <p className="otm_time">00:{countdown}</p>
                                      <label className="mb-0">
                                        You din't receive OTP?&nbsp;
                                        {completed ?
                                          <span>Resend</span>
                                          :
                                          <Link to="/" onClick={(e) => { e.preventDefault(); handleResendOtp("mobile"); }}>Resend</Link>
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>



              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterForJobStep1;
