import React, { useState, useEffect, useContext } from "react";
import { NannySidebar, EmployerSidebar } from "../Pages/LoginSidebar";
import { Formik, Field, Form, ErrorMessage } from "formik";
import RenderLoader from "../Components/RenderLoader";
import { changePasswordApi } from './Action';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import { InputValidationContext } from "../Pages/Context/InputValidationContext"

const ChangePassword = () => {
  const { handleAlphabetKeyPress, handleNumberKeyPress, handleAlphaNumKeyPress } = useContext(InputValidationContext);
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState({});


  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true);
    const data = {
      old_password: values.old_password,
      new_password: values.new_password,
      role: userDetails?.role
    };
    await changePasswordApi(data).then(response => {
      setLoader(false);
      if (response.status) {
        resetForm();
        toast.success(response.message);
      } else {
        setLoader(false)
        toast.error(response.message);
      }
    })
  };

  const changePasswordNanny = Yup.object().shape({
    old_password: Yup.string().required('Old Password / PIN is required'),
    new_password: Yup.string()
      .required('New Password / PIN is required')
      .min(6, 'New Password / PIN must be at least 6 characters')
      .max(8, 'New Password / PIN must be at least 8 characters'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Must match with new password / PIN ')
      .required('Confirm Password is required'),
  });

  const changePasswordEmployer = Yup.object().shape({
    old_password: Yup.string().required('Old Password / PIN  is required'),
    new_password: Yup.string()
      .required('New Password / PIN is required')
      .min(6, 'New Password / PIN must be at least 6 characters')
      .max(20, 'New Password / PIN must be at least 20 characters'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Must match with new password / PIN')
      .required('Confirm Password is required'),
  });

  useEffect(() => {
    window.document.title="Change Passowrd : s Nanny";
    const loginUser = sessionStorage.getItem('user_details') ? JSON.parse(sessionStorage.getItem('user_details')) : '';
    setUserDetails(loginUser);
  }, [])
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">

            {userDetails?.role === 1 ?
              <EmployerSidebar />
              :
              <NannySidebar />
            }

            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }

            <div className="profile_content">
              <div className="profile_form">
                <h4>Change Password / PIN </h4>
                <Formik
                  initialValues={{
                    old_password: "",
                    new_password: "",
                    confirm_password: ""
                  }}
                  validationSchema={userDetails?.role == 1 ? changePasswordEmployer : changePasswordNanny}
                  onSubmit={handleSubmit}
                >
                  {({ values, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-7">
                          <div className="form-group">
                            <label htmlFor="old_password">Old Passowrd / PIN <red>*</red></label>
                            <Field
                              type="text"
                              name="old_password"
                              className="form-control"
                              placeholder="Enter old password / PIN"
                              autoComplete="off"
                              onKeyDown={userDetails?.role == 2 ? handleNumberKeyPress : handleAlphaNumKeyPress}
                            />
                            <ErrorMessage
                              name="old_password"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="form-group">
                            <label htmlFor="new_password">New Passowrd / PIN <red>*</red></label>
                            <Field
                              type="text"
                              name="new_password"
                              className="form-control"
                              placeholder="Enter new passowrd / PIN"
                              autoComplete="off"
                              onKeyDown={userDetails?.role == 2 ? handleNumberKeyPress : handleAlphaNumKeyPress}
                            />
                            <ErrorMessage
                              name="new_password"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="form-group">
                            <label htmlFor="confirm_password">Confirm new passowrd / PIN <red>*</red></label>
                            <Field
                              type="text"
                              name="confirm_password"
                              className="form-control"
                              placeholder="Enter confirm new passowrd / PIN"
                              autoComplete="off"
                              onKeyDown={userDetails?.role == 2 ? handleNumberKeyPress : handleAlphaNumKeyPress}
                            />
                            <ErrorMessage
                              name="confirm_password"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="form-group mb-0">
                            <button type="submit" className="btn btn-type1" disabled={isSubmitting}>
                              Update 
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
