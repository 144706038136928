import React, { useEffect } from "react";
import shape_trusted from "../../assets/img/shape_trusted.svg";
import logo1 from "../../assets/img/logo1.png";
import logo2 from "../../assets/img/logo2.png";
import logo3 from "../../assets/img/logo3.png";
import logo4 from "../../assets/img/logo4.png";
import logo5 from "../../assets/img/logo5.png";
import logo6 from "../../assets/img/logo6.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RenderLoader from "../../Components/RenderLoader";

const TrustedBy = () => {
  const logos = {
    dots: false,
    errow: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Adjust the number of items to show on smaller screens
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {}, []);
  return (
    <section className="trusted_sec">
      <div className="container">
        <div className="trusted_sec_in">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="trusted_sec_head">
                <div className="trusted_sec_head_shape">
                  <img src={shape_trusted} />
                </div>
                <h3>Trusted by more than 100+ families.</h3>
              </div>
            </div>
            <div className="col-md-2"></div>

            {/* <div className="col-md-8">
              <Slider {...logos} className="testimonial_sld">
                <div className="item">
                  <img src={logo1} />
                </div>
                <div className="item">
                  <img src={logo2} />
                </div>
                <div className="item">
                  <img src={logo3} />
                </div>
                <div className="item">
                  <img src={logo4} />
                </div>
                <div className="item">
                  <img src={logo5} />
                </div>
                <div className="item">
                  <img src={logo6} />
                </div>
              </Slider>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedBy;
