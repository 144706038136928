import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { convertDateFomrat } from "../../Utils/Logic";
import heart_ic from "../../assets/img/heart_ic.svg";
import heart_fill from "../../assets/img/heart_fill.svg";
import calendar_ic from "../../assets/img/calendar_ic.svg";
import location_ic from "../../assets/img/location_ic.svg";
import category_ic from "../../assets/img/category_ic.svg";
import user_ic from "../../assets/img/user_ic.svg";
import flag_ic from "../../assets/img/flag_ic.svg";
import money_ic from "../../assets/img/video_ic.svg";
import video_ic from "../../assets/img/video_ic.svg";
import right_arrow from "../../assets/img/right_arrow.svg";
import RenderLoader from "../../Components/RenderLoader";
import { VideoModalContext } from "../Context/VideoModalContext";

function NannyProfile(props) {
  const [loader, setLoader] = useState(false);
  const { openModal } = useContext(VideoModalContext);


  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <div className="profile_itm" key={props?.item?._id}>
      <div
        className="profile_itm_img"
        style={{
          backgroundImage:
            "url(" + `${(props?.item?.profile_image) ? process.env.REACT_APP_URL + '/' + props?.item?.profile_image : user_ic}` + ")",
        }}
      >
        {props?.favourite &&
          <div className="remove_fav">
            <Link to="#" onClick={(e) => { e.preventDefault(); props?.addFavorite(props?.item?._id); }}>Remove</Link>
          </div>
        }
      </div>
      {
        loader ?
          <RenderLoader trans={true} />
          : null
      }
      <div className="profile_itm_con">
        <div className="profile_itm_head">
          <h5>{(props?.item?.first_name + " " + props?.item?.last_name).substring(0, 22)}</h5>
          
          <div className="profile_mts">
            <div className="profile_mt">
              {props?.item?.city ? props?.item?.city?.name : ''}
              {props?.item?.country ? " " + props?.item?.country?.name : ''} 
            </div>
            <div className="profile_mt">
              Posted On: {(props?.item?.date) ? convertDateFomrat(props?.item?.date) : ''} |
              Exp : {(props?.item?.total_experience >1) ? props?.item?.total_experience+" years" : props?.item?.total_experience+" year"}

            </div>
          </div>
         
          <div className="profile_itm_heart" onClick={(e) => props?.addFavorite(props?.item?._id,props?.item?.first_name+' '+props?.item?.last_name)}>
            {props?.likeprofile?.includes(props?.item?._id) ? <img src={heart_fill} /> : <img src={heart_ic} alt="" />}
          </div>
        </div>
        <div className="profile_itm_meta">
          <div className="profile_itm_meta_itm">
            <i>
              <img src={calendar_ic} alt="" />
            </i>
            <span>{props?.item?.age} Years Old</span>
          </div>
          <div className="profile_itm_meta_itm">
            <i>
              <img src={location_ic} alt="" />
            </i>
            <span>{props?.item?.nationality ? props?.item?.nationality.name : ''}</span>
          </div>
          <div className="profile_itm_meta_itm">
            <i>
              <img src={category_ic} alt="" />
            </i>
            <span>
            {props?.item?.job_category?.length > 0 ?
              props?.item?.job_category?.map((item, index) => ( (index<=2)?item.name+',':''))
              :''
            }
            {/* {props?.item?.job_category ? props?.item?.job_category[0]?.name : ''} */}
            </span>
          </div>
          <div className="profile_itm_meta_itm">
            <i>
              <img src={flag_ic} alt="" />
            </i>
            <span>
            {props?.item?.live_status?.length > 0 ?
              props?.item?.live_status?.map((item, index) => ( (index<=1)?item+',':''))
              :''
            }
            </span>
          </div>
        </div>
        <div className="price_profile">            
          {(props?.item?.expected_salary)?
          <>
            {/* <i>
              <img src={money_ic} alt="" />
            </i> */}
            <span>{props?.item?.expected_salary} AED</span>
          </>
          :'' }
        </div>
        
        <div className="profile_itm_btns">
          <Link to={"/nannydetails/" + props?.item?._id} className="btn btn-type2">
            <span>View Profile</span>
            <i>
              <img src={right_arrow} alt="" />
            </i>
          </Link>
          <Link to="#" className="btn btn-type1 btn-round" onClick={() => openModal(props?.item?._id)}>
            <i>
              <img src={video_ic} alt="" />
            </i>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NannyProfile;

