import React, { useState, useEffect, useContext } from "react";
import nanny_profile from "../assets/img/nanny_profile.jpg";
import heart_ic from "../assets/img/heart_ic.svg";
import heart_fill from "../assets/img/heart_fill.svg";
import share from "../assets/img/share.svg";
import calendar_ic from "../assets/img/calendar_ic.svg";
import location_ic from "../assets/img/location_ic.svg";
import category_ic from "../assets/img/category_ic.svg";
import user_ic from "../assets/img/user_ic.svg";
import flag_ic from "../assets/img/flag_ic.svg";
import right_arrow from "../assets/img/right_arrow.svg";
import { Link, useParams } from "react-router-dom";
import {
  getNannyDetailsApi,
  addShortlistedListApi,
  likeNannyProfilesShow,
} from "./Action";
import { convertDateFomrat, dateFormatConverter } from "../Utils/Logic";
import ReactPlayer from "react-player";
import RenderLoader from "../Components/RenderLoader";
import { VideoModalContext } from "./Context/VideoModalContext";
import { GuestUserModalContext } from "./Context/GuestUserModalContext";
import { toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RWebShare } from "react-web-share";

const NannyDetails = () => {
  const { id } = useParams();
  const { openModal } = useContext(VideoModalContext);
  const { showGuestModal, openContactModal } = useContext(
    GuestUserModalContext
  );
  const [loader, setLoader] = useState(false);
  const [likeprofile, setLikeprofile] = useState([]);

  const [nannyData, setNannyData] = useState({});
  const [language, setLanguage] = useState([]);

  const getNannyDetails = async () => {
    setLoader(true);
    await getNannyDetailsApi(id).then(async (response) => {
      if (response.status) {
        setNannyData(response?.data);
        let lang = [];
        if (response?.data?.language.length > 0) {
          lang = response?.data?.language.map((item) => {
            return item.name;
          });
          setLanguage(lang);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const fetchLikeProfile = async () => {
    setLoader(true);
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if(userDetails){
      await likeNannyProfilesShow(userDetails?._id).then((response) => {
        if (response.status) {
          setLikeprofile(response.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    }
  };

  const handleContactShow = () => {
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (
      (sessionStorage.getItem("token") &&
        sessionStorage.getItem("user_details") &&
        userDetails?.role == 1 &&
        userDetails?.subscription_status) ||
      sessionStorage.getItem("plan_subscribed") == "true"
    ) {
      openContactModal(nannyData);
    } else {
      showGuestModal(nannyData?.first_name + " " + nannyData?.last_name);
    }
  };

  const addFavorite = async (id) => {
    let objData = {
      nanny_id: id,
    };
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (userDetails?.role == 1) {
      setLoader(true);
      await addShortlistedListApi(objData).then((response) => {
        if (response.status) {
          setLoader(false);
          toast.success(response.message);
          fetchLikeProfile();
        } else {
          // toast.success(response.message);
          showGuestModal(nannyData?.first_name + " " + nannyData?.last_name);
          setLoader(false);
        }
      });
    } else {
      showGuestModal(nannyData?.first_name + " " + nannyData?.last_name);
    }
  };

  useEffect(() => {
    window.document.title = "Nanny Detail : Maids & Nanny";
    fetchLikeProfile();
    getNannyDetails();
  }, []);

  return (
    <>
      <section className="nannydetail_sec">
        {loader ? (
          <div className="container">
            <div className="nannydetail_sec_in">
              <div className="row">
                <Skeleton
                  count={5}
                  height={30}
                  highlightColor="#D3D3D3"
                  enableAnimation="true"
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {loader ? (
          <RenderLoader trans={true} />
        ) : (
          <div className="container">
            <div className="nannydetail_sec_in">
              <div className="row">
                <div className="col-md-12">
                  <div className="nannydetail_sec_head nannydetail_box">
                    <div className="nannydetail_sec_img">
                      {nannyData?.profile_image ? (
                        <img
                          src={
                            process.env.REACT_APP_URL +
                            "/" +
                            nannyData?.profile_image
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="nannydetail_sec_con">
                      <div className="profile_itm_head">
                        <h4>
                          {nannyData?.first_name + " " + nannyData?.last_name}
                        </h4>
                        <div className="profile_mts">
                          <div className="profile_mt">
                            Posted On:{" "}
                            <span>{convertDateFomrat(nannyData?.date)}</span> |
                            Location:
                            <span>
                              {" "}
                              {nannyData?.city ? nannyData?.city?.name : ""}
                              {nannyData?.country
                                ? " " + nannyData?.country?.name
                                : ""}
                            </span>{" "}
                            | Desired monthly salary:{" "}
                            <span>AED {nannyData?.expected_salary}</span>
                          </div>
                          <div className="profile_mts_meta">
                            <div
                              className="profile_itm_heart"
                              onClick={() => addFavorite(nannyData?._id)}
                            >
                              {likeprofile?.includes(nannyData?._id) ? (
                                <img src={heart_fill} />
                              ) : (
                                <img src={heart_ic} alt="" />
                              )}
                            </div>
                            <div className="profile_itm_share">
                              {/* <img src={share} /> */}

                              <RWebShare
                                data={{
                                  text: "Maid and Nanny",
                                  url: `${process.env.REACT_APP_FRONT_URL}/nannydetails/${nannyData?._id}`,
                                  title: "Maid and Nanny",
                                }}
                                onClick={() =>
                                  console.log("shared successfully!")
                                }
                              >
                                <img src={share} />
                              </RWebShare>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nannydetail_bio">
                        <h5>Bio:</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: nannyData?.bio }}
                        ></p>
                      </div>
                      <div className="profile_itm_meta">
                        <div className="profile_itm_meta_itm">
                          <i>
                            <img src={calendar_ic} />
                          </i>
                          <span>{nannyData?.age} Year Old</span>
                        </div>
                        <div className="profile_itm_meta_itm">
                          <i>
                            <img src={location_ic} />
                          </i>
                          <span>
                            {nannyData?.nationality
                              ? nannyData?.nationality.name
                              : ""}
                          </span>
                        </div>
                        <div className="profile_itm_meta_itm">
                          <i>
                            <img src={category_ic} />
                          </i>
                          <span>
                            {nannyData?.job_category
                              ? nannyData?.job_category[0]?.name
                              : ""}
                          </span>
                        </div>
                        <div className="profile_itm_meta_itm">
                          <i>
                            <img src={flag_ic} />
                          </i>
                          <span>
                            {nannyData?.live_status
                              ? nannyData?.live_status.toString()
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="nannydetail_sec_btn">
                        <button
                          onClick={handleContactShow}
                          className="btn btn-type2"
                        >
                          <span>Contact to Nanny</span>
                          <i>
                            <img src={right_arrow} />
                          </i>
                        </button>
                        <Link
                          to="#"
                          className="btn btn-type1 ms-2"
                          onClick={() => openModal(nannyData?._id)}
                        >
                          Watch Video
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="nannydetail_box">
                    <h4>Personal Info</h4>
                    <ul className="nannydetail_box_itms">
                      <li>
                        <span>Candidate ID:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.uniqueId}
                        </span>
                      </li>
                      <li>
                        <span>Registered Date:</span>
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.date
                            ? convertDateFomrat(nannyData?.date)
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span>Religion:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.religion ? nannyData?.religion?.name : ""}
                        </span>
                      </li>
                      <li>
                        <span>Nationality:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.nationality
                            ? nannyData?.nationality?.name
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span>Current Location:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.city ? nannyData?.city?.name : ""}
                          {nannyData?.country
                            ? " " + nannyData?.country?.name
                            : ""}
                        </span>
                      </li>
                      {/* <li>
                      <span>Recommendation:</span>{" "}
                      <span className="nannydetail_box_itm_value">No</span>
                    </li> */}
                      <li>
                        <span>Preferred Location:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.preferred_location
                            ? nannyData?.preferred_location?.map(
                                (item, index) => item?.name + ","
                              )
                            : "N/A"}
                        </span>
                      </li>
                      <li>
                        <span>Visa Status:</span>
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.visa_status
                            ? nannyData?.visa_status
                            : "N/A"}
                        </span>
                      </li>
                      <li>
                        <span>Age:</span>
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.age} years
                        </span>
                      </li>
                      <li>
                        <span>Date Of Birth:</span>
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.dob
                            ? dateFormatConverter(nannyData?.dob)
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span>Languages:</span>
                        <span className="nannydetail_box_itm_value">
                          {language ? language.toString() : ""}
                        </span>
                      </li>
                      <li>
                        <span>Marital Status:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.marital_status}
                          {nannyData?.number_of_kids > 0
                            ? nannyData?.number_of_kids > 1
                              ? "(" + nannyData?.number_of_kids + " Kids)"
                              : "(" + nannyData?.number_of_kids + " Kid)"
                            : ""}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="nannydetail_box">
                    <h4>Salary & Experience</h4>
                    <ul className="nannydetail_box_itms">
                      <li>
                        <span>Position:</span>{" "}
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.live_status
                            ? nannyData?.live_status.toString()
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span>Expected Salary:</span>
                        <span className="nannydetail_box_itm_value">
                           {(nannyData?.expected_salary)?'AED '+nannyData?.expected_salary:'--'}
                        </span>
                      </li>
                      <li>
                        <span>Available From:</span>
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.available_from
                            ? convertDateFomrat(nannyData?.available_from)
                            : "N/A"}
                        </span>
                      </li>
                      <li>
                        <span>Years of Experience :</span>
                        <span className="nannydetail_box_itm_value">
                          {nannyData?.total_experience + " Years"}
                        </span>
                      </li>
                      {/* <li>
                      <span>Years of Experience - UAE:</span>
                      <span className="nannydetail_box_itm_value">
                        Beginner
                      </span>
                    </li> */}
                      {/* <li>
                      <span>Years of Experience - GCC:</span>{" "}
                      <span className="nannydetail_box_itm_value">
                        9-12 years
                      </span>
                    </li> */}
                      {/* <li>
                      <span>Years of Experience - Others:</span>{" "}
                      <span className="nannydetail_box_itm_value">
                        9-12 years
                      </span>
                    </li> */}
                    </ul>
                    <div className="nannydetail_experties">
                      <h4>Job Types</h4>
                      <ul className="experties_itms">
                        {nannyData?.job_category?.length > 0
                          ? nannyData?.job_category?.map((item, index) => (
                              <li key={index}>{item.name}</li>
                            ))
                          : ""}
                      </ul>
                    </div>
                    <div className="nannydetail_experties">
                      <h4>Experties</h4>
                      <ul className="experties_itms">
                        {nannyData?.skills?.length > 0
                          ? nannyData?.skills?.map((item, index) => (
                              <li key={index}>{item.name}</li>
                            ))
                          : ""}
                      </ul>
                    </div>
                    <div className="nannydetail_experties">
                      <h4>Experties with kids</h4>
                      <ul className="experties_itms">
                        {nannyData?.experience_with_kids?.length > 0
                          ? nannyData?.experience_with_kids?.map(
                              (item, index) => <li key={index}>{item.name}</li>
                            )
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {nannyData?.video && (
              <div className="nannydetail_video" id="video_sec">
                <div className="heading_sec1">
                  <h3>About Nanny</h3>
                </div>
                <div className="nannydetail_video_in">
                  <div className="nannydetail_video_img">
                    <ReactPlayer
                      url={process.env.REACT_APP_URL + "/" + nannyData?.video}
                      playing={false}
                      width="100%"
                      height="500px"
                      controls={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default NannyDetails;
