import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quote from "../../assets/img/quote.svg";
import user_image from "../../assets/img/user_image.png";
import { getServiceApi } from '../Action';
import RenderLoader from "../../Components/RenderLoader";


function CustomerFeedback() {

    const [loader, setLoader] = useState(false);
    const [customerText, setCustomerText] = useState({});

    const serviceApi = async () => {
        await getServiceApi('customer-feedback').then(response => {
            if (response.status) {
                setCustomerText(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };

    // home page start and see
    // react slick
    const settings = {
        dots: false,
        errow: true,
        infinite: true,
        autoplay: true,
        pauseOnFocus: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        serviceApi();
    }, []);
    return (
        < section className="testimonial_sec" >
            <div className="container">
                <div className="testimonial_sec_in">
                    <div className="heading_sec1">
                        <h3>{customerText?.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: customerText?.description }}></p>
                    </div>
                    {
                        loader ?
                            <RenderLoader trans={true} />
                            : null
                    }
                    {/* React slick slider */}
                    
                    <Slider {...settings}>
                        {/* <div className="testimonial_sld owl-carousel"> */}

                        <div className="item">
                            <div className="testimonial_sld_itm">
                                <div className="testimonial_sld_itm_img">
                                    <img src={quote} />
                                </div>
                                <div className="testimonial_sld_itm_para">
                                    <p>
                                    So glad that I came across this website! I was desperate to find a nanny before going back to work after my pregnancy. I feel so sure about my choice and the process was really smooth too!
                                    </p>
                                </div>
                                <div className="testimonial_sld_itm_meta">
                                    {/* <div className="testimonial_sld_itm_meta_img">
                                        <img src={user_image} />
                                    </div> */}
                                    <div className="testimonial_sld_itm_meta_con">
                                        <h5>Tinu K</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonial_sld_itm">
                                <div className="testimonial_sld_itm_img">
                                    <img src={quote} />
                                </div>
                                <div className="testimonial_sld_itm_para">
                                    <p>
                                    Thanks to Maids & Nanny for helping find a maid. They had lots of profiles on their site and the cost seems reasonable too. I tried initially to find a maid online on various social media platforms however was unsure of their profile and whether they were serious. However, Maidandnanny.com was quite useful and will recommend other families too
                                    </p>
                                </div>
                                <div className="testimonial_sld_itm_meta">
                                    {/* <div className="testimonial_sld_itm_meta_img">
                                        <img src={user_image} />
                                    </div> */}
                                    <div className="testimonial_sld_itm_meta_con">
                                        <h5>Neethu</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonial_sld_itm">
                                <div className="testimonial_sld_itm_img">
                                    <img src={quote} />
                                </div>
                                <div className="testimonial_sld_itm_para">
                                    <p>
                                    So glad that I came across this website! I was desperate to find a nanny before going back to work after my pregnancy. I feel so sure about my choice and the process was really smooth too!
                                    </p>
                                </div>
                                <div className="testimonial_sld_itm_meta">
                                    {/* <div className="testimonial_sld_itm_meta_img">
                                        <img src={user_image} />
                                    </div> */}
                                    <div className="testimonial_sld_itm_meta_con">
                                        <h5>Nipuni Perera</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testimonial_sld_itm">
                                <div className="testimonial_sld_itm_img">
                                    <img src={quote} />
                                </div>
                                <div className="testimonial_sld_itm_para">
                                    <p>
                                    Thanks to Maids & Nanny for helping find a maid. They had lots of profiles on their site and the cost seems reasonable too. I tried initially to find a maid online on various social media platforms however was unsure of their profile and whether they were serious. However, Maidandnanny.com was quite useful and will recommend other families too
                                    </p>
                                </div>
                                <div className="testimonial_sld_itm_meta">
                                    {/* <div className="testimonial_sld_itm_meta_img">
                                        <img src={user_image} />
                                    </div> */}
                                    <div className="testimonial_sld_itm_meta_con">
                                        <h5>Maria</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                       

                        {/* </div> */}
                    </Slider>
                </div>
            </div>
        </section >
    );
}

export default CustomerFeedback;
