import React, { createContext, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { showNannyContactInfo } from "../Action";
import RenderLoader from "../../Components/RenderLoader";
import call_ic from "../../assets/img/call_ic.svg";
import wts_ic from "../../assets/img/wts_ic.svg";
import email_ic from "../../assets/img/email_ic.svg";
import { LoginModalContext } from "./LoginModalContext";

export const GuestUserModalContext = createContext();
export const GuestUserModalContextProvider = ({ children }) => {
  const history = useNavigate();
  const { handleShow } = useContext(LoginModalContext);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [nannyData, setNannyData] = useState({});
  const [nannyName, setNannyName] = useState('');
  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));

  //---------show/hide guest user model--------//
  const showGuestModal = async (name) => {
    setNannyName(name);
    setIsOpen(true);
  }

  const closeGuestModal = () => setIsOpen(false);

  const handleRedirection = () => {
    closeGuestModal();
    history("/registerformnanny");
  };

  const handleRedirectionServicePlan = () => {
    closeGuestModal();
    history("/serviceplan");
  };

  const openContactModal = async (data) => {
    setIsOpenContact(true);
    setLoader(true);
    await showNannyContactInfo(data?._id).then(async (response) => {
      if (response.status) {
        setNannyData(response?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const closeContactModal = () => setIsOpenContact(false);

  const handleModalBox = () => {
    closeGuestModal();
    handleShow();
  };

  return (
    <GuestUserModalContext.Provider
      value={{
        isOpen,
        showGuestModal,
        closeGuestModal,
        isOpenContact,
        openContactModal,
        closeContactModal,
      }}
    >
      {children}

      {isOpen && (
        <>
          <Modal show={isOpen} onHide={closeGuestModal}>
            <button
              type="button"
              className="btn-close"
              onClick={closeGuestModal}
            ></button>

            {userDetails && userDetails.subscription_status == false ? (
              <div className="modal-body">
                <div className="heading_sec1">
                  <h3>Want To Contact<br/>
                    {nannyName}</h3>
                  <p>Please register and subscribe to our affordable plan and get in touch with Maids and Nannies</p>
                </div>
                <div className="modal_content">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-1 text-center">
                          <button
                            type="button"
                            onClick={handleRedirectionServicePlan}
                            className="btn btn-type1"
                          >
                            Service Plans
                          </button>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                          <div className="form-group text-center mb-0">
                              <label className="mb-0">
                                  <span>Already have an account?</span>
                                  <br />
                                  <Link to="#" onClick={handleModalBox}>
                                      Login
                                  </Link>
                              </label>
                          </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <div className="heading_sec1">
                  <h3>
                    Want To Contact<br/>
                    {nannyName}
                  </h3>
                  <p>
                    Please register and subscribe to our affordable plan and get in touch with Maids and Nannies
                  </p>
                </div>
                <div className="modal_content">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-1 text-center">
                          <button
                            type="button"
                            onClick={handleRedirection}
                            className="btn btn-type1"
                          >
                            Register Here
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group text-center mb-0">
                          <label className="mb-0">
                            <span>Already have an account?</span>
                            <br />
                            <Link to="#" onClick={handleModalBox}>
                              Login
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Modal>
        </>
      )}
      {loader ? <RenderLoader trans={true} /> : null}
      {isOpenContact && (
        <>
          <Modal show={isOpenContact} onHide={closeContactModal}>
            <Modal.Body>
              <button
                type="button"
                className="btn-close"
                onClick={closeContactModal}
              ></button>
              <div className="modal-body">
                <div className="heading_sec2 heading_sec_left">
                  <h3>Contact Details</h3>
                </div>
                <div className="modal_content">
                  <div className="contactdetail_sec">
                    <div className="nannydetail_sec_img">
                      <img
                        src={
                          process.env.REACT_APP_URL +
                          "/" +
                          nannyData?.profile_image
                        }
                      />
                    </div>
                    <div className="nannydetail_sec_con">
                      <h5>
                        {nannyData?.first_name + " " + nannyData?.last_name}
                      </h5>
                      <ul className="contactdetail_sec_itms">
                        <li>
                          <Link to={`tel:${nannyData?.mobile}`}>
                            <i>
                              <img src={call_ic} />
                            </i>
                            <div className="contactdetail_sec_itm_cn">
                              <span>Contact Number</span>
                              <p>{nannyData?.mobile}</p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`https://wa.me/${nannyData?.whatsAppNumber}`}
                            target="_blank"
                          >
                            <i>
                              <img src={wts_ic} />
                            </i>
                            <div className="contactdetail_sec_itm_cn">
                              <span>Contact Number</span>
                              <p>{nannyData?.whatsAppNumber}</p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </GuestUserModalContext.Provider>
  );
};
