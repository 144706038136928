import React, { useState, useEffect } from "react";
import shape1 from "../../assets/img/shape1.svg";
import Register_For_Nanny from "../../assets/img/register_for_nanny.jpg";
import Register_for_job from "../../assets/img/register_for_job.jpg";
import right_arrow from "../../assets/img/right_arrow.svg";
import chat_with_expert from "../../assets/img/chat_with_expert.svg";
import { getTotalNanniesApi } from '../Action';
import { Link } from "react-router-dom";
import RenderLoader from '../../Components/RenderLoader';

function HomeSection1() {
    const [loader, setLoader] = useState(false);
    const [totalNannis, setTotalNannis] = useState(0);
    const serviceApi = async () => {
        await getTotalNanniesApi().then(response => {
          if (response.status) {
            setTotalNannis(response.data);
            setLoader(false);
          } else {
            setLoader(false)
          }
        })
      };
    
    useEffect(() => {
        window.scroll(0, 0);
        serviceApi();
      }, []);

    return (
        <>
            <section className="cat_btns">
                <div className="shape_sec1">
                    <img src={shape1} alt="" />
                </div>
                <div className="container">
                    <div className="cat_btns_in">
                        <div className="row justify-content-center">
                            <div className="col-md-5 col-sm-6">
                                <div className="cat_btns_itm">
                                    <div className="cat_btns_itm_img">
                                        <img src={Register_For_Nanny} alt="" />
                                    </div>
                                    <div className="cat_btns_itm_btn">
                                        <h4>I am looking to hire (For Family)</h4>
                                        <Link to="/registerformnanny" className="btn btn-type2">
                                            <span>Register Now</span>
                                            <i>
                                                <img src={right_arrow} alt="" />
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-6">
                                <div className="cat_btns_itm">
                                    <div className="cat_btns_itm_img">
                                        <img src={Register_for_job} alt="" />
                                    </div>
                                    <div className="cat_btns_itm_btn">
                                        <h4>I am looking for a job (Nanny)</h4>
                                        <Link to="/registerforjob" className="btn btn-type2">
                                            <span>Register Now</span>
                                            <i>
                                                <img src={right_arrow} alt="" />
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chat_sec bg_type1">
                <div className="container">
                    <div className="chat_sec_in">
                        <div className="chat_sec_head">
                            <span>{totalNannis}+</span>Available Profiles
                        </div>
                        <div className="chat_sec_btn">
                            <Link to={`https://wa.me/${"521297955"}`} target='_blank'>
                                <img src={chat_with_expert} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSection1;
