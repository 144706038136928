import React, { useEffect, useState, useContext } from "react";
// Register For Nanny 1 &&  register_for_job1.html

import { Link } from "react-router-dom";

// image import
import Register from "../assets/img/register_for_job.jpg";
import Upload from "../assets/img/upload.svg";
import Calender1 from "../assets/img/calender1.svg";
import thankyouImage from "../assets/img/thankyou.svg";
// validation formik & yup
import { Formik, Field, Form, ErrorMessage } from "formik";
import { registerjobstep2 } from "../Utils/ValidatianSchema";
import { postMethod } from "../Utils/Service";
import {
  addNannyApi,
  getNationalityApi,
  getReligionApi,
  getExperienceWithKidApi,
  getLangaugeApi,
  getSkillApi,
  getPositionApi,
  getVisaOptionListApi,
  getCityApi,
} from "./Action";
import SelectField from "./CustomSelect";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import RenderLoader from "../Components/RenderLoader";
import toast from "react-hot-toast";
import { LoginModalContext } from "../Pages/Context/LoginModalContext";
import { InputValidationContext } from "../Pages/Context/InputValidationContext";

const RegisterFormSubmit = (props) => {
  const { handleShow } = useContext(LoginModalContext);
  const {
    handleAlphabetKeyPress,
    handleNumberKeyPress,
    handleAlphaNumKeyPress,
  } = useContext(InputValidationContext);

  const [loader, setLoader] = useState(false);
  const [inputValues, setInputValues] = useState({});

  const [nationalityList, setNationalityList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [kidsExperienceList, setKidsExperienceList] = useState([]);
  const [langaugeList, setLangaugeList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [skillOption, setSkillOption] = useState([]);
  const [liveStatusOption, setLiveStatusOption] = useState([]);
  const [thankYou, setThankYou] = useState(false);
  const [visaOptionList, setVisaOptionList] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const handleJobForm = async (values, { resetForm }) => {
    values.skills = values?.skill_array?.map((item) => {
      return item?.value;
    });
    values.live_status = values?.live_status_array?.map((item) => {
      return item?.value;
    });
    values.preferred_location = values?.location_array
      ? values?.location_array?.map((item) => {
          return item?.value;
        })
      : [];

    values.profile_image = inputValues?.profile_image;
    values.video = inputValues?.video;
    values.passport = inputValues?.passport;
    values.visa = inputValues?.visa;

    // console.log(values);
    setLoader(true);
    await addNannyApi(values).then((response) => {
      if (response.status) {
        // toast.success(response.message);
        toast.success("Your request send. Wait for Approve the account.");
        setInputValues(values);
        resetForm();
        setThankYou(true);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };

  const fetch = async () => {
    //------fetching Nationality list------//
    await getNationalityApi().then((response) => {
      if (response?.status) {
        setNationalityList(response.data);
      }
    });

    //------fetching Religion list------//
    await getReligionApi().then((response) => {
      if (response?.status) {
        setReligionList(response.data);
      }
    });

    //------fetching Religion list------//
    await getExperienceWithKidApi().then((response) => {
      if (response?.status) {
        setKidsExperienceList(response.data);
      }
    });

    //------fetching Langauge list------//
    await getLangaugeApi().then((response) => {
      if (response?.status) {
        setLangaugeList(response.data);
      }
    });

    //------fetching skills list------//
    await getSkillApi().then(async (response) => {
      if (response?.status) {
        setSkillList(response.data);
        const skillArr = await makeReactSelect(response?.data);
        setSkillOption(skillArr);
      }
    });

    //------fetching Position list------//
    await getPositionApi().then(async (response) => {
      if (response?.status) {
        const liveArr = await makeArrayReactSelect(response?.data);
        setLiveStatusOption(liveArr);
      }
    });

    //------visa option list------//
    await getVisaOptionListApi().then(async (response) => {
      if (response?.status) {
        const liveArr = await makeArrayReactSelect(response?.data);
        setVisaOptionList(liveArr);
      }
    });

    if (props?.state != "") {
      setLoader(true);
      await getCityApi(props?.state).then(async (response) => {
        if (response.status) {
          const arrLocation = await makeReactSelect(response?.data);
          setLocationOption(arrLocation);
          console.log("**************************", arrLocation);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    } else {
      setLocationOption([]);
    }
  };

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, value: item?._id, key: index };
      arr.push(obj);
    });
    return arr;
  };

  const makeArrayReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item, key: index, value: item };
      arr.push(obj);
    });
    return arr;
  };

  useEffect(() => {
    window.document.title = "Register For a Job : Maids & Nanny";
    fetch();
  }, []);
  return (
    <div className="register_s_form">
      <h4>Register to get contacted directly by families</h4>
      <Formik
        initialValues={{
          step: 2,
          mobile: props?.mobile,
          login_name: props?.login_name,
          profile_image_formik: "",
          video_formik: "",
          passport_formik: "",
          visa_formik: "",
          nationality: "",
          religion: "",
          gender: "",
          marital_status: "",
          dob: "",
          visa_status: "",
          number_of_kids: "",
          total_experience: "",
          experience_with_kids: "",
          language: "",
          specific_language: "",
          skill_array: "",
          bio: "",
          live_status_array: "",
          expected_salary: "",
          available_from: "",
          location_array: "",
          acceptTerms: false,
          active: true,
        }}
        validationSchema={registerjobstep2}
        onSubmit={handleJobForm}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-12">
                <div className="form-group">
                  <label>
                    Upload Your Profile Picture <red>*</red>
                  </label>
                  <div className="">
                    {inputValues.profile_image ? (
                      <img
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                        src={
                          process.env.REACT_APP_IMG_URL +
                          "/" +
                          inputValues.profile_image
                        }
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      type="file"
                      name="profile_image_formik"
                      className="form-control"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={async (event) => {
                        try {
                          handleChange(event);
                          setInputValues({
                            ...inputValues,
                            ["profile_image"]: "",
                          });
                          var file = event.target.files[0];
                          var formData = new FormData();
                          formData.append("image", file, file.name);
                          setLoader(true);

                          await postMethod("uploadImage", formData).then(
                            (response) => {
                              if (response?.status) {
                                // setFieldValue("profile_image", response?.file);
                                setInputValues({
                                  ...inputValues,
                                  ["profile_image"]: response?.file,
                                });
                                setLoader(false);
                              } else {
                                toast.error(response.message);
                                setLoader(false);
                              }
                            }
                          );
                        } catch (e) {
                          console.log("error", e.message);
                        }
                      }}
                    />
                    {/* <div className="file_type_btn form-control">
                      <i className="file_type_ic">
                        <img src={Upload} />
                      </i>
                      <span>Upload Profile Picture</span>
                    </div> */}
                    <ErrorMessage
                      name="profile_image_formik"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-group">
                  <label>Upload Your Profile Video</label>
                  <div className="">
                    {inputValues.video ? (
                      <Link
                        to={
                          process.env.REACT_APP_IMG_URL +
                          "/" +
                          inputValues.video
                        }
                        download={true}
                        target="_blank"
                      >
                        Download
                      </Link>
                    ) : (
                      ""
                    )}
                    <Field
                      type="file"
                      name="video_formik"
                      className="form-control"
                      accept="video/*"
                      onChange={async (event) => {
                        try {
                          handleChange(event);
                          setInputValues({ ...inputValues, ["video"]: "" });
                          var file = event.target.files[0];
                          var formData = new FormData();
                          formData.append("image", file, file.name);
                          setLoader(true);

                          await postMethod("uploadImage", formData).then(
                            (response) => {
                              if (response?.status) {
                                // setFieldValue("video", response?.file);
                                setInputValues({
                                  ...inputValues,
                                  ["video"]: response?.file,
                                });
                                setLoader(false);
                              } else {
                                toast.error(response.message);
                                setLoader(false);
                              }
                            }
                          );
                        } catch (e) {
                          console.log("error", e.message);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="video_formik"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-group">
                  <label>
                    Upload Your Passport <red>*</red>
                  </label>
                  <div className="">
                    {inputValues.passport ? (
                      <img
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                        src={
                          process.env.REACT_APP_IMG_URL +
                          "/" +
                          inputValues.passport
                        }
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      type="file"
                      name="passport_formik"
                      className="form-control"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={async (event) => {
                        try {
                          handleChange(event);
                          setInputValues({ ...inputValues, ["passport"]: "" });
                          var file = event.target.files[0];
                          var formData = new FormData();
                          formData.append("image", file, file.name);
                          setLoader(true);

                          await postMethod("uploadImage", formData).then(
                            (response) => {
                              if (response?.status) {
                                // setFieldValue("passport", response?.file);
                                setInputValues({
                                  ...inputValues,
                                  ["passport"]: response?.file,
                                });
                                setLoader(false);
                              } else {
                                toast.error(response.message);
                                setLoader(false);
                              }
                            }
                          );
                        } catch (e) {
                          console.log("error", e.message);
                        }
                      }}
                    />
                    {/* <div className="file_type_btn form-control">
                      <i className="file_type_ic">
                        <img src={Upload} />
                      </i>
                      <span>Upload Your Passport</span>
                    </div> */}
                    <ErrorMessage
                      name="passport_formik"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-group">
                  <label>
                    Upload Your Visa <red>*</red>
                  </label>
                  <div className="">
                    {inputValues.visa ? (
                      <img
                        style={{ maxWidth: "150px", maxHeight: "150px" }}
                        src={
                          process.env.REACT_APP_IMG_URL + "/" + inputValues.visa
                        }
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                    <Field
                      type="file"
                      name="visa_formik"
                      className="form-control"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={async (event) => {
                        try {
                          handleChange(event);
                          setInputValues({ ...inputValues, ["visa"]: "" });
                          var file = event.target.files[0];
                          var formData = new FormData();
                          formData.append("image", file, file.name);
                          setLoader(true);
                          await postMethod("uploadImage", formData).then(
                            (response) => {
                              if (response?.status) {
                                // setFieldValue("visa", response?.file);
                                setInputValues({
                                  ...inputValues,
                                  ["visa"]: response?.file,
                                });
                                setLoader(false);
                              } else {
                                toast.error(response.message);
                                setLoader(false);
                              }
                            }
                          );
                        } catch (e) {
                          console.log("error", e.message);
                        }
                      }}
                    />
                    {/* <div className="file_type_btn form-control">
                      <i className="file_type_ic">
                        <img src={Upload} />
                      </i>
                      <span>Upload Your Visa</span>
                    </div> */}
                    <ErrorMessage
                      name="visa_formik"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>

              {/* Nationality */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Nationality <red>*</red>
                  </label>
                  <Field
                    as="select"
                    name="nationality"
                    className="form-control"
                  >
                    <option value="">Select Nationality</option>
                    {nationalityList?.length > 0
                      ? nationalityList?.map((item, index) => (
                          <option key={index} value={item?._id}>
                            {item?.name}
                          </option>
                        ))
                      : null}
                  </Field>
                  <ErrorMessage
                    name="nationality"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* Religion */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Religion <red>*</red>
                  </label>
                  <Field as="select" name="religion" className="form-control">
                    <option value="">Select Religion</option>
                    {religionList?.length > 0
                      ? religionList?.map((item, index) => (
                          <option key={index} value={item?._id}>
                            {item?.name}
                          </option>
                        ))
                      : null}
                  </Field>
                  <ErrorMessage
                    name="religion"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              {/* Gender */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Gender <red>*</red>
                  </label>
                  <div className="custom_radio_itms">
                    <label className="custom_radio">
                      Male
                      <Field type="radio" name="gender" value="male" />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom_radio">
                      Female
                      <Field type="radio" name="gender" value="female" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* Marital Status */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Marital Status <red>*</red>
                  </label>
                  <div className="custom_radio_itms">
                    <label className="custom_radio">
                      Married
                      <Field
                        type="radio"
                        name="marital_status"
                        value="married"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom_radio">
                      UnMarried
                      <Field
                        type="radio"
                        name="marital_status"
                        value="unmarried"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <ErrorMessage
                    name="marital_status"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* Age */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Date Of Birth <red>*</red>
                  </label>
                  <div className="input-group date" id="datepicker">
                    <Field
                      type="date"
                      name="dob"
                      max={moment().format("YYYY-MM-DD")}
                      className="form-control"
                      placeholder="Select Age"
                    />
                  </div>
                  <ErrorMessage name="dob" component="div" className="error" />
                </div>
              </div>

              {/* Visa Status */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Visa Status <red>*</red>
                  </label>
                  {/* <Field
                    type="text"
                    name="visa_status"
                    className="form-control"
                    placeholder="Enter visa status"
                    autoComplete="off"
                  /> */}
                  <Field
                    as="select"
                    name="visa_status"
                    className="form-control"
                  >
                    <option value="">Select an option</option>
                    {visaOptionList?.length > 0
                      ? visaOptionList?.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item?.value}
                          </option>
                        ))
                      : null}
                  </Field>
                  <ErrorMessage
                    name="visa_status"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* How Many Kids Do You Have */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    How Many Kids Do You Have ? <red>*</red>
                  </label>
                  <Field
                    type="number"
                    name="number_of_kids"
                    className="form-control"
                    placeholder="Enter no of kids"
                    autoComplete="off"
                    onKeyDown={handleNumberKeyPress}
                  />
                  <ErrorMessage
                    name="number_of_kids"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* Years of experience as a nanny */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                    Years of experience as a nanny <red>*</red>
                  </label>
                  <Field
                    type="number"
                    name="total_experience"
                    placeholder="Enter total experience"
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="total_experience"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* Experience with Kids */}
              <div className="col-xl-12">
                <div className="form-group">
                  <label>
                    Experience with Kids <red>*</red>
                  </label>

                  <div className="custom_checkbox_itms">
                    {kidsExperienceList?.length > 0
                      ? kidsExperienceList?.map((item, index) => (
                          <label className="custom_checkbox" key={index}>
                            {item?.name}
                            <Field
                              type="checkbox"
                              name="experience_with_kids"
                              value={item?._id}
                            />
                            <span className="checkmark"></span>
                          </label>
                        ))
                      : ""}
                  </div>
                  <ErrorMessage
                    name="experience_with_kids"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* languages */}
              <div className="col-xl-12">
                <div className="form-group">
                  <label>
                    Languages <red>*</red>
                  </label>
                  <div className="custom_checkbox_itms">
                    {langaugeList?.length > 0
                      ? langaugeList?.map((item, index) => (
                          <label className="custom_checkbox" key={index}>
                            {item?.name}
                            <Field
                              type="checkbox"
                              name="language"
                              value={item?._id}
                            />
                            <span className="checkmark"></span>
                          </label>
                        ))
                      : ""}
                  </div>
                  <ErrorMessage
                    name="language"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {/* Specific Language */}
              <div className="col-xl-12">
                <div className="form-group">
                  <label>Specific Language</label>
                  <Field
                    type="text"
                    name="specific_language"
                    placeholder="Enter Language"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="specific_language"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-xl-12">
                <div className="form-group">
                  <label htmlFor="skill_array">
                    Skills <red>*</red>
                  </label>
                  <Field
                    component={SelectField}
                    name="skill_array"
                    options={skillOption}
                  />
                  <ErrorMessage
                    name="skill_array"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>BIO</label>
                  <Field
                    as="textarea"
                    name="bio"
                    placeholder="Write other requirements"
                    className="form-control"
                    autoComplete="off"
                    rows="4"
                  />
                  <ErrorMessage name="bio" component="div" className="error" />
                </div>
              </div>

              {/* live status */}
              <div className="col-xl-6">
                <div className="form-group">
                  <label>
                  Type of employment <red>*</red>
                  </label>
                  <Field
                    component={SelectField}
                    name="live_status_array"
                    options={liveStatusOption}
                  />
                  <ErrorMessage
                    name="live_status_array"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Expected Salary <red>*</red>
                  </label>
                  <Field
                    type="text"
                    name="expected_salary"
                    placeholder="Enter your expected salary like 1200-1500"
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="expected_salary"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Available From</label>
                  <Field
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    name="available_from"
                    placeholder="Available from"
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="available_from"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Preferred Location</label>
                  {/* <Field
                    type="text"
                    name="preferred_location"
                    placeholder="Enter location from"
                    className="form-control"
                    autoComplete="off"
                  /> */}
                  <Field
                    component={SelectField}
                    name="location_array"
                    options={locationOption}
                  />
                  <ErrorMessage
                    name="location_array"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="custom_checkbox_itms">
                    <label className="custom_checkbox">
                      I Accept <red>*</red>{" "}
                      <Link to="/">Terms and Conditions</Link>
                      <Field type="checkbox" name="acceptTerms" />
                      <span className="checkmark"></span>
                    </label>
                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="form-group mt-1 align-right">
                <button type="submit" className="btn btn-type1">
                  Submit
                </button>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="form-group mb-0">
                <label className="mb-0">
                  If you already have an account?&nbsp;
                  <Link to="#" onClick={handleShow}>
                    Login
                  </Link>
                </label>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {loader ? <RenderLoader trans={true} /> : null}
      <Modal className="search-modal" show={thankYou}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="thank_you_image">
              <img src={thankyouImage} />
            </div>
            <div className="heading_sec1">
              <h3>Thank You!</h3>
              <p>
                Your registration has been successful and sent for verification
                to backend team. Once approved will be available for families to
                view
                {/* Your registration has been successful with us. Profile verification send for Approval. */}
              </p>
            </div>
            <div className="modal_content">
              <div className="col-md-12">
                <div className="form-group mb-0 mt-1 text-center">
                  <Link to="/" className="btn btn-type1">
                    Back to Home
                  </Link>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisterFormSubmit;
