import React, { useState, useEffect, useContext } from "react";
import left_ic from "../assets/img/left_ic.svg";
import right_ic from "../assets/img/right_ic.svg";
import RenderLoader from "../Components/RenderLoader";
import ReactPaginate from 'react-paginate';
import { shortlistedListApi, addShortlistedListApi, likeNannyProfilesShow } from './Action';
import { EmployerSidebar } from "../Pages/LoginSidebar";
import NannyProfile from "./Home/NannyProfile";
import { toast } from "react-hot-toast";

const EmpShortlist = () => {

  const [loader, setLoader] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [likeprofile, setLikeprofile] = useState([]);


  const addFavorite = async (id,fullname="") => {
    let objData = {
      nanny_id: id,
    }
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (userDetails?.role == 1) {
      setLoader(true)
      await addShortlistedListApi(objData).then(response => {
        if (response.status) {
          setLoader(false)
          toast.success(response.message);
          let result = profileList.filter((item) => {
            return item?.nanny_id?._id !== id;
          });
          setProfileList(result);
          setTotalProduct(totalProduct - 1);
        } else {
          toast.error(response.message);
          setLoader(false)
        }
      })
    } else {
      showGuestModal(fullname);
    }
  }


  const fetchLikeProfile = async () => {
    setLoader(true);
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if(userDetails){
      await likeNannyProfilesShow(userDetails?._id).then(response => {
        if (response.status) {
          setLikeprofile(response.data);
          setLoader(false);
        } else {
          setLoader(false)
        }
      })
    }
  }


  const profileListData = async (limit, page, search) => {
    let objData = {
      limit: limit,
      page: page,
      search: search
    }
    await shortlistedListApi(objData).then(response => {
      if (response.status) {
        setLoader(false)
        setProfileList(response?.data);
        setTotalProduct(response?.total);
      } else {
        setLoader(false)
      }
    })
  }
  var divisor = (totalProduct / 6) >> 0;
  var remainder = totalProduct % 6;
  useEffect(() => {
    window.document.title="Short listed profile : Maids & Nanny";
    profileListData(6, paginationIndex);
    fetchLikeProfile();
  }, []);
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">

            <EmployerSidebar />
            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }
            <div className="profile_content">
              <div className="profile_content_in">
                <h4>My Shortlisted </h4>
                <div className="profile_itms">
                  <div className="row">
                    {
                      profileList?.length > 0 ?
                        profileList?.map((item, index) =>
                          <div className="col-md-6" key={index}>
                            <NannyProfile key={index} item={item?.nanny_id} favourite={true} addFavorite={addFavorite} likeprofile={likeprofile} />
                          </div>
                        )
                        :
                        <div className="col-md-12">
                          <h6>No short listed yet...</h6>
                        </div>
                    }
                  </div>

                  <div className="pagination_sec">
                    {
                      totalProduct > 6 ?
                        <ReactPaginate
                          // previousLabel={"Prev"}
                          previousLabel={<img src={left_ic} />}
                          // nextLabel={"Next"}
                          nextLabel={<img src={right_ic} />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={remainder >= 1 ? divisor + 1 : divisor}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={async (e) => { setLoader(true); setPaginationIndex(e.selected + 1); profileListData(6, e.selected + 1) }}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active pp"} />
                        :
                        null
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmpShortlist;
