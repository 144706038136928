import React, { useState, useEffect } from 'react'
import { getServiceApi } from "./Action";
import { useParams } from "react-router-dom";
import RenderLoader from "../Components/RenderLoader";

function OtherService() {
  const { slug } = useParams();
  const [plainText, setPlainText] = useState({});
  const [loader, setLoader] = useState(false);

  const serviceApi = async () => {
    await getServiceApi(slug).then(response => {
      if (response.status) {
        setPlainText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };
  useEffect(() => {
    window.document.title = slug + ": Maids & Nanny";
    serviceApi()
  }, [slug]);
  return (
    <section className="plan_sec bg_type2">
      <div className="container">
        <div className="plan_sec_in">
          <div className="heading_sec1">
            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }
            <h3>{plainText?.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: plainText?.description }}></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OtherService