import React, { useState, useEffect } from "react";
import category_ic from "../assets/img/category_ic.svg";
import blog_big from "../assets/img/blog_big.jpg";
import blg1 from "../assets/img/blg1.jpg";
import blg2 from "../assets/img/blg2.jpg";
import blg3 from "../assets/img/blg3.jpg";

import { Link, useParams } from "react-router-dom";
import RenderLoader from "../Components/RenderLoader";
import { blogDetailsApi, getLatestBlogsApi, getServiceApi } from "./Action";

const BlogDetails = () => {
  const [loader, setLoader] = useState(false);
  const [blogDetails, setBlogDetails] = useState({});
  const [blogList, setBlogList] = useState([]);
  const [relatedBlogText, setRelatedBlogText] = useState({});
  const { id } = useParams();

  const blogDetailsData = async () => {
    setLoader(true);
    await blogDetailsApi(id).then(async response => {
      if (response.status) {
        setBlogDetails(response?.data);
        getLatestBlog(response?.data?.type?._id, id);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  const getLatestBlog = async (category, id) => {
    setLoader(true);
    let objData = {
      category: category,
      id: id
    }
    await getLatestBlogsApi(objData).then(response => {
      if (response.status) {
        setBlogList(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };

  const serviceApi = async () => {
    setLoader(true);
    await getServiceApi('related-blogs').then(response => {
      if (response.status) {
        setRelatedBlogText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };
  const handleRelatedBlogs = async(uId) => {
    await blogDetailsApi(uId).then(async response => {
      if (response.status) {
        setBlogDetails(response?.data);
        getLatestBlog(response?.data?.type?._id, id);
        window.scroll(0, 0);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    window.document.title="Blog Details : Maids & Nanny";
    window.scroll(0, 0);
    serviceApi();
    blogDetailsData();
  }, []);

  return (
    <>
      <section className="blog_detail_s">
        <div className="container">
          <div className="blog_detail_in">
            <div className="blog_detail_con">
              {
                loader ?
                  <RenderLoader trans={true} />
                  : null
              }
              <div className="blog_cat">
                <img src={category_ic} alt="" />
                <span>{blogDetails?.type?.name}</span>
              </div>
              <div className="blog_title">
                <h2>{blogDetails?.title}</h2>
              </div>
              <div className="blog_detail_img">
                <img src={process.env.REACT_APP_URL + "/" + blogDetails?.image} alt="" />
              </div>
              <p dangerouslySetInnerHTML={{ __html: blogDetails?.description }}></p>

              {/* <ul>
                <li>
                  <h5>
                    <span>Communication issues</span>
                  </h5>
                  <p>
                    Miscommunication frequently leads to misunderstandings and
                    dissatisfaction. Consider whether there is a language
                    barrier, or if unclear instructions are causing the issue.
                  </p>
                </li>
                <li>
                  <h5>
                    <span>Job expectations</span>
                  </h5>
                  <p>
                    Ensure that both you and your maid understand the job
                    expectations. It’s possible that the maid is not aware of
                    specific tasks or responsibilities, leading to a disconnect
                    in expectations.
                  </p>
                </li>
                <li>
                  <h5>
                    <span>Cultural differences</span>
                  </h5>
                  <p>
                    Cultural differences can sometimes cause friction in the
                    employer-employee relationship. Make an effort to understand
                    and respect each other’s cultural background and practices.
                  </p>
                </li>
              </ul> */}


              {/* <div className="conclution_sec">
                <h4>Conclusion</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div> */}
            </div>


            <div>
              <div>
                <div className="blog_sec_in">
                  <div className="heading_sec1">
                    <h3>{relatedBlogText?.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: relatedBlogText?.description }}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* Related Blogs */}
      <section section className="blog_Sec bg_type1" >
        <div className="container">
          <div className="blog_itms">
            <div className="row">
              {blogList?.length > 0 ?

                blogList?.map((item, index) =>
                  <div className="col-md-4 col-sm-6" key={index}>
                    <div className="blog_itm">
                      <div className="blog_itm_img">
                        <img src={process.env.REACT_APP_URL + "/" + item?.image} />
                      </div>
                      <div className="blog_itm_con">
                        <h4>{item?.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: item?.description ? item?.description.substring(0, 100) : '' }}></p>
                        <Link to={"##"} onClick={() => handleRelatedBlogs(item?._id)} className="more_btn">
                          View More
                        </Link>
                      </div>
                    </div>
                  </div>

                )

                : <h1>No record available...</h1>}

            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default BlogDetails;
