import React, { createContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import RenderLoader from "../../Components/RenderLoader";
import { showNannyVideo } from "../Action";

export const VideoModalContext = createContext();
export const VideoModalContextProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [nannyData, setNannyData] = useState({});

  const openModal = async (id) => {
    setIsOpen(true);
    setLoader(true);
    await showNannyVideo(id).then(async (response) => {
      if (response.status) {
        setNannyData(response?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <VideoModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}

      {isOpen && (
        <>
          {loader ? <RenderLoader trans={true} /> : null}
          <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="heading_sec1">
                  <h3>
                    About {nannyData?.first_name + " " + nannyData?.last_name}
                  </h3>
                </div>
                <div className="modal_content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group text-center mb-0">
                        <label className="mb-0">
                          {nannyData?.video ? (
                            <div className="nannydetail_video" id="video_sec">
                              <ReactPlayer
                                url={
                                  process.env.REACT_APP_URL +
                                  "/" +
                                  nannyData?.video
                                }
                                playing={false}
                                width="100%"
                                height="300px"
                            
                                controls={true}
                              />
                            </div>
                          ) : (
                            <h5>Video not found...</h5>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </VideoModalContext.Provider>
  );
};
