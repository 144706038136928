import React, { useState, useEffect,useContext } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { validationSchemaVisa } from "../Utils/ValidatianSchema"
import { getServiceApi, addVisaApi,getNationalityApi } from './Action';
import QueAns from "./Home/FAQ/QueAns";
import RenderLoader from "../Components/RenderLoader";
import toast from 'react-hot-toast';
import { InputValidationContext } from "../Pages/Context/InputValidationContext";

const VisaSponsorship = () => {

  const { handleAlphabetKeyPress, handleNumberKeyPress, handleAlphaNumKeyPress } =
  useContext(InputValidationContext);

  const [loader, setLoader] = useState(false);
  const [visaText, setVisaText] = useState({});
  const [nationalityList, setNationalityList] = useState([]);

  const serviceApi = async () => {

    await getServiceApi('visa-sponsorship').then(response => {
      if (response.status) {
        setVisaText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })

    //------fetching Nationality list------//
    await getNationalityApi().then((response) => {
      if (response?.status) {
        setNationalityList(response.data);
      }
    })

  };

  const [activeKey, setActiveKey] = useState(null);
  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  // validation && form
  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true);
    await addVisaApi(values).then(response => {
      if (response.status) {
        toast.success("Your request sent successfully.");
        resetForm();
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
    // console.log(values);
  };
  useEffect(() => {
    window.document.title="Visa & Sponrsorship : Maids & Nanny";
    window.scroll(0, 0);
    serviceApi();
  }, [])

  return (
    <>
      <section className="visa_sec">
        <div className="container">
          <div className="visa_sec_in">
            <div className="row">
              <div className="col-lg-6">
                <div className="visa_form">
                  <div className="heading_sec2">

                    {
                      loader ?
                        <RenderLoader trans={true} />
                        : null
                    }
                    <h3>{visaText?.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: visaText?.description }}></p>
                  </div>
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      nationality: "",
                      email: "",
                      comment: "",

                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchemaVisa}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="firstName">First Name <red>*</red></label>
                              <input
                                type="text"
                                name="first_name"
                                className="form-control"
                                placeholder="Enter Full Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                                onKeyDown={handleAlphabetKeyPress}
                              />
                              {errors.first_name && touched.first_name && (
                                <div className="error">{errors.first_name}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="last_name">Last Name <red>*</red></label>
                              <input
                                type="text"
                                name="last_name"
                                className="form-control"
                                placeholder="Enter Last Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                                onKeyDown={handleAlphabetKeyPress}
                              />
                              {errors.last_name && touched.last_name && (
                                <div className="error">{errors.last_name}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="nationality">
                                Nationality of Nanny / Maid <red>*</red>
                              </label>
                              {/* <input
                                type="text"
                                name="nationality"
                                className="form-control"
                                placeholder="Enter Nationality"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.nationality}
                                onKeyDown={handleAlphabetKeyPress}
                              /> */}
                              <Field as="select" name="nationality" className="form-control">
                                <option value="">Select Nationality</option>
                                {nationalityList?.length > 0 ?
                                  nationalityList?.map((item, index) =>
                                    <option key={index} value={item?._id}>{item?.name}</option>
                                  )
                                  : null}
                              </Field>
                              {errors.nationality && touched.nationality && (
                                <div className="error">
                                  {errors.nationality}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Your Email <red>*</red></label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && touched.email && (
                                <div className="error">{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="comment">
                                Comment / Question (if any) <red>*</red>
                              </label>
                              <textarea
                                name="comment"
                                className="form-control"
                                rows="4"
                                placeholder="Tell us about you"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comment}
                                onKeyDown={handleAlphabetKeyPress}
                              />
                              {errors.comment && touched.comment && (
                                <div className="error">{errors.comment}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <button type="submit" className="btn btn-type1">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <QueAns />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisaSponsorship;
