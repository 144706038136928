import Select from "react-select";
import { useField } from "formik";
import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function MobileField(props) {
    const [field, state, { setValue, setTouched }] = useField(props.field.name);

    // value is an array now
    // const onChange = (value) => {
    //     setValue(value);
    // };

    // use value to make this a  controlled component
    // now when the form receives a value for 'campfeatures' it will populate as expected
    return (
        <PhoneInput
            {...props}
            value={state?.value}
            className="inputFields"
            autocomplete="off"
            country={'ae'}
            onChange={
                (e, data) => {
                    if (e != "") {
                        (data?.format ?
                            data?.format[0] ?
                                e.length > data.dialCode.length ?
                                    setValue(data?.format[0] + e)
                                    : setValue('')
                                : setValue('')
                            : setValue(''))
                    } else {
                        setValue('')
                    }
                }
            }
            onBlur={setTouched}
        />
    );
}
