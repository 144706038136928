import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getPlantypeByIdApi, couponApplyApi } from "./Action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import RenderLoader from "../Components/RenderLoader";
import toast from 'react-hot-toast';
import premium_icon from "../assets/img/premium-icon.svg";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const PUBLIC_KEY = `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`;
const stripeTestPromise = loadStripe(PUBLIC_KEY);
import StripeContainer from "./StripeContainer";

const Payment = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [planDetails, setPlanDetails] = useState({});
  const [coupon, setCoupon] = useState({});
  const [planAmount, setPlanAmount] = useState({});
  const [couponCode, setCouponCode] = useState('');

  const planBySlug = async () => {
    await getPlantypeByIdApi(id).then(response => {
      if (response.status) {
        setLoader(false);
        setPlanDetails(response.data);
        setPlanAmount({
          code: "",
          price: response.data?.price,
          discount: response.data?.discount,
          total_amount: response.data?.price
        });
        if (sessionStorage.getItem('coupon')) {
          handleApplyCoupon({ code: sessionStorage.getItem('coupon') });
        }
      } else {
        setLoader(false)
      }
    })
  }

  const handleApplyCoupon = async (values) => {
    // console.log("*********", values);
    const data = {
      code: values.code,
      planId: id
    }
    setLoader(true);
    await couponApplyApi(data).then(response => {
      if (response.status) {
        setLoader(false);
        // toast.success(response.message);
        setCoupon(response.data);
        sessionStorage.setItem('coupon', values.code);
        setPlanAmount({
          code: response.data?.code,
          price: response.data?.price,
          discount: response.data?.discount,
          total_amount: response.data?.total_price
        });
        setCouponCode("");
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
  }

  const removeCouponCode = () => {
    sessionStorage.removeItem('coupon');
    planBySlug();
  }

  useEffect(() => {
    window.document.title = "Plan Details : Maids & Nanny";
    window.scroll(0, 0);
    planBySlug();
  }, []);
  return (
    <>
      <section className="payment_sec">
        <div className="container">
          <div className="payment_sec_in">
            <div className="row">
              <div className="col-md-6">
                <div className="plan_detail">
                  {
                    loader ?
                      <RenderLoader trans={true} />
                      : null
                  }
                  <div className="plan_selected">
                    <div className="plan_selected_img">
                      <img src={premium_icon} />
                    </div>
                    <div className="plan_selected_con">
                      <h4>{planDetails?.name}</h4>
                      <div className="plan_sec_prc">
                        <span className="plan_sec_prc_currency">AED</span> {planDetails?.price}
                        <span className="plan_sec_prc_time">/ {planDetails?.duration + " " + planDetails?.duration_type}</span>
                        {/* <span className="plan_sec_prc_time">/ {planDetails?.duration ? convertDays(planDetails?.duration) : ""}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="service_plan_itms">
                    <h4>Service Plan Specifications</h4>
                    <ul className="list_type1">
                      {
                        planDetails?.features?.map((item, index) =>
                          <li key={index}>{item?.value}</li>
                        )}
                      <li className={planDetails?.consultant ? "" : "text-decoration-line-through"}>
                        Dedicated consultant*
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="payment_detail">
                  <div className="payment_head">
                    <h4>Payable Amount </h4>
                    <div className="plan_sec_prc">
                      <span className="plan_sec_prc_currency">AED</span> {planAmount?.total_amount}
                    </div>
                  </div>

                  <div className="row">

                    {/* <div className="col-md-12">
                        <div className="form-group">
                          <div className="custom_checkbox_itms">
                            <label className="custom_checkbox">
                              Avail Discount of 10%
                              <input type="checkbox" />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div> */}

                    <div className="col-md-12">
                      <div className="price_specification form-group">
                        <label>Price Specification:</label>
                        <ul className="price_specification_itms">
                          <li>
                            <span>Price</span>
                            <span className="span_value">AED {planAmount?.price}</span>
                          </li>
                          {planAmount?.discount > 0 &&
                            <>
                              <li>
                                <span>Discount --<Link to="#" onClick={removeCouponCode}>Remove Coupon </Link></span>

                                <span className="span_value">AED {planAmount?.discount} </span>
                              </li>
                              <li>
                                <span>Coupon Name : {planAmount?.code} </span>
                              </li>
                            </>
                          }
                          <li>
                            <span>Total Price</span>
                            <span className="span_value">AED {planAmount?.total_amount}</span>
                          </li>

                        </ul>
                        {/* ------- this is for coupon code section -------- */}
                        {planAmount?.discount == 0 &&
                          <Formik
                            initialValues={{
                              code: ""
                            }}
                            validationSchema={Yup.object().shape({
                              code: Yup.string()
                                .required("Coupon code is required"),
                            })}
                            onSubmit={handleApplyCoupon}
                          >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (

                              <Form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label>Enter your coupon code here</label>
                                      <div className="coupon_input">
                                        <label>
                                          <Field
                                            type="text"
                                            name="code"
                                            className=""
                                            placeholder="Enter coupon code"
                                            autoComplete="off"
                                            value={couponCode}
                                            onChange={(e) => { handleChange(e), setCouponCode(e.target.value.toUpperCase()) }}
                                          />

                                          {touched.code && errors.code && (
                                            <div className="error">{errors.code}</div>
                                          )}
                                        </label>
                                        <button type="submit" className="btn btn-type1" disabled={isSubmitting}>
                                          Submit
                                        </button>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                              </Form>
                            )}
                          </Formik>
                        }
                      </div>
                    </div>
                    <hr />
                    {/* ------- this is for card section -------- */}
                    <Elements stripe={stripeTestPromise}>
                      <StripeContainer planId={id} coupon={sessionStorage.getItem('coupon')} />
                    </Elements>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

       
      </section >
    </>
  );
};

export default Payment;
