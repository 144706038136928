import React, { createContext, useState } from 'react';
export const CategoryContext = createContext();
export const CategoryProvider = ({ children }) => {
  const [searchData, setSearchData] = useState({});

  const updateSearchData = (data) => {
    setSearchData(data);
  };

  return (
    <CategoryContext.Provider value={{ searchData, updateSearchData }}>
      {children}
    </CategoryContext.Provider>
  );
};