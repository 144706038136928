// Register For Nanny

import React, { useState, useEffect, useContext } from "react";
import Register_For_Nanny from "../assets/img/register_for_nanny.jpg";
import thankyouImage from "../assets/img/thankyou.svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import {
  registerformnannyformone,
  otpValidation,
} from "../Utils/ValidatianSchema";
import {
  getServiceApi,
  getCategoryApi,
  // getLocationApi,
  addEmployerApi,
  verfyOtpApi,
  resendOtpApi,
} from "./Action";
import SelectField from "./CustomSelect";
import MobileField from "./CustomMobile";
import RegisterFormNannyStep2 from "./RegisterForNannyStep2";
import RenderLoader from "../Components/RenderLoader";
import toast from "react-hot-toast";
import { LoginModalContext } from "../Pages/Context/LoginModalContext";
import { InputValidationContext } from "../Pages/Context/InputValidationContext";
import { setAuthorizationToken } from "../Utils/Service";
import { setCookies } from "../Utils/cookies";

const RegisterFormNannyStep1 = () => {
  const {
    handleAlphabetKeyPress,
    handleNumberKeyPress,
    handleAlphaNumKeyPress,
  } = useContext(InputValidationContext);

  const [thankYou, setThankYou] = useState(false);
  const { handleShow } = useContext(LoginModalContext);
  const [loader, setLoader] = useState(true);
  const [registerNanny, setRegisterNanny] = useState({});
  const [values, setValues] = useState({});
  const [categoryOption, setCategoryOption] = useState([]);
  // const [locationOption, setLocationOption] = useState([]);
  const [formTwo, setFormTwo] = useState(false);

  const categoryList = async () => {
    await getCategoryApi().then(async (response) => {
      if (response.status) {
        const categoryArr = await makeReactSelect(response?.data);
        setCategoryOption(categoryArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  // const locationList = async () => {
  //   await getLocationApi().then(async (response) => {
  //     if (response.status) {
  //       const locationArr = await makeReactSelect(response?.data);
  //       setLocationOption(locationArr);
  //       setLoader(false);
  //     } else {
  //       setLoader(false);
  //     }
  //   });
  // };

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, value: item?._id, key: index };
      arr.push(obj);
    });
    return arr;
  };

  const serviceApi = async () => {
    await getServiceApi("register-nanny").then((response) => {
      if (response.status) {
        setRegisterNanny(response.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  // // for  modal
  const [show, setShow] = useState(false);
  const handleCloseMobile = () => setShow(false);
  // for email modal
  const [showEmail, setShowEmail] = useState(false);
  const handleCloseEmail = () => setShowEmail(false);

  const handleVerifyMobile = async (values) => {
    // console.log("***mobile otp verify***", values);
    await verfyOtpApi(values).then((response) => {
      if (response.status) {
        toast.success(response.message);
        setShow(false);
        setShowEmail(true);
        setLoader(false);
        setCountdown(process.env.REACT_APP_RESEND_COUNTER);
        startTimer();
        setCompleted(true);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };

  const handleVerifyEmail = async (inputValues) => {
    console.log("***email otp verify***", inputValues);
    await verfyOtpApi(inputValues).then(async (response) => {
      if (response.status) {
        toast.success(response.message);
        setShowEmail(false);
        setLoader(false);
        
        var data = {
          step: 2,
          email:values?.email,
          mobile:values?.mobile,
        };
        await addEmployerApi(data).then((response) => {
          if (response.status) {            
            sessionStorage.setItem("token", response.token);
            sessionStorage.setItem("user_details", JSON.stringify(response.data));
            setCookies("_isJwtFront", true);
            setLoader(true);
            setAuthorizationToken(true);
            // toast.success(response.message);
            setThankYou(true);
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });

        //---hiding 2nd form---//
        /*
        setFormTwo(true);
        */
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };
  //------------count down to resend oto----------//
  const [countdown, setCountdown] = useState(0);
  const [completed, setCompleted] = useState(true);

  const handleResendOtp = async (type) => {
    const obj = {
      type: type,
      mobile: values?.mobile,
    };
    
    await resendOtpApi(obj).then((response) => {
      if (response.status) {
        setCountdown(process.env.REACT_APP_RESEND_COUNTER);
        startTimer();
        setCompleted(true);
        toast.success(response.message);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };

  const startTimer = () => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCompleted(false);
    }
  };
  useEffect(() => {
    startTimer();
  }, [countdown]);
  //------------count down to resend oto----------//

  const handleSubmitForm1 = async (values) => {
    // values.job_category = values?.job_array?.map((item) => {
    //   return item?.value;
    // });
    // values.location = values?.location_array?.map((item) => {
    //   return item?.value;
    // });
    setLoader(true);
    await addEmployerApi(values).then((response) => {
      if (response.status) {
        setLoader(false);
        toast.success(response.message);
        setValues(values);
        // setShow(true);
        setShowEmail(true);
        setCountdown(process.env.REACT_APP_RESEND_COUNTER);
        startTimer();
        setCompleted(true);
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    window.document.title = "Register For Nanny : Maids & Nanny";
    serviceApi();
    categoryList();
    // locationList();
  }, []);
  return (
    <>
      <div className="main_sec">
        <section className="register_s">
          <div className="container">
            <div className="register_s_in">
              <div className="row">
                <div className="col-md-6">
                  <div className="register_s_img">
                    <img src={Register_For_Nanny} />
                  </div>
                  {loader ? <RenderLoader trans={true} /> : null}
                  <div className="register_s_con mt-3">
                    <div className="heading_sec2">
                      <h3>{registerNanny?.title}</h3>
                    </div>
                    <div className="register_s_pera">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: registerNanny?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  {formTwo ? (
                    <RegisterFormNannyStep2
                      mobile={values?.mobile}
                      email={values?.email}
                    />
                  ) : (
                    <div className="register_s_form">
                      <h4>Register to get access to contact details</h4>
                      {/* //---form One---// */}
                      <Formik
                        initialValues={{
                          step: 1,
                          first_name: "",
                          last_name: "",
                          email: "",
                          password: "",
                          mobile: "",
                          whatsAppNumber: "",
                          // job_array: "",
                          // location_array: "",
                        }}
                        validationSchema={registerformnannyformone}
                        onSubmit={handleSubmitForm1}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="form-group">
                                  <label>
                                    First Name <red>*</red>
                                  </label>
                                  <Field
                                    type="text"
                                    name="first_name"
                                    placeholder="Enter First Name"
                                    className="form-control"
                                    autoComplete="off"
                                    onKeyDown={handleAlphabetKeyPress}
                                  />
                                  <ErrorMessage
                                    name="first_name"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="form-group">
                                  <label>
                                    Last Name <red>*</red>
                                  </label>
                                  <Field
                                    type="text"
                                    name="last_name"
                                    placeholder="Enter Last Name"
                                    className="form-control"
                                    autoComplete="off"
                                    onKeyDown={handleAlphabetKeyPress}
                                  />
                                  <ErrorMessage
                                    name="last_name"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>

                              <div className="col-xl-6">
                                <div className="form-group">
                                  <label>
                                    Mobile Number <red>*</red>
                                  </label>

                                  <Field
                                    component={MobileField}
                                    name="mobile"
                                    type="tel"
                                    required="true"
                                    value={values?.mobile}
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>

                              <div className="col-xl-6">
                                <div className="form-group">
                                  <label>
                                    WhatsApp Number <red>*</red>
                                  </label>
                                  <Field
                                    component={MobileField}
                                    name="whatsAppNumber"
                                    type="tel"
                                    required="true"
                                    value={values.whatsAppNumber}
                                  />
                                  <ErrorMessage
                                    name="whatsAppNumber"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>

                              <div className="col-xl-6">
                                <div className="form-group">
                                  <label>
                                    Email Address <red>*</red>
                                  </label>
                                  <Field
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email Address"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="form-group">
                                  <label>
                                    Password <red>*</red>
                                  </label>
                                  <Field
                                    type="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    className="form-control"
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                              {/* <div className="col-xl-12">
                                <div className="form-group">
                                  <label htmlFor="job_array">
                                    Looking For <red>*</red>
                                  </label>
                                  <Field
                                    component={SelectField}
                                    name="job_array"
                                    options={categoryOption}
                                  />
                                  <ErrorMessage
                                    name="job_array"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div> */}
                              {/* <div className="col-xl-12">
                                <div className="form-group">
                                  <label htmlFor="location_array">
                                    Location <red>*</red>
                                  </label>
                                  <Field
                                    component={SelectField}
                                    name="location_array"
                                    options={locationOption}
                                  />
                                  <ErrorMessage
                                    name="location_array"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div> */}
                              <div className="col-xl-12">
                                <div className="form-group mt-1">
                                  <button
                                    type="submit"
                                    className="btn btn-type1"
                                    disabled={isSubmitting}
                                  >
                                    Continue to Register
                                  </button>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group mb-0">
                                  <label className="mb-0">
                                    If you already have an account?&nbsp;
                                    <Link to="#" onClick={handleShow}>
                                      Login
                                    </Link>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                  {/* Mobile Otp Verfy */}
                  <div>
                    <Modal
                      className="search-modal"
                      show={show}
                      onHide={handleCloseMobile}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        {/* verify Number */}
                        <div className="modal-body">
                          <div className="heading_sec1">
                            <h3>Verify Number</h3>
                            <p>
                              Please enter OTP sent to your registered mobile
                              number XXX XXXX XX{values?.mobile?.slice(-3)}
                            </p>
                          </div>
                          <div className="modal_content">
                            <Formik
                              initialValues={{
                                otp: "",
                                mobile: values?.mobile,
                                type: "mobile",
                              }}
                              onSubmit={handleVerifyMobile}
                              validationSchema={otpValidation}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          name="otp"
                                          className="form-control"
                                          placeholder="Please enter OTP sent to your registered mobile number."
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.otp}
                                        />
                                        {errors.otp && touched.otp && (
                                          <div className="error">
                                            {errors.otp}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group mt-1 text-center">
                                        <button
                                          type="submit"
                                          className="btn btn-type1"
                                          disabled={isSubmitting}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group text-center mb-0">
                                        <p className="otm_time">
                                          00:{countdown}
                                        </p>
                                        <label className="mb-0">
                                          You din't receive OTP?&nbsp;
                                          {completed ? (
                                            <span>Resend</span>
                                          ) : (
                                            <Link
                                              to="/"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleResendOtp("mobile");
                                              }}
                                            >
                                              Resend
                                            </Link>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>

                  {/* Email Otp verfy */}
                  <div>
                    <Modal
                      className="search-modal"
                      show={showEmail}
                      onHide={handleCloseEmail}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <div className="modal-body">
                          <div className="heading_sec1">
                            <h3>Verify Email</h3>
                            <p>
                              Please enter OTP sent to your registered email ID{" "}
                              <span>{values?.email}</span>
                            </p>
                            <b>It has come to our attention that some important emails might be ending up in your junk/spam folder so please check there also.</b>
                          </div>
                          <div className="modal_content">
                            <Formik
                              initialValues={{
                                otp: "",
                                mobile: values?.mobile,
                                type: "email",
                              }}
                              onSubmit={handleVerifyEmail}
                              validationSchema={otpValidation}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <input
                                          type="number"
                                          name="otp"
                                          className="form-control"
                                          placeholder="Please enter OTP sent to your registered email ID"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.otp}
                                        />
                                        {errors.otp && touched.otp && (
                                          <div className="error">
                                            {errors.otp}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group mt-1 text-center">
                                        <button
                                          type="submit"
                                          className="btn btn-type1"
                                          disabled={isSubmitting}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group text-center mb-0">
                                        <p className="otm_time">
                                          00:{countdown}
                                        </p>
                                        <label className="mb-0">
                                          You din't receive OTP?&nbsp;
                                          {completed ? (
                                            <span>Resend</span>
                                          ) : (
                                            <Link
                                              to="/"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleResendOtp("email");
                                              }}
                                            >
                                              Resend
                                            </Link>
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      <Modal className="search-modal" show={thankYou}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="thank_you_image">
              <img src={thankyouImage} />
            </div>
            <div className="heading_sec1">
              <h3>Thank You!</h3>
              <p>
                Your registration is successful. Start searching your most
                suitable household profiles.
              </p>
            </div>
            <div className="modal_content">
              <div className="col-md-12">
                <div className="form-group mb-0 mt-1 text-center">
                  <Link
                    to="#"
                    onClick={(e) => window.open("/searchnannies", "_self")}
                    className="btn btn-type1"
                  >
                    {/* <Link to="/searchnannies" className="btn btn-type1"> */}
                    See Profiles
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to="#"
                    onClick={(e) => window.open("/serviceplan", "_self")}
                    className="btn btn-type1"
                  >
                    {/* <Link to="/serviceplan" className="btn btn-type1"> */}
                    Choose Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default RegisterFormNannyStep1;
