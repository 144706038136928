import React, { useState, useEffect } from 'react';
import { getFAQsApi } from '../../Action';
import { Accordion } from "react-bootstrap";
import RenderLoader from '../../../Components/RenderLoader';

const QueAns = () => {
    const [loader, setLoader] = useState(false);
    const [faqData, setFaqData] = useState([]);
    const [activeKey, setActiveKey] = useState(null);

    const getFaqData = async () => {
        setLoader(true);
        await getFAQsApi().then(response => {
            if (response.status) {
                setFaqData(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };
    useEffect(() => {
        getFaqData();
    }, []);
    return (
        <div className="col-lg-6">
            <div className="faq_sec_itms">
            {
          loader ?
            <RenderLoader trans={true} />
            : null
        }
                <Accordion activeKey={activeKey}>

                    {faqData?.length > 0 ?
                        faqData?.map((item, index) =>
                            <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header onClick={() => toggleAccordion(index)} >
                                    {item?.question}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p dangerouslySetInnerHTML={{ __html: item?.answer }}></p>
                                </Accordion.Body>
                            </Accordion.Item>

                        ) : null}
                </Accordion>
            </div>
        </div>
    )
}

export default QueAns
