import "./assets/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/stylesheet.css";
import React, { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Blog from "./Pages/Blog";
import SearchNannies from "./Pages/SearchNannies";
import ServicePlan from "./Pages/ServicePlan";
import ContactUs from "./Pages/ContactUs";
import OtherAction from "./Pages/OtherAction";
import OtherService from "./Pages/OtherService";
import Register from "./Pages/Register";
// react router dom@v6
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import RegisterForJob from "./Pages/RegisterForJobStep1";
import Payment from "./Pages/Payment";
import NannyDetails from "./Pages/NannyDetails";
import BlogDetails from "./Pages/BlogDetails";
import VisaSponsorship from "./Pages/VisaSponsorship";
import RegisterFormNanny from "./Pages/RegisterForNannyStep1";

import EmployeeProfile from "./Pages/EmployeeProfile";
import NannyEdit from "./Pages/NannyEdit";
import EmployerEdit from "./Pages/EmployerEdit";
import ChangePassword from "./Pages/ChangePassword";
import EmpPlan from "./Pages/EmpPlan";
import EmpShortlist from "./Pages/EmpShortlist";
import Nannypro from "./Pages/Nannypro";
import LoaderPage from "./Components/Loader/Loader";
import ResetPassword from "./Pages/ResetPassword";
import { CategoryProvider } from "./Pages/Context/CategoryContext";
import { VideoModalContextProvider } from "./Pages/Context/VideoModalContext";
import { GuestUserModalContextProvider } from "./Pages/Context/GuestUserModalContext";
import { LoginModalContextProvider } from "./Pages/Context/LoginModalContext";
import { InputValidationProvider } from "./Pages/Context/InputValidationContext";

function App() {
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const loginUser = sessionStorage.getItem('user_details') ? JSON.parse(sessionStorage.getItem('user_details')) : '';
    setUserDetails(loginUser);
  }, []);

  return (
    <div className="App">
      <>
        <CategoryProvider>
          <VideoModalContextProvider>
            <Router>
              <InputValidationProvider>
                <LoginModalContextProvider>
                  <GuestUserModalContextProvider>
                    <Header />
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/searchnannies" element={<SearchNannies />} />
                      <Route path="/nannydetails/:id" element={<NannyDetails />} />
                      <Route path="/blog" element={<Blog />} />
                      <Route path="/blogdetails/:id" element={<BlogDetails />} />
                      <Route path="/about" element={<AboutUs />} />
                      <Route path="/contactUs" element={<ContactUs />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/visa" element={<VisaSponsorship />} />
                      <Route path="/otherAction" element={<OtherAction />} />
                      <Route path="/serviceplan" element={<ServicePlan />} />
                      <Route path="/payment/:id" element={<Payment />} />
                      <Route path="/registerformnanny" element={<RegisterFormNanny />} />
                      <Route path="/registerforjob" element={<RegisterForJob />} />
                      <Route path="/page/:slug" element={<OtherService />} />
                      {userDetails?.role === 2 ?
                        <>
                          <Route path="/nannypro" element={<Nannypro />} />
                          <Route path="/nannyEdit" element={<NannyEdit />} />
                        </>
                        : ''}
                      {userDetails?.role === 1 ?
                        <>
                          <Route path="/empProfile" element={<EmployeeProfile />} />
                          <Route path="/employerEdit" element={<EmployerEdit />} />
                          <Route path="/empplan" element={<EmpPlan />} />
                          <Route path="/empshortlist" element={<EmpShortlist />} />
                        </>
                        : ''}
                      {userDetails ?
                        <>
                          <Route path="/changepassword" element={<ChangePassword />} />
                        </>
                        : ''}
                      <Route path="/loading" element={<LoaderPage />} />
                      <Route path="/reset/:number/:token" element={<ResetPassword />} />

                    </Routes>
                    <Footer />
                  </GuestUserModalContextProvider>
                </LoginModalContextProvider>
              </InputValidationProvider>
            </Router>
          </VideoModalContextProvider>
        </CategoryProvider>
      </>
    </div>
  );
}

export default App;
