import React, { createContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import RenderLoader from "../../Components/RenderLoader";

import * as Yup from "yup";
import MobileField from "../CustomMobile";
import { forgotPinApi, forgotPasswordApi } from "../Action";
// formik
import { Formik, Form, Field, ErrorMessage } from "formik";
import { nannyLogin, employerLogin } from "../../Utils/ValidatianSchema";
import { postMethod, setAuthorizationToken } from "../../Utils/Service";
import toast from "react-hot-toast";
import { setCookies } from "../../Utils/cookies";

export const LoginModalContext = createContext();
export const LoginModalContextProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  // login modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  // forgot password modal
  const [showPassword, setShowPassword] = useState(false);
  const handleClosePassword = () => setShowPassword(false);
  const handleShowPassword = () => {
    setShowPassword(true);
    handleClose();
  };

  // forgot pin password modal
  const [showPinPassword, setShowPinPassword] = useState(false);
  const handleClosePinPassword = () => setShowPinPassword(false);
  const handleShowPinPassword = () => {
    setShowPinPassword(true);
    handleClose();
  };

  const handleBackToLogin = () => {
    handleClosePinPassword();
    handleClosePassword();
    handleShow();
  };
  const [activeTab, setActiveTab] = useState("nanny");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    handleClose();
    navigate("/register");
  };

  const handleSubmit = async (values) => {
    setLoader(true);
    console.log(values);

    const data = {
      login_name: values.loginName,
      login_pin: values.loginPin,
    };

    await postMethod("user/nannyLogin", data).then(async (response) => {
      if (response.status) {
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("user_details", JSON.stringify(response.data));
        sessionStorage.setItem("plan_subscribed", false);
        setCookies("_isJwtFront", true);
        setLoader(true);
        toast.success(response.message);
        setAuthorizationToken(true);
        // navigate("/");
        window.open("/", "_self");
      } else {
        toast.error(response.message);
        setLoader(false);
        navigate("/", "_self");
      }
    });
  };

  const handleEmployerSubmit = async (values) => {
    setLoader(true);
    console.log(values);

    const data = {
      email: values.email,
      password: values.password,
    };

    await postMethod("user/employerLogin", data).then(async (response) => {
      if (response.status) {
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("user_details", JSON.stringify(response.data));
        sessionStorage.setItem("plan_subscribed", false);
        setCookies("_isJwtFront", true);
        setLoader(true);
        toast.success(response.message);
        setAuthorizationToken(true);
        // navigate("/");
        window.open("/", "_self");
      } else {
        toast.error(response.message);
        setLoader(false);
        navigate("/", "_self");
      }
    });
  };

  const handleForgotPinSubmit = async (values) => {
    setLoader(true);
    if (values.mobile) {
      await forgotPinApi(values).then((response) => {
        if (response.status) {
          setLoader(false);
          toast.success(response.message);
          handleClosePinPassword();
          setShow(true);
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      });
    }
  };

  const handleForgotPasswordSubmit = async (values) => {
    setLoader(true);
    if (values.email) {
      await forgotPasswordApi(values).then((response) => {
        if (response.status) {
          setLoader(false);
          toast.success(response.message);
          handleClosePassword();
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      });
    }
  };
  useEffect(() => {}, []);
  return (
    <LoginModalContext.Provider
      value={{
        show,
        handleShow,
        handleClose,
        showPassword,
        handleShowPassword,
        handleClosePassword,
        showPinPassword,
        handleShowPinPassword,
        handleClosePinPassword,
      }}
    >
      {children}

      {/* Login && Register model */}
      {show && (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="heading_sec1">
                  <h3>Login</h3>
                  <p>
                    Login for nanny & employers users.
                  </p>
                </div>
                <div className="modal_content">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${
                              activeTab === "nanny" ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("nanny")}
                          >
                            Nanny
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${
                              activeTab === "employer" ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("employer")}
                          >
                            Employer
                          </Link>
                        </li>
                      </ul>

                      <div className="tab-content">
                        {/* ========Nanny Login================= */}
                        <div
                          className={`tab-pane ${
                            activeTab === "nanny" ? "active" : ""
                          }`}
                          id="nanny"
                        >
                          <div className="modal_tab_content">
                            <Formik
                              initialValues={{
                                loginName: "",
                                loginPin: "",
                              }}
                              validationSchema={nannyLogin}
                              onSubmit={handleSubmit}
                            >
                              {({ values, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <Field
                                        type="text"
                                        name="loginName"
                                        placeholder="User name"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                      <ErrorMessage
                                        name="loginName"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <Field
                                        type="password"
                                        name="loginPin"
                                        placeholder="User pin '123456'"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                      <ErrorMessage
                                        name="loginPin"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-center mb-0">
                                      <label className="mb-3">
                                        <Link
                                          to="#"
                                          onClick={handleShowPinPassword}
                                          autoComplete="off"
                                        >
                                          Forgot Pin?
                                        </Link>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mt-1 text-center">
                                      <button
                                        type="submit"
                                        className="btn btn-type1"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-center mb-0">
                                      <label className="mb-0">
                                        New User?{" "}
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            handleRegister(e);
                                          }}
                                        >
                                          Register
                                        </Link>
                                      </label>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>

                        {/* ========Employer Login================= */}
                        <div
                          className={`tab-pane ${
                            activeTab === "employer" ? "active" : ""
                          }`}
                          id="employer"
                        >
                          <div className="modal_tab_content">
                            <Formik
                              initialValues={{
                                email: "",
                                password: "",
                              }}
                              validationSchema={employerLogin}
                              onSubmit={handleEmployerSubmit}
                            >
                              {({ values, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <Field
                                        type="text"
                                        name="email"
                                        placeholder="Email Address"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                      <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <Field
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        className="form-control"
                                        autoComplete="off"
                                      />
                                      <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-center mb-0">
                                      <label className="mb-3">
                                        <Link
                                          to="#"
                                          onClick={handleShowPassword}
                                        >
                                          Forgot Password?
                                        </Link>
                                      </label>
                                    </div>
                                    <div></div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mt-1 text-center">
                                      <button
                                        type="submit"
                                        className="btn btn-type1"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group text-center mb-0">
                                      <label className="mb-0">
                                        New User?{" "}
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            handleRegister(e);
                                          }}
                                        >
                                          Register
                                        </Link>
                                      </label>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      {/* <!------forgot password modal-------------->*/}
      {showPassword && (
        <>
          <Modal show={showPassword} onHide={handleClosePassword}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="heading_sec1">
                  <h3>Forgot Password</h3>
                  <p>
                  <p>Enter Email To Get Request To Change Password</p>
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. */}
                  </p>
                </div>
                <div className="modal_content">
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Invalid email address")
                        .required("Email Address is required")
                        .max(50, "Text must be at most 50 characters"),
                    })}
                    onSubmit={handleForgotPasswordSubmit}
                  >
                    {({ values, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <Field
                                type="text"
                                name="email"
                                placeholder="Enter Email Address"
                                className="form-control"
                                autoComplete="off"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mt-1 text-center">
                              <button type="submit" className="btn btn-type1">
                                Submit
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group text-center mb-0">
                              <label className="mb-0">
                                <Link to="#" onClick={handleBackToLogin}>
                                  Back to Login
                                </Link>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      {/* <!------forgot pin  modal-------------->*/}
      {showPinPassword && (
        <>
          <Modal show={showPinPassword} onHide={handleClosePinPassword}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <div className="heading_sec1">
                  <h3>Forgot Pin</h3>
                  <p>Enter Mobile Number To Get Request To Change Password</p>
                </div>
                <div className="modal_content">
                  <Formik
                    initialValues={{
                      mobile: "",
                    }}
                    validationSchema={Yup.object().shape({
                      mobile: Yup.string().required(
                        "Mobile number is required"
                      ),
                    })}
                    onSubmit={handleForgotPinSubmit}
                  >
                    {({ values, handleSubmit, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <Field
                                component={MobileField}
                                name="mobile"
                                type="tel"
                                required="true"
                                value={values?.mobile}
                              />
                              <ErrorMessage
                                name="mobile"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mt-1 text-center">
                              <button
                                type="submit"
                                className="btn btn-type1"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group text-center mb-0">
                              <label className="mb-0">
                                <Link to="#" onClick={handleBackToLogin}>
                                  Back to Login
                                </Link>
                              </label>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </LoginModalContext.Provider>
  );
};
