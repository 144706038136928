const dateFormatConverter = (arg) => {
  let date = new Date(arg);
  let datel = date.getUTCDate();
  let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let returnDate = datel + ' ' + monthArray[(date.getUTCMonth())] + ' ' + date.getUTCFullYear()
  return returnDate;
}
function convertDateFomrat(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}

function slugify(string) {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

function convertDays(days) {
  const oneWeek = 7;
  const oneMonth = 30; // Average number of days in a month
  const oneYear = 365; // Average number of days in a year

  if (days >= oneYear) {
    const years = Math.floor(days / oneYear);
    return years + (years > 1 ? " years" : " year");
  } else if (days >= oneMonth) {
    const months = Math.floor(days / oneMonth);
    return months + (months > 1 ? " months" : " month");
  } else {
    const weeks = Math.floor(days / oneWeek);
    return weeks + (weeks > 1 ? " weeks" : " week");
  }
}
export { dateFormatConverter, convertDateFomrat, slugify, convertDays };