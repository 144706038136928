import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import basic from "../../assets/img/basic.svg";
import standard from "../../assets/img/standard.svg";
import premium from "../../assets/img/premium.svg";
import { getPlantypeApi } from '../Action';
import { convertDays } from '../../Utils/Logic';
import RenderLoader from "../../Components/RenderLoader";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function ServicePlanSection() {

    const [loader, setLoader] = useState(false);
    const [basicPlanType, setBasicPlanType] = useState({});
    const [standardPlanType, setStandardPlanType] = useState({});
    const [premiumPlanType, setPremiumPlanType] = useState({});

    const planTypeApi = async () => {

        let basicPlan = await planBySlug('basic');
        setBasicPlanType(basicPlan);

        let standardPlan = await planBySlug('standard');
        setStandardPlanType(standardPlan);

        let premiumPlan = await planBySlug('premium');
        setPremiumPlanType(premiumPlan);
    };

    const planBySlug = async (slug) => {
        setLoader(true);
        return await getPlantypeApi(slug).then(response => {
            if (response.status) {
                setLoader(false);
                return response.data;
            } else {
                setLoader(false)
            }
        })
    }

    useEffect(() => {
        planTypeApi();
    }, []);
    return (
        <div className="plan_sec_itms">
            <div className="row">

                {
                    loader ?
                        <>
                            <RenderLoader trans={true} />
                            <Skeleton count={5} height={30} highlightColor="#D3D3D3" enableAnimation="true" />
                        </>
                        :
                        <>
                            <div className="col-md-4" key={basicPlanType?._id}>
                                <div className="plan_sec_itm">
                                    <div className="plan_sec_head">
                                        <div className="plan_sec_ic">
                                            <img src={basic} />
                                        </div>
                                        <div className="plan_sec_heading">
                                            <h3>{basicPlanType?.name}<span>Plan</span></h3>
                                        </div>
                                        <div className="plan_sec_prc">
                                            <span className="plan_sec_prc_currency">AED</span> {basicPlanType?.price}
                                            <span className="plan_sec_prc_time">/ {basicPlanType?.duration + " " + basicPlanType?.duration_type}</span>
                                            {/* <span className="plan_sec_prc_time">/ {basicPlanType?.duration ? convertDays(basicPlanType?.duration) : ''}</span> */}
                                        </div>
                                        {/* <div className="plan_sec_offer">
                                            <span>Best Offer</span>
                                        </div> */}
                                    </div>
                                    <div className="plan_sec_con">
                                        <ul className="list_type1">
                                            {
                                                basicPlanType?.features?.map((item, index) =>
                                                    <li key={index}>{item?.value}</li>
                                                )}
                                            <li className={(basicPlanType?.consultant) ? "" : "text-decoration-line-through"}>
                                                Dedicated consultant*
                                            </li>
                                        </ul>
                                        <div className="plan_sec_btn">
                                            <Link to={"/payment/" + basicPlanType?._id} className="btn btn-type1" onClick={sessionStorage.removeItem('coupon')}>
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4" key={standardPlanType?._id}>
                                <div className="plan_sec_itm">
                                    <div className="plan_sec_head">
                                        <div className="plan_sec_ic">
                                            <img src={standard} />
                                        </div>
                                        <div className="plan_sec_heading">
                                            <h3>{standardPlanType?.name}<span>Plan</span></h3>
                                        </div>
                                        <div className="plan_sec_prc">
                                            <span className="plan_sec_prc_currency">AED</span> {standardPlanType?.price}
                                            <span className="plan_sec_prc_time">/ {standardPlanType?.duration + " " + standardPlanType?.duration_type}</span>
                                        </div>
                                        <div className="plan_sec_offer">
                                            <span>Most preferred</span>
                                        </div>
                                    </div>

                                    <div className="plan_sec_con">
                                        <ul className="list_type1">
                                            {
                                                standardPlanType?.features?.map((item, index) =>
                                                    <li key={index}>{item?.value}</li>
                                                )}
                                            <li className={standardPlanType?.consultant ? "" : "text-decoration-line-through"}>
                                                Dedicated consultant*
                                            </li>
                                        </ul>
                                        <div className="plan_sec_btn">
                                            <Link to={"/payment/" + standardPlanType?._id} className="btn btn-type1" onClick={sessionStorage.removeItem('coupon')}>
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4" key={premiumPlanType?._id}>
                                {/* <div className="plan_sec_itm activated"> */}
                                <div className="plan_sec_itm">
                                    <div className="plan_sec_head">
                                        <div className="plan_sec_ic">
                                            <img src={premium} />
                                        </div>
                                        <div className="plan_sec_heading">
                                            <h3>{premiumPlanType?.name}<span>Plan</span></h3>
                                        </div>
                                        <div className="plan_sec_prc">
                                            <span className="plan_sec_prc_currency">AED</span> {premiumPlanType?.price}
                                            <span className="plan_sec_prc_time">/ {premiumPlanType?.duration + " " + premiumPlanType?.duration_type}</span>
                                            {/* <span className="plan_sec_prc_time">/ {premiumPlanType?.duration ? convertDays(premiumPlanType?.duration) : ''}</span> */}
                                        </div>
                                        {/* <div className="plan_sec_offer">
                                            <span>Best Offer</span>
                                        </div> */}
                                    </div>

                                    <div className="plan_sec_con">
                                        <ul className="list_type1">
                                            {
                                                premiumPlanType?.features?.map((item, index) =>
                                                    <li key={index}>{item?.value}</li>
                                                )}
                                            <li className={premiumPlanType?.consultant ? "" : "text-decoration-line-through"}>
                                                Dedicated consultant*
                                            </li>
                                        </ul>
                                        <div className="plan_sec_btn">
                                            <Link to={"/payment/" + premiumPlanType?._id} className="btn btn-type1" onClick={sessionStorage.removeItem('coupon')}>
                                                Get Started
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default ServicePlanSection
