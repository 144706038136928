import React, { createContext } from 'react';

const InputValidationContext = createContext();

const InputValidationProvider = ({ children }) => {
    const handleAlphabetKeyPress = (e) => {
        if (
            e.which !== 8 &&
            e.which !== 9 &&
            e.which !== 0 &&
            e.which !== 32 &&
            (e.which < 65 || e.which > 90) &&
            (e.which < 97 || e.which > 122) &&
            e.which !== 46
        ) {
            e.preventDefault();
        }
    };

    const handleNumberKeyPress = (e) => {
        if (e.which !== 8 && e.which !== 0 && e.which !== 0 && e.which !== 9 && e.which !== 45 && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    };

    const handleAlphaNumKeyPress = (e) => {
        if (
            e.which !== 8 &&
            e.which !== 9 &&
            e.which !== 0 &&
            e.which !== 32 &&
            (e.which < 65 || e.which > 90) &&
            (e.which < 97 || e.which > 122) &&
            (e.which < 48 || e.which > 57)
        ) {
            e.preventDefault();
        }
    };

    const value = {
        handleAlphabetKeyPress,
        handleNumberKeyPress,
        handleAlphaNumKeyPress,
    };

    return (
        <InputValidationContext.Provider value={value}>
            {children}
        </InputValidationContext.Provider>
    );
};

export { InputValidationContext, InputValidationProvider };
