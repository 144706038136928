import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getServiceApi, getLatestBlogsApi } from '../Action';
import RenderLoader from "../../Components/RenderLoader";


function GuideToFindNanny() {

    const [loader, setLoader] = useState(false);
    const [guideText, setGuideText] = useState({});
    const [blogsData, setBlogsData] = useState([]);

    const serviceApi = async () => {
        await getServiceApi('find-nanny-step').then(response => {
            if (response.status) {
                setGuideText(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };

    const getLatestBlog = async () => {
        await getLatestBlogsApi().then(response => {
            if (response.status) {
                setBlogsData(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };

    useEffect(() => {
        serviceApi();
        getLatestBlog();
    }, []);
    return (
        <section className="home_blog bg_type1">
            <div className="container">
                <div className="home_blog_in">
                    <div className="heading_sec1">
                        <h3>{guideText?.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: guideText?.description }}></p>
                    </div>
                    {
                        loader ?
                            <RenderLoader trans={true} />
                            : null
                    }
                    <div className="row">
                        {blogsData?.length ?
                            blogsData?.map((item, index) =>
                                index == 0 ?
                                    <div className="col-md-6" key={index}>
                                        <div className="home_blog_itm" key={item?._id}>
                                            <div className="home_blog_itm_img">
                                                <img src={process.env.REACT_APP_URL + "/" + item?.image} />
                                            </div>
                                            <div className="home_blog_itm_con">
                                                <h4>{item?.title}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: item?.description ? item?.description.substring(0, 400) : '' }}></p>
                                                <Link to={"/blogdetails/" + item?._id} className="more_btn">
                                                    View More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            ) : null}

                        <div className="col-md-6">
                            {blogsData?.length ?
                                blogsData?.map((item, index) =>
                                    index != 0 ?
                                        <div className="home_blog_itm" key={item?._id}>
                                            <div className="home_blog_itm_img">
                                                <img src={process.env.REACT_APP_URL + "/" + item?.image} />
                                            </div>
                                            <div className="home_blog_itm_con">
                                                <h4>{item?.title}</h4>
                                                <p dangerouslySetInnerHTML={{ __html: item?.description ? item?.description.substring(0, 400) : '' }}></p>
                                                <Link to={"/blogdetails/" + item?._id} className="more_btn">
                                                    View More
                                                </Link>
                                            </div>
                                        </div>
                                        :
                                        ""
                                ) : ""}
                        </div>

                    </div>
                </div>
            </div>
        </section >
    );
}

export default GuideToFindNanny;
