import React from 'react';
import { Link } from 'react-router-dom';
import Call_red from "../../assets/img/call_red.svg";
import Wts_green from "../../assets/img/wts_green.svg";
import Email_blue from "../../assets/img/email_blue.svg";

const ContactUsSection = () => {
    return (
        <div className="col-lg-6">
            <div className="contact_info">
                <div className="contact_info_in">
                    <div className="heading_sec2">
                        <h3>Contact Info</h3>
                    </div>
                    <div className="contact_info_itms">
                        <Link to={`tel:${"521297955"}`} className="contact_info_itm">
                            <div className="contact_info_itm_ic">
                                <img src={Call_red} alt="" />
                            </div>
                            <div className="contact_info_itm_con">
                                <span>Contact Number</span>
                                <p>+971 - 521297955</p>
                            </div>
                        </Link>
                        <Link to={`https://wa.me/${"521297955"}`} className="contact_info_itm" target='_blank'>
                            <div className="contact_info_itm_ic">
                                <img src={Wts_green} alt="" />
                            </div>
                            <div className="contact_info_itm_con">
                                <span>WhatsApp Number</span>
                                <p>+971 - 521297955</p>
                            </div>
                        </Link>
                        <Link to={`mailto:${"team@maidsandnanny.com"}`} className="contact_info_itm">
                            <div className="contact_info_itm_ic">
                                <img src={Email_blue} alt="" />
                            </div>
                            <div className="contact_info_itm_con">
                                <span>Email Address</span>
                                <p>team@maidsandnanny.com</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsSection
