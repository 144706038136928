import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getServiceApi,getTotalNanniesApi } from './Action';
import About_us from "../assets/img/about_us.png";
import create_account from "../assets/img/create_account.svg";
import search_jobs from "../assets/img/search_jobs.svg";
import save from "../assets/img/save.svg";
import TrustedBy from "./Home/TrustedBy";
import HowItWorks from "./Home/HowItWorks";
import ContactUs from "./ContactUs";
import RenderLoader from "../Components/RenderLoader";

const AboutUs = () => {

  const [loader, setLoader] = useState(false);
  const [aboutUsText, setAboutUsText] = useState({});
  const [workHowText, setWorkHowText] = useState({});
  const [totalNannis, setTotalNannis] = useState(0);

  const serviceApi = async () => {
    await getServiceApi('about-us').then(response => {
      if (response.status) {
        setAboutUsText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })

    await getServiceApi('works-how').then(response => {
      if (response.status) {
        setWorkHowText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
    
    await getTotalNanniesApi().then(response => {
      if (response.status) {
        setTotalNannis(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };


  useEffect(() => {
    window.document.title="About us : Maids & Nanny";
    window.scroll(0, 0);
    serviceApi();
  }, []);
  return (
    <>
      <div className="main_sec">

        {
          loader ?
            <RenderLoader trans={true} />
            : null
        }
        <section className="about_sec">
          <div className="container">
            <div className="about_sec_in">
              <div className="row">
                <div className="col-md-6">
                  <div className="about_sec_img">
                    <img src={About_us} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about_sec_con">
                    <div className="heading_sec2">
                      <h3>{aboutUsText?.title}</h3>
                    </div>
                    <div className="about_sec_para">
                      <p dangerouslySetInnerHTML={{ __html: aboutUsText?.description }}></p>
                      <div className="chat_sec_head">
                        <span>{totalNannis}+</span>Available Profiles
                      </div>
                      <div className="btn_sec">
                        <Link to="/registerforjob" className="btn btn-type1">
                          Register as a Nanny
                        </Link>
                        <Link
                          to="/searchnannies"
                          className="btn btn-type1 ms-3"
                        >
                          View Profiles
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <TrustedBy />

        {/* //---how its work----// */}
        <section section className="process_sec1" >
          <div className="container">
            <div className="process_sec1_in">
              <div className="heading_sec1">
                <h3>{workHowText?.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: workHowText?.description }}></p>
              </div>
              <div className="process_sec1_itms">
                <div className="row">
                  <div className="col-md-4">
                    <div className="process_sec1_itm">
                      <div className="process_sec1_itm_ic">
                        <img src={create_account} alt="" />
                      </div>
                      <div className="process_sec1_itm_con">
                        <h4>Create an Account</h4>
                        <p>
                          Fill in your details and create your account on the portal.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="process_sec1_itm">
                      <div className="process_sec1_itm_ic">
                        <img src={search_jobs} alt="" />
                      </div>
                      <div className="process_sec1_itm_con">
                        <h4>Browse profiles</h4>
                        <p>
                          Start looking for relevant house help in the UAE and find your perfect fit. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="process_sec1_itm">
                      <div className="process_sec1_itm_ic">
                        <img src={save} alt="" />
                      </div>
                      <div className="process_sec1_itm_con">
                        <h4>Contact</h4>
                        <p>
                        Subscribe to our plans, access the entire database and contact your perfect house help.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >

        {/* <HowItWorks /> */}
        <ContactUs aboutus="hide"/>
      </div >
    </>
  );
};

export default AboutUs;
