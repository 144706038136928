import React, { useState, useEffect, useRef } from "react";
import Upload from "../assets/img/upload.svg";
import Calender1 from "../assets/img/calender1.svg";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { editNannyProfile, otpValidation } from "../Utils/ValidatianSchema"
import { verfyOtpApi, getCountryApi, getCityApi, getStateApi, editUserProfileApi, getCategoryApi, getEditUser, getNationalityApi, getReligionApi, getExperienceWithKidApi, getLangaugeApi, getSkillApi, getPositionApi,getVisaOptionListApi } from './Action';
import Select from 'react-select';
import moment from 'moment';
import SelectField from "./CustomSelect";
import MobileField from "./CustomMobile";
import TextBoxField from "./CustomTextBox";
import RenderLoader from "../Components/RenderLoader";
import toast from 'react-hot-toast';
import { NannySidebar } from "../Pages/LoginSidebar";
import { postMethod } from '../Utils/Service';

const NannyEdit = () => {
  const history = useNavigate();
  const [loader, setLoader] = useState(true);
  const [inputValues, setInputValues] = useState({});
  const [visaOptionList, setVisaOptionList] = useState([]);


  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [religionList, setReligionList] = useState([]);

  const [experienceOption, setExperienceOption] = useState([]);
  const [languageOption, setLanguageOption] = useState([]);

  const [skillsOption, setSkillsOption] = useState([]);
  const [skillsSelect, setSkillsSelect] = useState([]);

  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [jobTypeSelect, setJobTypeSelect] = useState([]);

  const [liveStatusOption, setLiveStatusOption] = useState([]);
  const [liveStatusSelect, setLiveStatusSelect] = useState([]);

  const [locationOption, setLocationOption] = useState([]);
  const [locationSelect, setLocationSelect] = useState([]);

  const handleCountryChange = async (country) => {
    if (country != "") {
      await getStateApi(country).then(response => {
        if (response.status) {
          setStateList(response.data);
          setCityList([]);
          setLocationOption([]);
          setLoader(false);
        } else {
          setLoader(false)
        }
      })
    } else {
      setStateList([]);
      setCityList([]);
      setLocationOption([]);
    }
  }

  const handleStateChange = async (state) => {
    if (state != "") {
      await getCityApi(state).then(async response => {
        if (response.status) {
          setCityList(response.data);
          setLocationOption([]);
          const arrLocation = await makeReactSelect(response?.data);
          setLocationOption(arrLocation);
          setLoader(false);
        } else {
          setLoader(false)
        }
      })
    } else {
      setCityList([]);
      setLocationOption([]);
    }
  }

  // // for  modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // for email modal
  const [showEmail, setShowEmail] = useState(false);
  const handleCloseEmail = () => setShowEmail(false);

  const handleVerifyMobile = async (values) => {
    setLoader(true);
    // console.log("***mobile otp verify***", values);
    await verfyOtpApi(values).then(response => {
      if (response.status) {
        toast.success(response.message);
        setShowEmail(true);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
  }

  const handleVerifyEmail = async (values) => {
    setLoader(true);
    // console.log("***email otp verify***", values);
    await verfyOtpApi(values).then(response => {
      if (response.status) {
        toast.success(response.message);
        setShowEmail(false);
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
  };


  const handleSubmitForm = async (values, { resetForm }) => {
    setLoader(true);

    values.job_category = values?.job_array?.map((item) => { return item?.value });
    values.skills = values?.skill_array?.map((item) => { return item?.value });
    values.live_status = values?.live_status_array?.map((item) => { return item?.value });
    values.preferred_location = (values?.location_array)? values?.location_array?.map((item) => { return item?.value }):[];

    inputValues?.profile_image ? values.profile_image = inputValues?.profile_image : '';
    inputValues?.video ? values.video = inputValues?.video : '';
    inputValues?.passport ? values.passport = inputValues?.passport : '';
    inputValues?.visa ? values.visa = inputValues?.visa : '';

    // console.log(values);
    setLoader(true);
    await editUserProfileApi(values).then(response => {
      if (response.status) {
        toast.success(response.message);
        resetForm();
        history('/nannypro');
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
  };

  const fetchData = async () => {
    setLoader(true);
    await getEditUser().then(async response => {
      if (response.status) {
        setLoader(false);
        setInputValues(response.data);
        //--skills edit--
        const skillsSelectEdit = await makeReactSelect(response.data?.skills);
        setSkillsSelect(skillsSelectEdit);
        //--job_category edit--
        // const jobTypeSelectEdit = await response.data?.job_category[0]?._id;
        // setJobTypeSelect(jobTypeSelectEdit);
        const jobTypeSelectEdit = await makeReactSelect(response.data?.job_category);
        setJobTypeSelect(jobTypeSelectEdit);

        //--country state city---//
        handleCountryChange(response.data?.country);
        handleStateChange(response.data?.state);
        //--live status--
        const liveSelectEdit = await makeArrayReactSelect(response.data?.live_status);
        setLiveStatusSelect(liveSelectEdit);
       
        //--preferred location--
        const locationSelectEdit = await makeReactSelect((response.data?.preferred_location)?response.data?.preferred_location:[]);
        setLocationSelect(locationSelectEdit);

      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })

    //------fetching country list------//
    await getCountryApi().then((response) => {
      if (response?.status) {
        setCountryList(response.data);
      }
    })


    //------fetching Nationality list------//
    await getNationalityApi().then((response) => {
      if (response?.status) {
        setNationalityList(response.data);
      }
    })

    //------fetching Religion list------//
    await getReligionApi().then((response) => {
      if (response?.status) {
        setReligionList(response.data);
      }
    })

    await getExperienceWithKidApi().then(async (response) => {
      if (response?.status) {
        setExperienceOption(response?.data);
      }
    })
    await getLangaugeApi().then(async (response) => {
      if (response?.status) {
        setLanguageOption(response?.data);
      }
    })
    await getSkillApi().then(async (response) => {
      if (response?.status) {
        const arrSkill = await makeReactSelect(response?.data);
        setSkillsOption(arrSkill);
      }
    })

    await getPositionApi().then(async (response) => {
      if (response?.status) {
        const arrLive = await makeArrayReactSelect(response?.data);
        setLiveStatusOption(arrLive);
      }
    })

    //------visa option list------//
    await getVisaOptionListApi().then(async (response) => {
      if (response?.status) {
        const liveArr = await makeArrayReactSelect(response?.data);
        setVisaOptionList(liveArr);
      }
    })

    await getCategoryApi().then(async (response) => {
      if (response?.status) {
        // setJobTypeOption(response?.data);
        const arrJob = await makeReactSelect(response?.data);
        setJobTypeOption(arrJob);
      }
    })
  }

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, value: item?._id, key: index };
      arr.push(obj);
    })
    return arr;
  }

  const makeArrayReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item, key: index, value: item };
      arr.push(obj);
    })
    return arr;
  }

  useEffect(() => {
    window.document.title="Edit profile : Maids & Nanny";
    fetchData();
  }, [])
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">

            <NannySidebar />
            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }

            <div className="profile_content">
              <div className="profile_form">
                <h4>Edit Profile</h4>
                <Formik
                  initialValues={{
                    role: 2,
                    first_name: inputValues?.first_name,
                    last_name: inputValues?.last_name,
                    job_array: jobTypeSelect,
                    country: inputValues?.country,
                    state: inputValues?.state,
                    city: inputValues?.city,
                    mobile: inputValues?.mobile,
                    whatsAppNumber: inputValues?.whatsAppNumber,
                    login_name: inputValues?.login_name,

                    // profile_image_formik: inputValues?.profile_image,
                    // video_formik: inputValues?.video,
                    // passport_formik: inputValues?.passport,
                    // visa_formik: inputValues?.visa,

                    nationality: inputValues?.nationality,
                    religion: inputValues?.religion,
                    gender: inputValues?.gender,
                    marital_status: inputValues?.marital_status,
                    dob: moment(inputValues?.dob).format('YYYY-MM-DD'),
                    visa_status: inputValues?.visa_status,
                    number_of_kids: inputValues?.number_of_kids,
                    total_experience: inputValues?.total_experience,
                    experience_with_kids: inputValues?.experience_with_kids,
                    language: inputValues?.language,
                    specific_language: inputValues?.specific_language,
                    skill_array: skillsSelect,
                    bio: inputValues?.bio,
                    live_status_array: liveStatusSelect,
                    expected_salary: inputValues?.expected_salary,
                    available_from: (inputValues?.available_from) ? moment(inputValues?.available_from).format('YYYY-MM-DD') : '',
                    // preferred_location: inputValues?.preferred_location
                    location_array: locationSelect,

                  }}
                  validationSchema={editNannyProfile}
                  onSubmit={handleSubmitForm}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>


                      <div className="row">

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>First Name <red>*</red></label>
                            <Field
                              type="text"
                              name="first_name"
                              placeholder="Enter First Name"
                              className="form-control"
                              autoComplete="off"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Last Name <red>*</red></label>
                            <Field
                              type="text"
                              name="last_name"
                              placeholder="Enter Last Name"
                              className="form-control"
                              autoComplete="off"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* <div className="col-xl-12">
                          <div className="form-group">
                            <label htmlFor="job_category">Job Type</label>
                            <Field
                              as="select"
                              name="job_category"
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value="">Select job type</option>
                              {
                                jobTypeOption?.length ?
                                  jobTypeOption?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                  : null
                              }
                            </Field>
                            <ErrorMessage
                              name="job_category"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div> */}

                        <div className="col-xl-12">
                          <div className="form-group">
                            <label htmlFor="job_array">Job Type <red>*</red></label>
                            <Field
                              component={SelectField}
                              name="job_array"
                              options={jobTypeOption}
                            />
                            <ErrorMessage
                              name="job_array"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label htmlFor="country">Country <red>*</red></label>
                            <Field
                              as="select"
                              name="country"
                              className="form-control"
                              onChange={(e) => { handleChange(e), handleCountryChange(e.target.value), setFieldValue('state', ''), setFieldValue('city', ''),setFieldValue('location_array', '') }}
                            >
                              <option value="">Select an country</option>
                              {
                                countryList?.length ?
                                  countryList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                  : null
                              }
                            </Field>
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label htmlFor="state">States / Emirates <red>*</red></label>
                            <Field
                              as="select"
                              name="state"
                              className="form-control"
                              onChange={(e) => { handleChange(e), handleStateChange(e.target.value), setFieldValue('city', ''),setFieldValue('location_array', '') }}
                            >
                              <option value="">Select an state</option>
                              {
                                stateList?.length ?
                                  stateList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                  : null
                              }
                            </Field>
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label htmlFor="city">City <red>*</red></label>
                            <Field
                              as="select"
                              name="city"
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value="">Select city</option>
                              {
                                cityList?.length ?
                                  cityList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                  : null
                              }
                            </Field>
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>


                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Mobile Number <red>*</red></label>

                            <Field
                              component={MobileField}
                              name="mobile"
                              type="tel"
                              required="true"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="mobile"
                              component="div"
                              className="error"
                            />

                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>WhatsApp Number <red>*</red></label>
                            <Field
                              component={MobileField}
                              name="whatsAppNumber"
                              type="tel"
                              required="true"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="whatsAppNumber"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Create Login Name <red>*</red></label>
                            <Field
                              type="text"
                              name="login_name"
                              placeholder="Set a login name"
                              className="form-control"
                              autoComplete="off"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="login_name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>


                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Upload Your Profile Picture</label>
                            <div className="">

                              {inputValues.profile_image ?
                                <img
                                  style={{ maxWidth: "150px", maxHeight: "150px" }}
                                  src={
                                    process.env.REACT_APP_IMG_URL +
                                    '/' + inputValues.profile_image
                                  }
                                  className="img-fluid"
                                />
                                : ''}
                              <Field
                                type="file"
                                name="profile_image_formik"
                                className="form-control"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={async (event) => {
                                  try {
                                    handleChange(event);
                                    setInputValues({ ...inputValues, ['profile_image']: "" });
                                    var file = event.target.files[0]
                                    var formData = new FormData()
                                    formData.append('image', file, file.name)
                                    setLoader(true)

                                    await postMethod(
                                      'uploadImage',
                                      formData,
                                    ).then((response) => {
                                      if (response?.status) {
                                        // setFieldValue("profile_image", response?.file);
                                        setInputValues({ ...inputValues, ['profile_image']: response?.file })
                                        setLoader(false)
                                      } else {
                                        toast.error(response.message)
                                        setLoader(false)
                                      }
                                    })
                                  } catch (e) {
                                    console.log("error", e.message);
                                  }
                                }}

                              />

                              <ErrorMessage
                                name="profile_image_formik"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Upload Your Profile Video</label>
                            <div className="">

                              {inputValues.video ?
                                <Link to={process.env.REACT_APP_IMG_URL +
                                  '/' + inputValues.video} download={true} target="_blank">Download</Link>
                                : ''}
                              <Field
                                type="file"
                                name="video_formik"
                                className="form-control"

                                accept="video/*"
                                onChange={async (event) => {
                                  try {
                                    handleChange(event);
                                    setInputValues({ ...inputValues, ['video']: "" });
                                    var file = event.target.files[0]
                                    var formData = new FormData()
                                    formData.append('image', file, file.name)
                                    setLoader(true)

                                    await postMethod(
                                      'uploadImage',
                                      formData,
                                    ).then((response) => {
                                      if (response?.status) {
                                        // setFieldValue("video", response?.file);
                                        setInputValues({ ...inputValues, ['video']: response?.file })
                                        setLoader(false)
                                      } else {
                                        toast.error(response.message)
                                        setLoader(false)
                                      }
                                    })
                                  } catch (e) {
                                    console.log("error", e.message);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="video_formik"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Upload Your Passport</label>
                            <div className="">

                              {inputValues.passport ?
                                <img
                                  style={{ maxWidth: "150px", maxHeight: "150px" }}
                                  src={
                                    process.env.REACT_APP_IMG_URL +
                                    '/' + inputValues.passport
                                  }
                                  className="img-fluid"
                                />
                                : ''}
                              <Field
                                type="file"
                                name="passport_formik"
                                className="form-control"

                                accept="image/png, image/gif, image/jpeg"
                                onChange={async (event) => {
                                  try {
                                    handleChange(event);
                                    setInputValues({ ...inputValues, ['passport']: "" });
                                    var file = event.target.files[0]
                                    var formData = new FormData()
                                    formData.append('image', file, file.name)
                                    setLoader(true)

                                    await postMethod(
                                      'uploadImage',
                                      formData,
                                    ).then((response) => {
                                      if (response?.status) {
                                        // setFieldValue("passport", response?.file);
                                        setInputValues({ ...inputValues, ['passport']: response?.file })
                                        setLoader(false)
                                      } else {
                                        toast.error(response.message)
                                        setLoader(false)
                                      }
                                    })
                                  } catch (e) {
                                    console.log("error", e.message);
                                  }
                                }}
                              />
                              {/* <div className="file_type_btn form-control">
                      <i className="file_type_ic">
                        <img src={Upload} />
                      </i>
                      <span>Upload Your Passport</span>
                    </div> */}
                              <ErrorMessage
                                name="passport_formik"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Upload Your Visa</label>
                            <div className="">

                              {inputValues.visa ?
                                <img
                                  style={{ maxWidth: "150px", maxHeight: "150px" }}
                                  src={
                                    process.env.REACT_APP_IMG_URL +
                                    '/' + inputValues.visa
                                  }
                                  className="img-fluid"
                                />
                                : ''}
                              <Field
                                type="file"
                                name="visa_formik"
                                className="form-control"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={async (event) => {
                                  try {
                                    handleChange(event);
                                    setInputValues({ ...inputValues, ['visa']: "" });
                                    var file = event.target.files[0]
                                    var formData = new FormData()
                                    formData.append('image', file, file.name)
                                    setLoader(true)
                                    await postMethod(
                                      'uploadImage',
                                      formData,
                                    ).then((response) => {
                                      if (response?.status) {
                                        // setFieldValue("visa", response?.file);
                                        setInputValues({ ...inputValues, ['visa']: response?.file })
                                        setLoader(false)
                                      } else {
                                        toast.error(response.message)
                                        setLoader(false)
                                      }
                                    })
                                  } catch (e) {
                                    console.log("error", e.message);
                                  }
                                }}
                              />
                              {/* <div className="file_type_btn form-control">
                      <i className="file_type_ic">
                        <img src={Upload} />
                      </i>
                      <span>Upload Your Visa</span>
                    </div> */}
                              <ErrorMessage
                                name="visa_formik"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Nationality */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Nationality <red>*</red></label>
                            <Field as="select" name="nationality" className="form-control">
                              <option value="">Select Nationality</option>
                              {nationalityList?.length > 0 ?
                                nationalityList?.map((item, index) =>
                                  <option key={index} value={item?._id}>{item?.name}</option>
                                )
                                : null}
                            </Field>
                            <ErrorMessage
                              name="nationality"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>


                        {/* Religion */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Religion <red>*</red></label>
                            <Field as="select" name="religion" className="form-control">
                              <option value="">Select Religion</option>
                              {religionList?.length > 0 ?
                                religionList?.map((item, index) =>
                                  <option key={index} value={item?._id}>{item?.name}</option>
                                )
                                : null}
                            </Field>
                            <ErrorMessage
                              name="religion"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        {/* Gender */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Gender <red>*</red></label>
                            <div className="custom_radio_itms">
                              <label className="custom_radio">
                                Male
                                <Field type="radio" name="gender" value="male" />
                                <span className="checkmark"></span>
                              </label>
                              <label className="custom_radio">
                                Female
                                <Field
                                  type="radio"
                                  name="gender"
                                  value="female"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="gender"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* Marital Status */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Marital Status <red>*</red></label>
                            <div className="custom_radio_itms">
                              <label className="custom_radio">
                                Married
                                <Field
                                  type="radio"
                                  name="marital_status"
                                  value="married"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label className="custom_radio">
                                UnMarried
                                <Field
                                  type="radio"
                                  name="marital_status"
                                  value="unmarried"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <ErrorMessage
                              name="marital_status"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* Age */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Age <red>*</red></label>
                            <div className="input-group date" id="datepicker">
                              <Field
                                type="date"
                                name="dob"
                                max={moment().format('YYYY-MM-DD')}
                                className="form-control"
                                placeholder="Select Age"
                              />

                            </div>
                            <ErrorMessage
                              name="dob"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* Visa Status */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Visa Status <red>*</red></label>
                            {/* <Field
                              type="text"
                              name="visa_status"
                              className="form-control"
                              placeholder="Enter visa status"
                              autoComplete="off"
                            /> */}
                            <Field as="select" name="visa_status" className="form-control">
                                <option value="">Select an option</option>
                                {visaOptionList?.length > 0 ?
                                  visaOptionList?.map((item, index) =>
                                    <option key={index} value={item.value}>{item?.value}</option>
                                  )
                                  : null}
                            </Field>
                            <ErrorMessage
                              name="visa_status"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* How Many Kids Do You Have */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>How Many Kids Do You Have <red>*</red></label>
                            <Field
                              type="text"
                              name="number_of_kids"
                              className="form-control"
                              placeholder="Enter no of kids"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="number_of_kids"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>


                        {/* Years of experience as a nanny */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Years of experience as a nanny <red>*</red></label>
                            <Field
                              type="number"
                              name="total_experience"
                              placeholder="Enter total experience"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="total_experience"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* Experience with Kids */}
                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Experience with Kids <red>*</red></label>

                            <div className="custom_checkbox_itms">
                              {experienceOption?.length > 0 ?
                                experienceOption?.map((item, index) =>
                                  <label className="custom_checkbox" key={index}>
                                    {item?.name}
                                    <Field
                                      type="checkbox"
                                      name="experience_with_kids"
                                      value={item?._id}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                )
                                : ''}
                            </div>
                            <ErrorMessage
                              name="experience_with_kids"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* languages */}
                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Languages <red>*</red></label>
                            <div className="custom_checkbox_itms">

                              {languageOption?.length > 0 ?
                                languageOption?.map((item, index) =>
                                  <label className="custom_checkbox" key={index}>
                                    {item?.name}
                                    <Field
                                      type="checkbox"
                                      name="language"
                                      value={item?._id}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                )
                                : ''
                              }
                            </div>
                            <ErrorMessage
                              name="language"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* Specific Language */}
                        <div className="col-xl-12">
                          <div className="form-group">
                            <label>Specific Language</label>
                            <Field
                              type="text"
                              name="specific_language"
                              placeholder="Enter Language"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="specific_language"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              BIO
                            </label>
                            {/* <Field
                              as="textarea"
                              name="bio"
                              placeholder="Write other requirements"
                              className="form-control"
                              autoComplete="off"
                              rows="4"
                            /> */}
                            <Field
                              component={TextBoxField}
                              name="bio"
                            />
                            <ErrorMessage
                              name="bio"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-12">
                          <div className="form-group">
                            <label htmlFor="skill_array">Skills <red>*</red></label>
                            <Field
                              component={SelectField}
                              name="skill_array"
                              options={skillsOption}
                            />
                            <ErrorMessage
                              name="skill_array"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* live status */}
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Type of employment<red>*</red></label>
                            <Field
                              component={SelectField}
                              name="live_status_array"
                              options={liveStatusOption}
                            />
                            <ErrorMessage
                              name="live_status_array"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Expected Salary <red>*</red>
                            </label>
                            <Field
                              type="text"
                              name="expected_salary"
                              placeholder="Enter your expected salary like 1200-1500"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="expected_salary"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Available From
                            </label>
                            <Field
                              type="date"
                              min={moment().format('YYYY-MM-DD')}
                              name="available_from"
                              placeholder="Available from"
                              className="form-control"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="available_from"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Preferred Location
                            </label>
                            {/* <Field
                              type="text"
                              name="preferred_location"
                              placeholder="Enter location from"
                              className="form-control"
                              autoComplete="off"
                            /> */}
                            <Field
                              component={SelectField}
                              name="location_array"
                              options={locationOption}
                            />
                            <ErrorMessage
                              name="location_array"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        <div className="col-xl-12">
                          <div className="form-group mt-1">
                            <button type="submit" className="btn btn-type1" >
                              Continue to Register
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default NannyEdit;
