import React, { useState, useEffect } from "react";
import call_ic from "../assets/img/call_ic.svg";
import wts_ic from "../assets/img/wts_ic.svg";
import email_ic from "../assets/img/email_ic.svg";
import { Link } from "react-router-dom";
import { getUserDetails, updateNannyStatus } from '../Pages/Action';
import RenderLoader from "../Components/RenderLoader";
import { NannySidebar } from "../Pages/LoginSidebar";

const Nannypro = () => {
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState({});
  const [statusUpdate, setStatusUpdate] = useState({});

  const getDetails = async () => {
    setLoader(true);
    await getUserDetails().then(response => {
      if (response.status) {
        setDetails(response.data);
        let status = { hide: (response?.data) ? response?.data?.hide : '', job_status: (response?.data) ? response.data?.job_status : '' };
        setStatusUpdate(status);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  }

  const handleStatus = async (profile) => {
    setLoader(true);
    let data = { type: profile };
    await updateNannyStatus(data).then(response => {
      if (response.status) {
        setStatusUpdate(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    window.document.title="User Profile : Maids & Nanny";
    getDetails();
  }, []);
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">
            <NannySidebar />
            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }

            <div className="profile_content">
              <div className="profile_user">
                <div className="profile_user_img">
                  <img src={process.env.REACT_APP_URL + "/" + details?.profile_image} alt="" />
                </div>
                {statusUpdate?.job_status &&
                  <div className="open_to_work_tag">Open to Work</div>
                }
                <div className="profile_user_con">
                  <h5>{details?.first_name + " " + details?.last_name}</h5>
                  <p>
                    Location: <b>
                      {
                        details?.country ? details?.city?.name + " " + details?.country?.name : ''
                      }
                    </b>
                  </p>
                  <div className="hide_show">
                    <div className="hide_show_toggle">
                      <span>Hired Profile:</span>
                      <label className="switch">
                        <input type="checkbox" checked={statusUpdate?.hide} onChange={() => handleStatus('profile')} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="hide_show_toggle">
                      <span>Open to Work:</span>
                      <label className="switch">
                        <input type="checkbox" checked={statusUpdate?.job_status} onChange={() => handleStatus('job_status')} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <Link to="/nannyEdit" className="btn btn-type1">
                    Edit Profile
                  </Link>
                </div>
              </div>
              <div className="profile_info">
                <div className="profile_info_head">
                  <div className="profile_info_itms">
                    <div className="profile_info_itms_in">
                      <div className="row">
                        <div className="col-6">
                          <div className="profile_info_itm">
                            <Link to="#" className="profile_info_itm_in" onClick={(e)=>e.preventDefault()}>
                              <i>
                                <img src={call_ic} />
                              </i>
                              <div className="contactdetail_sec_itm_cn">
                                <span>Contact Number</span>
                                <p>{details?.mobile}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="profile_info_itm">
                            <Link to="/#" className="profile_info_itm_in" onClick={(e)=>e.preventDefault()}>
                              <i>
                                <img src={wts_ic} />
                              </i>
                              <div className="contactdetail_sec_itm_cn">
                                <span>WhatsApp Number</span>
                                <p>{details?.whatsAppNumber}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="profile_info_itm">
                            <Link to="#" className="profile_info_itm_in" onClick={(e)=>e.preventDefault()}>
                              <i>
                                <img src={email_ic} />
                              </i>
                              <div className="contactdetail_sec_itm_cn">
                                <span>Login Name</span>
                                <p>{details?.login_name}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile_info_con">
                      <div className="profile_info_itms">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>Position :</span>
                                  {details.live_status?.map((item, index) => <p key={index}>{item}</p>)}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>Location :</span>
                                  <p>{
                                    details?.country ? details?.city?.name + " " + details?.country?.name : ''
                                  }</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  {/* <span>Preferred Salary (Monthly – AED) :</span> */}
                                  <span>Preferred Salary  :</span>
                                  <p>AED {details?.expected_salary}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>
                                    Any preference in terms of nationality ? :
                                  </span>
                                  <p>{details?.preferred_location?.map((item, index) => item?.name + ",")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>Your Skills :</span>
                                  <p>{details?.skills?.map((item, index) => item?.name + ",")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Nannypro;
