import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { getServiceApi, } from "../Action";
import shape2 from "../../assets/img/shape2.svg";
import { CategoryContext } from "../Context/CategoryContext";
import RenderLoader from "../../Components/RenderLoader";

const JobCategorySection = (props) => {
    const { updateSearchData } = useContext(CategoryContext);

    const history = useNavigate();
    const [loader, setLoader] = useState(false);
    const [jobCategoryText, setJobCategoryText] = useState({});

    const serviceApi = async () => {
        await getServiceApi('job-category').then(response => {
            if (response.status) {
                setJobCategoryText(response.data);
                setLoader(false);
            } else {
                setLoader(false)
            }
        })
    };

    const handleSearchCategory = (category) => {
        let data = {};
        data.looking = [category?._id];
        data.looking_arr = [{ label: category?.name, value: category?._id, key: '0' }];
        updateSearchData(data);
        history("/searchnannies");
    }
    useEffect(() => {
        serviceApi();
    }, [])

    return (
        <section className="category_sec">
            <div className="container">
                <div className="category_sec_in">
                    <div className="heading_sec1">
                        <h3>{jobCategoryText?.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: jobCategoryText?.description }}></p>
                    </div>
                    <div className="category_itms">
                        <div className="row">

                            {
                                loader ?
                                    <RenderLoader trans={true} />
                                    : null
                            }

                            {props.jobCategoryList?.length > 0 ?
                                props.jobCategoryList?.map((item, index) =>
                                    <div className="col_5 col" key={index}>
                                        <div className="category_itm">
                                            <div className="category_itm_ic">
                                                <img src={process.env.REACT_APP_URL + '/' + item?.image} alt={item?.image} />
                                            </div>
                                            <div className="category_itm_con">
                                                <h5 className="category_name">{item?.name}</h5>
                                                <span className="category_profiles">{item?.count} Profiles</span>
                                                <span onClick={(e) => handleSearchCategory(item)} className="more_btn">
                                                    View More
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null
                            }
                        </div >
                    </div>
                </div>
            </div>
            <div className="shape_sec2">
                <img src={shape2} alt="" />
            </div>
        </section>
    )
}

export default JobCategorySection
