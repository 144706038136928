import React, { useState, useEffect } from 'react'
import { EmployerSidebar } from "../Pages/LoginSidebar";
import standard from "../assets/img/standard.svg"
import Expire_ic from "../assets/img/expire_ic.svg"
import { Link } from 'react-router-dom'
import RenderLoader from '../Components/RenderLoader';
import { getSubscribePlanDetails } from './Action';

const EmpPlan = () => {
  const [loader, setLoader] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [planDays, setPlanDays] = useState(0);
  const [planRemainingDays, setPlanRemainingDays] = useState(0);


  const fetchPlanDetails = async () => {
    setLoader(true);
    await getSubscribePlanDetails().then(response => {
      if (response.status) {
        setPlanDetails(response.data);
        const data = response.data;
        if (data?.planId?.duration_type == 'Week') {
          setPlanDays(data?.planId?.duration * 7);
        }
        else if (data?.planId?.duration_type == 'Month') {
          setPlanDays(data?.planId?.duration * 30);

        } else {
          setPlanDays(data?.planId?.duration);
        }

        // Define the end date as "30/11/2023".
        const endDate = new Date(data?.endDate);
        // const startDate = new Date(data?.startDate);
        const startDate = new Date();
        // Calculate the time remaining in milliseconds.
        const timeRemaining = endDate - startDate;
        // Convert milliseconds to days.
        const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        setPlanRemainingDays((daysRemaining > 0) ? daysRemaining : 0);
        console.log("********timeRemaining************",daysRemaining);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  }

  useEffect(() => {
    window.document.title = "User plan : Maids & Nanny";
    fetchPlanDetails();
  }, []);
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">

            <EmployerSidebar />
            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }
            <div className="profile_content">
              <div className="profile_form">
                {(planDetails) ?
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="profile_form_cnn">
                        <h4>My Selected Plan : {planDetails?.status?.toUpperCase()}</h4>
                        <div className="profile_form_cnn_plan"><i><img src={standard} /></i><span>{planDetails?.planId?.name}</span></div>
                        <div className="profile_form_cnn_prc">
                          <div className="plan_sec_prc"><span className="plan_sec_prc_currency">AED</span> {planDetails?.amount}<span className="plan_sec_prc_time">/ {planDetails?.planId?.duration} {planDetails?.planId?.duration_type}</span></div>
                        </div>
                        <div className="plan_expire">
                          <div className="plan_expire_con">
                            <span>Plan Expire on:</span>
                            <p>{planRemainingDays} Days ({planDays}/{planRemainingDays})</p>
                          </div>
                          <div className="plan_expire_ic"><img src={Expire_ic} /></div>
                        </div>
                        <div className="btn_sec mt-4">
                          <Link to="/serviceplan" className="btn btn-type1">Change Plan</Link>
                          <Link to="/serviceplan" className="btn btn-type1 ms-3">Renew now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile_form_cnn">
                        <h4>Service Plan Specifications</h4>
                        <ul className="list_type1">
                          {
                            planDetails?.planId?.features?.map((item, index) =>
                              <li key={index}>{item?.value}</li>
                            )}
                          <li className={planDetails?.planId?.consultant ? "" : "text-decoration-line-through"}>
                            Dedicated consultant*
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="profile_form_cnn">
                        <h4>No Selected Plan</h4>
                        <div className="btn_sec mt-4">
                          <Link to="/serviceplan" className="btn btn-type1">Select An Plan</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default EmpPlan