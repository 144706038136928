import React, { useContext, useEffect, useState } from "react";
import logo_white from "../../assets/img/logo_white.svg";
import facebook from "../../assets/img/facebook.svg";
import twitter from "../../assets/img/twitter.svg";
import linkedin from "../../assets/img/linkedin.svg";
import instagram from "../../assets/img/instagram.svg";
import whatsapp_ic from "../../assets/img/whatsapp_ic.svg";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { getCategoryApi } from "../../Pages/Action";
import RenderLoader from "../RenderLoader";
import { CategoryContext } from "../../Pages/Context/CategoryContext";

const Footer = () => {
  const history = useNavigate();
  const { updateSearchData } = useContext(CategoryContext);
  const [loader, setLoader] = useState(false);
  const [jobCategoryList, setJobCategoryList] = useState([]);

  const categoryList = async () => {
    await getCategoryApi().then(async (response) => {
      if (response.status) {
        setJobCategoryList(response?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const handleNavigation = (event, item) => {
    event.preventDefault();
    let data = {};
    data.looking = [item?._id];
    data.looking_arr = [{ label: item?.name, value: item?._id, key: "0" }];
    updateSearchData(data);
    history("/searchnannies");
  };
  useEffect(() => {
    categoryList();
  }, []);
  return (
    <>
      <footer className="footer_sec">
        <div className="container">
          <div className="footer_sec_in">
            <div className="footer_top">
              <div className="row">
                <div className="col-lg-4">
                  <div className="footer_sec_itm">
                    <Link to="/" className="footer_sec_itm_logo">
                      <img src={logo_white} alt="" />
                    </Link>
                    <p>
                      Maids & Nanny is a growing platform for all families and
                      house help to get in touch with each other and form
                      meaningful bonds during their time in the UAE. Found by a
                      couple who moved to Dubai in 2019, this platform is a
                      one-stop solution for all your house help needs.
                    </p>
                  </div>
                </div>
                {loader ? <RenderLoader trans={true} /> : null}
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-4 col-sm-6">
                      <div className="footer_sec_itm">
                        <div className="footer_sec_heading">
                          <h4>Quick Links</h4>
                        </div>
                        <div className="footer_itms">
                          <ul>
                            <li>
                              <Link to="/">Home</Link>
                            </li>
                            <li>
                              <Link to="/searchnannies">Search Nannies</Link>
                            </li>
                            <li>
                              <Link to="/contactUs">Contact Us</Link>
                            </li>
                            <li>
                              <Link to="/page/terms-conditions">
                                Terms and Conditions
                              </Link>
                            </li>
                            <li>
                              <Link to="/page/privacy-policy">
                                Privacy Policy
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="footer_sec_itm">
                        <div className="footer_sec_heading">
                          <h4>Find Workers</h4>
                        </div>
                        <div className="footer_itms">
                          <ul>
                            {jobCategoryList?.length > 0
                              ? jobCategoryList?.map((item, index) => (
                                  <li key={index}>
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        handleNavigation(e, item);
                                      }}
                                    >
                                      {item?.name}
                                    </Link>
                                  </li>
                                ))
                              : ""}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="footer_sec_itm">
                        <div className="footer_sec_heading">
                          <h4>Contact Us</h4>
                        </div>
                        <div className="footer_itms">
                          <ul>
                            <li>
                              <Link to="mailto:info@maids_nanny.com">
                                Email:
                                <br />
                                team@maidsandnanny.com
                              </Link>
                            </li>
                            <li>
                              <Link to={`tel:${"+971521297955"}`}>
                                Mobile Number:
                                <br />
                                +971-521297955
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_bot">
              <div className="row">
                <div className="col-md-7">
                  <div className="footer_bot_cn">
                    Copyright © 2023 Maids & Nanny . All Rights Reserved
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="footer_social_itms">
                    <ul>
                      <li>
                        <Link to="https://www.facebook.com/profile.php?id=100093233419833&mibextid=ZbWKwL">
                          <img src={facebook} alt="" />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#">
                          <img src={twitter} alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={linkedin} alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={instagram} alt="" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
      <div className="wts_fixed">
        <Link to={`https://wa.me/${"+971521297955"}`} target="_blank">
          <img src={whatsapp_ic} alt="" />
        </Link>
      </div>
    </>
  );
};

export default Footer;
