import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getServiceApi, getLastPostOnApi, addShortlistedListApi, likeNannyProfilesShow } from "../Action";
import NannyProfile from "./NannyProfile";
import RenderLoader from "../../Components/RenderLoader";
import { toast } from "react-hot-toast";
import { GuestUserModalContext } from "../Context/GuestUserModalContext";

const LastPostedOnSection = () => {
    const { showGuestModal } = useContext(GuestUserModalContext);
    const [loader, setLoader] = useState(false);
    const [lastPostOnList, setLastPostOnList] = useState([]);
    const [lastPostText, setLastPostText] = useState({});
    const [likeprofile, setLikeprofile] = useState([]);
    var today = new Date();
    var currdate = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    var backdate = today.getDate()-7 + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    const [date, setDate] = useState(backdate+" : "+currdate);

    const lastPostOn = async () => {
        await getLastPostOnApi().then(response => {
            if (response.status) {
                setLoader(false)
                setLastPostOnList(response?.data);
            } else {
                setLoader(false)
            }
        })
    }

    const serviceApi = async () => {
        await getServiceApi('last-posted-on').then(response => {
            if (response.status) {
                setLoader(false)
                setLastPostText(response.data);
            } else {
                setLoader(false)
            }
        })
    };

    const addFavorite = async (id,fullname="") => {
        let objData = {
            nanny_id: id,
        }
        const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
        if (userDetails?.role == 1) {
            setLoader(true)
            await addShortlistedListApi(objData).then(response => {
                if (response.status) {
                    setLoader(false)
                    toast.success(response.message);
                    fetchLikeProfile();
                } else {
                    // toast.success(response.message);
                    showGuestModal(fullname);
                    setLoader(false)
                }
            })
        } else {
            showGuestModal(fullname);
        }
    }

    const fetchLikeProfile = async () => {
        setLoader(true);
        const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
        if(userDetails){
            await likeNannyProfilesShow(userDetails?._id).then(response => {
                if (response.status) {
                    setLikeprofile(response.data);
                    setLoader(false);
                } else {
                    setLoader(false)
                }
            })
        }
    }

    useEffect(() => {
        serviceApi();
        lastPostOn();
        fetchLikeProfile();
        // setLoader(false);
    }, [])
    return (
        lastPostOnList?.length > 0 &&
        <section className="profile_sec bg_type1">
            <div className="container">
                <div className="profile_sec_in">

                    {
                        loader ?
                            <RenderLoader trans={true} />
                            : null
                    }
                    <div className="heading_sec1">

                        <h3>
                            {lastPostText?.title}
                        </h3>
                        <p dangerouslySetInnerHTML={{ __html: lastPostText?.description }}></p>

                    </div>
                    <div className="profile_itms">
                        <div className="row">
                            {
                                lastPostOnList?.length > 0 ?
                                    lastPostOnList?.map((item, index) =>
                                        <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                                            <NannyProfile key={index} item={item} addFavorite={addFavorite} likeprofile={likeprofile} />
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <div className="all_btn_sec">
                            <Link to="/searchnannies" className="btn btn-type1">
                                View All Profiles
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default LastPostedOnSection;
