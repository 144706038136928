import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  getCategoryApi,
  nannyProfileListingApi,
  addShortlistedListApi,
  likeNannyProfilesShow,
} from "./Action";
import left_ic from "../assets/img/left_ic.svg";
import right_ic from "../assets/img/right_ic.svg";
import RenderLoader from "../Components/RenderLoader";
import HomeSearch from "./Home/HomeSearch";
import NannyProfile from "./Home/NannyProfile";
import ReactPaginate from "react-paginate";
import { CategoryContext } from "./Context/CategoryContext";
import { toast } from "react-hot-toast";
import { GuestUserModalContext } from "./Context/GuestUserModalContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import user_ic from "../assets/img/user_ic.svg";

const SearchNannies = () => {
  let location = useLocation();
  const { updateSearchData, searchData } = useContext(CategoryContext);
  const { showGuestModal } = useContext(GuestUserModalContext);
  const [loader, setLoader] = useState(true);
  const [categoryOption, setCategoryOption] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [likeprofile, setLikeprofile] = useState([]);
  const [notFound, setNotFound] = useState(false);

  //---filter start form here---//
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [search, setSearch] = useState("");

  const categoryList = async () => {
    setLoader(true);
    await getCategoryApi().then(async (response) => {
      if (response.status) {
        const categoryArr = await makeReactSelect(response?.data);
        setCategoryOption(categoryArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, key: index, value: item?._id };
      arr.push(obj);
    });
    return arr;
  };

  const addFavorite = async (id,fullname="") => {
    let objData = {
      nanny_id: id,
    };
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if (userDetails?.role == 1) {
      setLoader(true);
      await addShortlistedListApi(objData).then((response) => {
        if (response.status) {
          setLoader(false);
          toast.success(response.message);
          fetchLikeProfile();
        } else {
          // toast.success(response.message);
          showGuestModal(fullname);
          setLoader(false);
        }
      });
    } else {
      showGuestModal(fullname);
    }
  };

  const profileListData = async (limit, page, search) => {
    setLoader(true);
    // console.log("**********searchData************", searchData);
    let objData = {
      limit: limit,
      page: page,
      search: search,
      looking: searchData?.looking,
      nationality: searchData?.nationality,
      live_status: searchData?.live_status,
      location: searchData?.location,
      religion: searchData?.religion,
      gender: searchData?.gender,
      age: searchData?.age,
      lastUpdate: searchData?.lastUpdate,
      experienceWithKids: searchData?.experienceWithKids,
      experience: searchData?.experience,
      job_status: searchData?.job_status,
    };

    await nannyProfileListingApi(objData).then((response) => {
      if (response.status) {
        setLoader(false);
        setProfileList(response?.data);
        setTotalProduct(response?.total);
        if (response.total == 0) {
          setNotFound(true);
        } else {
          setNotFound(false);
        }
      } else {
        setLoader(false);
      }
    });
  };

  const fetchLikeProfile = async () => {
    setLoader(true);
    const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
    if(userDetails){
      await likeNannyProfilesShow(userDetails?._id).then((response) => {
        if (response.status) {
          setLikeprofile(response.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    window.document.title = "Search Nannies : Maids & Nanny";
    window.scroll(0, 0);
    updateSearchData(searchData);
    categoryList();
    profileListData(6, paginationIndex, search);
    fetchLikeProfile();
  }, [searchData, updateSearchData]);

  var divisor = (totalProduct / 6) >> 0;
  var remainder = totalProduct % 6;

  return (
    <>
      <div className="main_sec">
        <HomeSearch categoryOption={categoryOption} />
        <section className="profile_sec profile_sec_main">
          <div className="container">
            <div className="profile_sec_in">
              <div className="profile_itms">
                <div className="row">
                  {profileList?.length > 0
                    ? profileList?.map((item, index) => (
                        <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                          <NannyProfile
                            key={index}
                            item={item}
                            addFavorite={addFavorite}
                            likeprofile={likeprofile}
                          />
                        </div>
                      ))
                    : notFound && (
                        <div>
                          <h3>Profile not found.</h3>
                          <h5>Discover Your Perfect Nanny </h5>
                        </div>
                      )}

                  {loader ? (
                    <>
                      <RenderLoader trans={true} />
                      <Skeleton
                        count={5}
                        height={30}
                        highlightColor="#D3D3D3"
                        enableAnimation="true"
                      />
                    </>
                  ) : null}
                </div>

                <div className="pagination_sec">
                  {totalProduct > 6 ? (
                    <ReactPaginate
                      // previousLabel={"Prev"}
                      previousLabel={<img src={left_ic} />}
                      // nextLabel={"Next"}
                      nextLabel={<img src={right_ic} />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={remainder >= 1 ? divisor + 1 : divisor}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={async (e) => {
                        setLoader(true);
                        setPaginationIndex(e.selected + 1);
                        profileListData(6, e.selected + 1, search);
                      }}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active pp"}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SearchNannies;
