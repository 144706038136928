import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postMethod } from "../Utils/Service";
import toast, { Toaster } from "react-hot-toast";
import RenderLoader from "../Components/RenderLoader";

const ResetPassword = () => {
  const { number, token } = useParams();
  const history = useNavigate();
  const [loader, setLoader] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enableInputBox, setEnableInputBox] = useState(true);

  const handleResetPassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (email === "") {
      toast.error("Please enter new password");
    } else {
      setLoader(false);
      const data = {
        password: password,
        email: email
      };

      await postMethod("user/resetPassword", data).then((response) => {
        if (response.status) {
          setEnableInputBox(true);
          toast.success(response.message);
          setTimeout(() => {
            history('/');
          }, 3000);
        } else {
          toast.error(response.message);
          setLoader(false);
        }
      });
    }
    setLoader(false);
  };

  const checkResetExpiryToken = async () => {
    try {
      const data = {
        encryptedToken: number,
        encryptedEmail: token
      }
      await postMethod("user/checkResetExpiryTokenForWeb", data).then((response) => {
        if (response.status) {
          setEnableInputBox(false)
          setEmail(token);
        } else {
          toast.error("Reset password token is expired... please try again");
          setLoader(false);
          setTimeout(() => {
            history('/');
          }, 4000);
        }
      });
    }
    catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    setLoader(false);
    checkResetExpiryToken();
  }, []);

  return (
    <>
      {loader ? <RenderLoader trans={true} /> : null}

      <div className="main_sec">
        <section className="contact_sec">
          <div className="container">
            <div className="contact_sec_in">
              <div className="row">
                <div className="col-lg-12">
                  <div className="contact_sec_form">
                    <div className="heading_sec2">
                      <h3>Reset Password</h3>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="first_name">New Password</label>
                            <input
                              type="text"
                              className="form-control"
                              name="password"
                              placeholder="Enter your new password..."
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              disabled={enableInputBox}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <button type="button" className="btn btn-type1" onClick={handleResetPassword}
                              disabled={enableInputBox}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


     
    </>
  );
};
export default ResetPassword;
