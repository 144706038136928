import React from 'react'
import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";
import icon3 from "../../assets/img/icon3.png";
import icon4 from "../../assets/img/icon4.png";

function HowItWorks() {
    return (
        <section className="steps_sec">
            <div className="container">
                <div className="steps_sec_in">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="steps_con">
                                <div className="heading_sec2">
                                    <h6>How It Works</h6>
                                    <h3>4 simple steps to choose the best for yourself</h3>
                                </div>
                                <ul className="list_type1">
                                    <li>Trustworthy & Quality Job</li>
                                    <li>Global Job Opportunities</li>
                                    <li>No Extra Costs</li>
                                    <li>Top Families</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="steps_con_itms">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="steps_con_itm">
                                            <span className="steps_con_itm_n">01</span>
                                            <div className="steps_con_itm_ic">
                                                <img src={icon1} />
                                            </div>
                                            <div className="steps_con_itm_con">
                                                <div className="contant">
                                                    <h4>Register your account</h4>
                                                    <p>
                                                    Create your profile and add your preferences.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="steps_con_itm">
                                            <span className="steps_con_itm_n">02</span>
                                            <div className="steps_con_itm_ic">
                                                <img src={icon2} />
                                            </div>
                                            <div className="steps_con_itm_con">
                                                <div className="contant">
                                                    <h4>
                                                        Start your search
                                                    </h4>
                                                    <p>
                                                    Browse profiles to find your best fit.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="steps_con_itm">
                                            <span className="steps_con_itm_n">03</span>
                                            <div className="steps_con_itm_ic">
                                                <img src={icon3} />
                                            </div>
                                            <div className="steps_con_itm_con">
                                                <div className="contant">
                                                    <h4>
                                                        Choose a service plan
                                                    </h4>
                                                    <p>
                                                    Opt for a plan that suits your budget and needs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="steps_con_itm">
                                            <span className="steps_con_itm_n">04</span>
                                            <div className="steps_con_itm_ic">
                                                <img src={icon4} />
                                            </div>
                                            <div className="steps_con_itm_con">
                                                <div className="contant">
                                                    <h4>
                                                    Initiate contact
                                                    </h4>
                                                    <p>
                                                    Finish checkout and contact your shortlisted profiles
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorks
