import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import cross from "../../assets/img/cross.svg";
import search_ic1 from "../../assets/img/search_ic1.svg";
import search_ic2 from "../../assets/img/search_ic2.svg";
import home_image from "../../assets/img/home_image.svg";

//React-js-bootstap select
import { MultiSelect } from "react-multi-select-component";
import { Form, Row, Col, Button, Accordion } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  getPositionApi,
  getStateListApi,
  getNationalityApi,
  getReligionApi,
  getExperienceWithKidApi,
} from "../Action";
import { CategoryContext } from "../Context/CategoryContext";
import RenderLoader from "../../Components/RenderLoader";

function HomeSearch(props) {
  const location = useLocation();
  const history = useNavigate();
  const { updateSearchData, searchData } = useContext(CategoryContext);
  let pageClass = location?.pathname == "/" ? "col-lg-7" : "col-lg-9";

  //----category multi select-----//
  const [loader, setLoader] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [advanceSearch, setAdvanceSearch] = useState({});

  const handleLookingforChange = (selectedOptions) => {
    let cat = [];
    if (selectedOptions?.length > 0) {
      selectedOptions.map((item, index) => {
        cat.push(item.value);
      });
    }
    setAdvanceSearch({
      ...advanceSearch,
      ["looking_arr"]: selectedOptions,
      ["looking"]: cat,
    });
    setCategorySelected(selectedOptions);
  };

  //----Postition /live status multi select-----//
  const [positionOption, setPositionOption] = useState([]);
  const [positionSelected, setPositionSelected] = useState([]);
  const positionList = async () => {
    await getPositionApi().then(async (response) => {
      if (response.status) {
        const positionArr = await makeArrayReactSelect(response?.data);
        setPositionOption(positionArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const handlePositionChange = (selectedOptions) => {
    let cat = [];
    if (selectedOptions?.length > 0) {
      selectedOptions.map((item, index) => {
        cat.push(item.value);
      });
    }
    // console.log("******position*******", cat);
    setAdvanceSearch({
      ...advanceSearch,
      ["live_status_arr"]: selectedOptions,
      ["live_status"]: cat,
    });
    setPositionSelected(selectedOptions);
  };

  //----Location multi select-----//
  const [locationOption, setLocationOption] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const locationList = async () => {
    await getStateListApi().then(async (response) => {
      if (response.status) {
        const locationArr = await makeReactSelect(response?.data);
        setLocationOption(locationArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const handleLocationChange = (selectedOptions) => {
    let arr = [];
    if (selectedOptions?.length > 0) {
      selectedOptions.map((item, index) => {
        arr.push(item.value);
      });
    }
    // console.log("******location*******", arr);
    setAdvanceSearch({
      ...advanceSearch,
      ["location_arr"]: selectedOptions,
      ["location"]: arr,
    });
    setLocationSelected(selectedOptions);
  };

  //----Nationality multi select-----//
  const [nationalityOption, setNationalityOption] = useState([]);
  const [nationalitySelected, setNationalitySelected] = useState([]);
  const nationalityList = async () => {
    await getNationalityApi().then(async (response) => {
      if (response.status) {
        const locationArr = await makeReactSelect(response?.data);
        setNationalityOption(locationArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const handleNationalityChange = (selectedOptions) => {
    let arr = [];
    if (selectedOptions?.length > 0) {
      selectedOptions.map((item, index) => {
        arr.push(item.value);
      });
    }
    // console.log("******nationality*******", arr);
    setAdvanceSearch({
      ...advanceSearch,
      ["nationality_arr"]: selectedOptions,
      ["nationality"]: arr,
    });
    setNationalitySelected(selectedOptions);
  };

  //----religion multi select-----//
  const [religionOption, setReligionOption] = useState([]);
  const [religionSelected, setReligionSelected] = useState([]);
  const religionList = async () => {
    await getReligionApi().then(async (response) => {
      if (response.status) {
        const religionArr = await makeReactSelect(response?.data);
        setReligionOption(religionArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const handleReligionChange = (selectedOptions) => {
    let cat = [];
    if (selectedOptions?.length > 0) {
      selectedOptions.map((item, index) => {
        cat.push(item.value);
      });
    }
    // console.log("******religion*******", cat);
    setAdvanceSearch({
      ...advanceSearch,
      ["religion_arr"]: selectedOptions,
      ["religion"]: cat,
    });
    setReligionSelected(selectedOptions);
  };

  //----religion multi select-----//
  const [experienceWithKidOption, setExperienceWithKidOption] = useState([]);
  const [experienceWithKidSelected, setExperienceWithKidSelected] = useState(
    []
  );
  const experienceWithKidList = async () => {
    await getExperienceWithKidApi().then(async (response) => {
      if (response.status) {
        const religionArr = await makeReactSelect(response?.data);
        setExperienceWithKidOption(religionArr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };
  const handleExperienceWithKidChange = (selectedOptions) => {
    let cat = [];
    if (selectedOptions?.length > 0) {
      selectedOptions.map((item, index) => {
        cat.push(item.value);
      });
    }
    // console.log("******experience with kids******", cat);
    setAdvanceSearch({
      ...advanceSearch,
      ["experienceWithKids_arr"]: selectedOptions,
      ["experienceWithKids"]: cat,
    });
    setExperienceWithKidSelected(selectedOptions);
  };

  // age form events
  const [selectedAge, setSelectedAge] = useState("");
  function handleAgeChange(event) {
    setSelectedAge(event.target.value);
    // console.log("**age****", event.target.value);
    setAdvanceSearch({ ...advanceSearch, ["age"]: event.target.value });
  }

  // Last updated events
  const [selectLastUpdate, setSelectLastUpdate] = useState("");
  function handleLastUpdated(event) {
    setSelectLastUpdate(event.target.value);
    // console.log("***last***", event.target.value);
    setAdvanceSearch({ ...advanceSearch, ["lastUpdate"]: event.target.value });
  }

  // Post status events
  const [postStatus, setPostStatus] = useState("");
  function handleJobPostStatusChange(event) {
    setPostStatus(event.target.value);
    // console.log("***Job Status***", event.target.value);
    setAdvanceSearch({ ...advanceSearch, ["job_status"]: event.target.value });
  }

  // Post status events
  const [totalExperience, setTotalExperience] = useState("");
  function handleTotalExperienceChange(event) {
    setTotalExperience(event.target.value);
    // console.log("***total experience***", event.target.value);
    setAdvanceSearch({ ...advanceSearch, ["experience"]: event.target.value });
  }

  // ----makereactselect function----------------------

  const handleSearch = () => {
    //---advace search---//
    console.log("Basic Search", advanceSearch);
    updateSearchData(advanceSearch);
    location?.pathname == "/" && history("/searchnannies");
  };

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, key: index, value: item?._id };
      arr.push(obj);
    });
    return arr;
  };

  const makeArrayReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item, key: index, value: item };
      arr.push(obj);
    });
    return arr;
  };
  // -------------------remove all below to this line------------------------

  // reactbootstap Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // gender form
  const [selectedGender, setSelectedGender] = useState("");

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
    // console.log(("*****gender*********", e.target.value));
    setAdvanceSearch({ ...advanceSearch, ["gender"]: e.target.value });
  };

  const handleAdvanceSearchSubmit = (event) => {
    event.preventDefault();
    //---advace search---//
    // console.log("Advanced search", advanceSearch)
    updateSearchData(advanceSearch);
    setShow(false);
    location?.pathname == "/" && history("/searchnannies");
  };

  const handleresetForm = () => {
    setAdvanceSearch({});
    updateSearchData({});
    setPositionSelected([]);
    setCategorySelected([]);
    setLocationSelected([]);
    setNationalitySelected([]);
    setReligionSelected([]);
    setExperienceWithKidSelected([]);
    setSelectedAge("");
    setSelectLastUpdate("");
    setPostStatus("");
    setTotalExperience("");
    setSelectedGender("");
    setShow(false);
  };

  const setAdvanceSearchValueSelected = () => {
    if (location?.pathname !== "/") {
      setAdvanceSearch(searchData);
      setCategorySelected(
        searchData?.looking_arr ? searchData?.looking_arr : []
      );
      setPositionSelected(
        searchData?.live_status_arr ? searchData?.live_status_arr : []
      );
      setLocationSelected(
        searchData?.location_arr ? searchData?.location_arr : []
      );
      setNationalitySelected(
        searchData?.nationality_arr ? searchData?.nationality_arr : []
      );
      setReligionSelected(
        searchData?.religion_arr ? searchData?.religion_arr : []
      );
      setExperienceWithKidSelected(
        searchData?.experienceWithKids_arr
          ? searchData?.experienceWithKids_arr
          : []
      );
      setSelectedAge(searchData?.age ? searchData?.age : "");
      setSelectLastUpdate(searchData?.lastUpdate ? searchData?.lastUpdate : "");
      setPostStatus(searchData?.job_status ? searchData?.job_status : "");
      setTotalExperience(searchData?.experience ? searchData?.experience : "");
      setSelectedGender(searchData?.gender ? searchData?.gender : "");
    }
  };

  useEffect(() => {
    setAdvanceSearchValueSelected();
    positionList();
    locationList();
    nationalityList();
    religionList();
    experienceWithKidList();
    if (location?.pathname === "/") {
      updateSearchData({});
    }
    // -------different services----------

    // setLoader(false);
  }, []);
  return (
    <section className="homesld_sec bg_type2">
      <div className="container">
        <div className="homesld_sec_in">
          <div className="row align-items-center">
            <div className={pageClass}>
              <div className="homesld_sec_con">
                {loader ? <RenderLoader trans={true} /> : null}

                {location?.pathname == "/" && (
                  <>
                    <div className="heading_type1">
                      <h5>Looking to hire a nanny or a maid?</h5>
                      <h1>Start your search here</h1>
                    </div>
                    <div className="search_now_btn">
                      <Link to="/" className=" btn btn-type1">
                        Search
                      </Link>
                    </div>
                  </>
                )}
                <div className="search_sec">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group-h">
                          <label className="form-label">LOOKING FOR A</label>
                          <MultiSelect
                            isMulti
                            value={categorySelected}
                            options={props.categoryOption}
                            onChange={handleLookingforChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group-h">
                          <label className="form-label">
                            TYPE OF EMPLOYEMENT
                          </label>
                          <MultiSelect
                            isMulti
                            value={positionSelected}
                            options={positionOption}
                            onChange={handlePositionChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group-h">
                          <label className="form-label">LOCATION</label>
                          <MultiSelect
                            isMulti
                            value={locationSelected}
                            options={locationOption}
                            onChange={handleLocationChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group-h">
                          <label className="form-label">NATIONALITY</label>
                          <MultiSelect
                            isMulti
                            value={nationalitySelected}
                            options={nationalityOption}
                            onChange={handleNationalityChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="search_sec_itm search_sec_itm_btn">
                    <button
                      type="button"
                      className="btn_default"
                      onClick={handleSearch}
                    >
                      <img src={search_ic1} alt="" />
                      <span>Search</span>
                    </button>
                  </div>
                </div>
                {location?.pathname == "/" && (
                  <div className="advance_search">
                    <h4>Looking for more specific profiles</h4>
                    <h6>Use advanced search</h6>
                    <button className="advance_search_btn" onClick={handleShow}>
                      <i className="advance_search_ic">
                        <img src={search_ic2} alt="" />
                      </i>
                      <span>Search here</span>
                    </button>
                  </div>
                )}
                <div className="mobody">
                  <Modal
                    className="search-modal"
                    show={show}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="heading_sec1">
                        <h3>Search Nanny</h3>
                        <p>
                          Looking for more specific profiles, Use advanced
                          search.
                        </p>
                      </div>
                      <div className="">
                        <Form onSubmit={handleAdvanceSearchSubmit}>
                          <Row>
                            <Col md={6}>
                              <Form.Group controlId="Nanny">
                                <label className="form-label">
                                  LOOKING FOR A
                                </label>
                                <MultiSelect
                                  isMulti
                                  value={categorySelected}
                                  options={props.categoryOption}
                                  onChange={handleLookingforChange}
                                  className="multi"
                                  isSearchable={true}
                                  labelledBy="Select Nanny type"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="livein">
                                <label className="form-label">
                                TYPE OF EMPLOYEMENT
                                </label>
                                <MultiSelect
                                  isMulti
                                  value={positionSelected}
                                  options={positionOption}
                                  onChange={handlePositionChange}
                                  className="multi"
                                  placeholder="Select Posistion"
                                  isSearchable={true}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="location">
                                <label className="form-label">LOCATION</label>
                                <MultiSelect
                                  isMulti
                                  value={locationSelected}
                                  options={locationOption}
                                  onChange={handleLocationChange}
                                  className="multi"
                                  placeholder="Select Location"
                                  isSearchable={true}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="nationality">
                                <label className="form-label">
                                  NATIONALITY
                                </label>
                                <MultiSelect
                                  isMulti
                                  value={nationalitySelected}
                                  options={nationalityOption}
                                  onChange={handleNationalityChange}
                                  className="multi"
                                  placeholder="Select Nationality"
                                  isSearchable={true}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="religion">
                                <label className="form-label">RELIGION</label>
                                <MultiSelect
                                  className="multi"
                                  value={religionSelected}
                                  options={religionOption}
                                  placeholder="Select Religion"
                                  onChange={handleReligionChange}
                                  isSearchable={true}
                                  isMulti
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="skills">
                                <label className="form-label">
                                  EXPERIENCE WITH KIDS
                                </label>
                                <MultiSelect
                                  className="multi"
                                  options={experienceWithKidOption}
                                  placeholder="Select Skills"
                                  value={experienceWithKidSelected}
                                  onChange={handleExperienceWithKidChange}
                                  isSearchable={true}
                                  isMulti
                                />
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group className="multi">
                                <Form.Label>Gender</Form.Label>
                                <div className="custom_radio_itms">
                                  <Form.Check
                                    type="radio"
                                    id="male"
                                    label="Male"
                                    value="male"
                                    checked={"male" == selectedGender}
                                    name="gender"
                                    onChange={handleGenderChange}
                                  />
                                  <Form.Check
                                    type="radio"
                                    id="female"
                                    label="Female"
                                    value="female"
                                    checked={"female" == selectedGender}
                                    name="gender"
                                    onChange={handleGenderChange}
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Select
                                  value={selectedAge}
                                  aria-label="Default select example"
                                  className="multi"
                                  onChange={handleAgeChange}
                                >
                                  <option value="">Select Age</option>
                                  <option value="18-25">18-25</option>
                                  <option value="25-30">25-30</option>
                                  <option value="30-35">30-35</option>
                                  <option value="35-40">35-40</option>
                                  <option value="40">40+</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Select
                                  value={selectLastUpdate}
                                  aria-label="Default select example"
                                  className="multi"
                                  onChange={handleLastUpdated}
                                >
                                  <option value="">Select last updated</option>
                                  <option value="7">1 Week</option>
                                  <option value="14">2 Weeks</option>
                                  <option value="30">1 Month</option>
                                  <option value="60">2 Months</option>
                                  <option value="365">1 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>

                            <Col md={6}>
                              <Form.Group controlId="experience">
                                <Form.Select
                                  value={totalExperience}
                                  aria-label="Default select example"
                                  className="multi"
                                  onChange={handleTotalExperienceChange}
                                >
                                  <option value="">Select Experience</option>
                                  <option value="0-3">0 to 3 Yrs</option>
                                  <option value="3-5">3 to 5 Yrs</option>
                                  <option value="5-10">5 to 10 Yrs</option>
                                  <option value="10">10+ Yrs</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="postStatus">
                                <Form.Select
                                  value={postStatus}
                                  aria-label="Default select example"
                                  className="multi"
                                  onChange={handleJobPostStatusChange}
                                >
                                  <option value="">Select post status</option>
                                  <option value="true">Hired</option>
                                  <option value="false">Open to work</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group className="mb-0 mt-1 text-center">
                                <Button type="submit" className="btn btn-type1">
                                  Search
                                </Button>
                                &nbsp;
                                <Button
                                  type="reset"
                                  className="btn btn-type1"
                                  onClick={handleresetForm}
                                >
                                  Reset
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
            {location?.pathname != "/" && (
              <div className="col-lg-3 advance_search">
                <button className="advance_search_btn" onClick={handleShow}>
                  <i className="advance_search_ic">
                    <img src={search_ic2} />
                  </i>
                  <span>Search Nanny</span>
                </button>
              </div>
            )}
            {location?.pathname == "/" && (
              <>
                <div className="col-md-5">
                  <div className="homesld_sec_img">
                    <img src={home_image} alt="" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSearch;
