import React from 'react';
import { setAuthorizationToken } from '../Utils/Service';
import { setCookies } from "../Utils/cookies";
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from "react-router-dom";



export const NannySidebar = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const handleLogout = async (e) => {
        setCookies("_isJwtFront", false);
        setAuthorizationToken(false);
        setCookies("user_details", "");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user_details");
        sessionStorage.removeItem("plan_subscribed");
        navigate("/");
    };

    return (
        <div className="sidebar_sec">
            <div className="sidebar_sec_in">
                <ul className="sidebar_sec_itms">
                    <li>
                        <Link to="/nannypro" className={location?.pathname == "/nannypro" || location?.pathname == "/nannyEdit" ? "active" : ""} >
                            Profile <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/changepassword" className={location?.pathname == "/changepassword" ? "active" : ""}>
                            Change PIN<span></span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={handleLogout}>
                            Log Out <span></span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export const EmployerSidebar = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const handleLogout = async (e) => {
        setCookies("_isJwtFront", false);
        setAuthorizationToken(false);
        setCookies("user_details", "");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user_details");
        sessionStorage.removeItem("plan_subscribed");
        navigate("/");
    };    
    return (
        <div className="sidebar_sec">
            <div className="sidebar_sec_in">
                <ul className="sidebar_sec_itms">
                    <li>
                        <Link to="/empProfile" className={location?.pathname == "/empProfile" || location?.pathname == "/employerEdit" ? "active" : ""}>
                            Profile <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/changepassword" className={location?.pathname == "/changepassword" ? "active" : ""}>
                            Change Password / PIN <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/empplan" className={location?.pathname == "/empplan" ? "active" : ""}>
                            My Plans <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/empshortlist" className={location?.pathname == "/empshortlist" ? "active" : ""}>
                            My Shortlisted <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/" onClick={handleLogout}>
                            Log Out <span></span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}


