import * as Yup from "yup";

// export const registertogetdirectfamiles = Yup.object({
//   profilePicture: Yup.mixed().required("Profile Picture is required"),
//   video: Yup.mixed().required("Video is required"),
//   passport: Yup.mixed().required("Passport is required"),
//   visa: Yup.mixed().required("Visa is required"),
//   nationality: Yup.string().required("Nationality is required"),
//   religion: Yup.string().required("Religion is required"),
//   gender: Yup.string().required("Gender is required"),
//   maritalStatus: Yup.string().required("Marital Status is required"),
//   age: Yup.string().required("Age is required"),
//   visaStatus: Yup.string().required("Visa Status is required"),
//   numberOfKids: Yup.string().required("Number of Kids is required"),
//   experience: Yup.string().required("Years of experience is required"),
//   experienceWithKids: Yup.array().required("Experience with Kids is required"),
//   languages: Yup.array().required("Languages is required"),
//   specificLanguage: Yup.string().when("languages", {
//     is: (languages) => languages && languages.includes("other"),
//     then: Yup.string().required("Specific Language is required"),
//     otherwise: Yup.string(),
//   }),
//   skills: Yup.array().required("Skills is required"),
//   acceptTerms: Yup.boolean()
//     .oneOf([true], "Accepting the terms and conditions is required")
//     .required("Accepting the terms and conditions is required"),
// });

// register for job step 1 ||
export const registerjobstep1 = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  // job_category: Yup.string().required("Job type is required"),
  job_array: Yup.array()
    .min(1, "At least one job type is required")
    .required("job type is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City Number is required"),
  mobile: Yup.string().required("Mobile Number is required"),
  whatsAppNumber: Yup.string().required("whats App Number is required"),
  login_name: Yup.string()
    .required("Create Login name is required")
    .min(3, "Must be at least 3 characters")
    .max(20, "Must be at most 20 characters"),
  login_pin: Yup.number()
    .typeError("Invalid number")
    .required("Create Login Pin is required")
    .integer("Number must be an integer")
    .positive("Number must be positive")
    .min(100000, "Number must be at least 6 digits")
    .max(99999999, "Number must be at most 8 digits"),
});

// register for job step 2 ||
export const registerjobstep2 = Yup.object({
  profile_image_formik: Yup.mixed().required("Profile picture is required"),
  // video_formik: Yup.mixed().required("Video is required"),
  passport_formik: Yup.mixed().required("Passport is required"),
  visa_formik: Yup.mixed().required("Visa is required"),
  nationality: Yup.string().required("Nationality is required"),
  religion: Yup.string().required("Religion is required"),
  gender: Yup.string().required("Gender is required"),
  marital_status: Yup.string().required("Marital status is required"),
  dob: Yup.string().required("Age is required"),
  visa_status: Yup.string()
    .required("Visa status is required")
    .max(50, "Must be at most 50 characters"),
  number_of_kids: Yup.string()
    .required("Number of kids is required")
    .max(2, "Must be at most 2 digit"),
  total_experience: Yup.string()
    .required("Experience is required")
    .max(2, "Must be at most 2 digit"),
  experience_with_kids: Yup.array()
    .min(1, "At least one experience is required")
    .required("Experiece with Kids is required"),
  language: Yup.array()
    .min(1, "At least one language is required")
    .required("Langauge is required"),
  skill_array: Yup.array()
    .min(1, "At least one skill is required")
    .required("Skill is required"),
  // bio: Yup.string().required("Bio is required").max(500, 'Must be at most 500 characters'),
  bio: Yup.string().max(500, "Must be at most 500 characters"),
  live_status_array: Yup.array()
    .min(1, "At least one Type of employment is required")
    .required("Type of employment is required"),
  expected_salary: Yup.string()
    .required("Expected salary is required")
    .max(50, "Must be at most 50 characters"),
  preferred_location: Yup.string()
    .notRequired()
    .max(50, "Must be at most 50 characters"),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

// register for nanny  ||
export const editNannyProfile = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  job_array: Yup.array()
    .min(1, "At least one job type is required")
    .required("job type is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City Number is required"),
  mobile: Yup.string().required("Mobile Number is required"),
  whatsAppNumber: Yup.string().required("whats App Number is required"),
  login_name: Yup.string().required("Login name is required"),
  nationality: Yup.string().required("Nationality is required"),
  religion: Yup.string().required("Religion is required"),
  gender: Yup.string().required("Gender is required"),
  marital_status: Yup.string().required("Marital status is required"),
  dob: Yup.string().required("Age is required"),
  visa_status: Yup.string()
    .required("Visa status is required")
    .max(50, "Must be at most 50 characters"),
  number_of_kids: Yup.string()
    .required("Number of kids is required")
    .max(2, "Must be at most 2 digit"),
  total_experience: Yup.string()
    .required("Experience is required")
    .max(2, "Must be at most 2 digit"),
  experience_with_kids: Yup.array()
    .min(1, "At least one experience is required")
    .required("Experiece with Kids is required"),
  language: Yup.array()
    .min(1, "At least one language is required")
    .required("Langauge is required"),
  skill_array: Yup.array()
    .min(1, "At least one skill is required")
    .required("Skill is required"),
  bio: Yup.string()
    .required("Bio is required")
    .max(500, "Must be at most 500 characters"),
  live_status_array: Yup.array()
    .min(1, "At least one Type of employment is required")
    .required("Type of employment is required"),
  expected_salary: Yup.string()
    .required("Expected salary is required")
    .max(50, "Must be at most 50 characters"),
  preferred_location: Yup.string()
    .notRequired()
    .max(50, "Must be at most 50 characters"),
});

// regiter employer page 1
export const registerformnannyformone = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  mobile: Yup.string().required("Mobile Number is required"),
  whatsAppNumber: Yup.number().required("WhatsApp Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required")
    .max(50, "Text must be at most 50 characters"),
  password: Yup.string()
    .required("Password is required")
    .max(20, "Text must be at most 20 characters"),
  // job_array: Yup.array().required("Please select at least one option"),
  // location_array: Yup.array().required("Please select at least one option"),
});
// regiter employer page 2
export const registerformnannyformtwo = Yup.object({
  offer_salary: Yup.string()
    .max(50, "Text must be at most 50 characters"),
  prefer_nationality: Yup.string()
    .required("Preference is required")
    .max(100, "Text must be at most 100 characters"),
  other_requirement: Yup.string().required("Requirements is required"),
  referenceby: Yup.string()
    .required("Referral Source is required")
    .max(100, "Text must be at most 100 characters"),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms and Conditions"
  ),
});

export const editEmployerProfile = Yup.object({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  mobile: Yup.string().required("Mobile Number is required"),
  whatsAppNumber: Yup.number().required("WhatsApp Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required")
    .max(50, "Text must be at most 50 characters"),
  // job_array: Yup.array().required("Please select at least one option"),
  // location_array: Yup.array().required("Please select at least one option"),
  // offer_salary: Yup.string()
  //   .max(50, "Text must be at most 50 characters"),
  // prefer_nationality: Yup.string()
  //   .required("Preference is required")
  //   .max(100, "Text must be at most 100 characters"),
  // other_requirement: Yup.string().required("Requirements is required"),
  // referenceby: Yup.string()
  //   .required("Referral Source is required")
  //   .max(100, "Text must be at most 100 characters"),
});

//---delete it later on----//
// export const registersubmit = Yup.object({
//   offer_salary: Yup.string().required("Salary Range is required"),
//   prefer_nationality: Yup.string().required("Preference is required"),
//   other_requirement: Yup.string().required("Requirements is required"),
//   referenceby: Yup.string().required("Referral Source is required"),
//   acceptTerms: Yup.boolean().oneOf(
//     [true],
//     "You must accept the Terms and Conditions"
//   ),
// });

// contactUs
export const contactUs = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  mobile: Yup.string().required("Mobile Number is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .max(50, "Text must be at most 50 characters"),
  job_type: Yup.string().required("Looking For is required"),
  message: Yup.string()
    .required("Your Message is required")
    .max(500, "Text must be at most 500 characters"),
});

// Other Action
export const otherAction = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  mobile: Yup.string()
    .required("Mobile Number is required")
    .max(50, "Text must be at most 50 characters"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string()
    .required("Your Message is required")
    .max(500, "Text must be at most 500 characters"),
  other: Yup.string().required("Other service is required"),
  new_service: Yup.mixed().when("other", (val) => {
    if (val[0] === "addOther") {
      return Yup.string().required("Please mention your requirements");
    }
    // is: (other) => other && other === "addOther",
    // then: Yup.string().required('Please mention your requirements')
  }),
});

// visaSponsorship
export const validationSchemaVisa = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Text must be at least 3 characters")
    .max(50, "Text must be at most 50 characters"),
  nationality: Yup.string().required("Nationality is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .max(50, "Text must be at most 50 characters"),
  comment: Yup.string()
    .required("Comment is required")
    .max(500, "Text must be at most 500 characters"),
});

// Help Nanny
export const registerNannyHelp = Yup.object().shape({
  job_type: Yup.string().required("Job type is required"),
  mobile: Yup.string().required("Mobile Number is required"),
  whatsAppNumber: Yup.string().required("Whats app Number is required"),
  message: Yup.string()
    .required("Your Message is required")
    .max(500, "Text must be at most 500 characters"),
});
//otp verify validtaion
export const otpValidation = Yup.object().shape({
  otp: Yup.string()
    .matches(/^\d{6}$/, "Login pin must be exactly 6 digits")
    .required("OTP is required"),
  mobile: Yup.string().required("Mobile Number is required"),
  type: Yup.string().required("Type is required"),
});

// validation nanny login formik yup
export const nannyLogin = Yup.object().shape({
  loginName: Yup.string()
    .required("Create Login name is required")
    .min(3, "Must be at least 3 characters")
    .max(20, "Must be at most 20 characters"),
  loginPin: Yup.number()
    .typeError("Invalid number")
    .required("Create Login Pin is required")
    .integer("Number must be an integer")
    .positive("Number must be positive")
    .min(100000, "Number must be at least 6 digits")
    .max(99999999, "Number must be at most 8 digits"),
});

// validation employer login formik yup
export const employerLogin = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required")
    .max(50, "Text must be at most 50 characters"),
  password: Yup.string()
    .required("Password is required")
    .max(20, "Text must be at most 20 characters"),
});

export const paymentDetails = Yup.object({
  nameOnCard: Yup.string().required("Name on Card is required"),
  expiryDate: Yup.string().required("Expiry Date is required"),
  cardNumber: Yup.string().required("Card Number is required"),
  cvv: Yup.string().required("CVV is required"),
});
