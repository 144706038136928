import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Call from "../assets/img/call_ic.svg";
import Wts from "../assets/img/wts_ic.svg";
import Email_ic from "../assets/img/email_ic.svg";
import RenderLoader from "../Components/RenderLoader";
import { getUserDetails } from '../Pages/Action';
import user_ic from "../assets/img/user_ic.svg";
import { EmployerSidebar } from "../Pages/LoginSidebar";

const EmployeeProfile = () => {
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState({});

  const getDetails = async () => {
    setLoader(true);
    await getUserDetails().then(response => {
      if (response.status) {
        setDetails(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  }
  useEffect(() => {
    window.document.title="User profile : Maids & Nanny";
    getDetails();
  }, []);
  return (
    <>
      <section className="profile_secc">
        <div className="container">
          <div className="profile_secc_in">
            <EmployerSidebar />
            {
              loader ?
                <RenderLoader trans={true} />
                : null
            }

            <div className="profile_content">
              <div className="profile_user">
                <div className="profile_user_img">
                  {/* <img src={process.env.REACT_APP_URL + "/" + details?.profile_image} alt="" /> */}
                  <img src={user_ic} alt="" />
                </div>
                <div className="profile_user_con">
                  <h5>{details?.first_name + " " + details?.last_name}</h5>
                  {/* <p>
                    Location: <b>
                      {
                        details?.country ? details?.city?.name + " " + details?.country?.name : ''
                      }
                    </b>
                  </p> */}
                  <p></p>
                  <Link to="/employerEdit" className="btn btn-type1">
                    Edit Profile
                  </Link>
                </div>
              </div>
              <div className="profile_info">
                <div className="profile_info_head">
                  <div className="profile_info_itms">
                    <div className="profile_info_itms_in">
                      <div className="row">
                        <div className="col-6">
                          <div className="profile_info_itm">
                            <Link to="#" onClick={(e) => e.preventDefault()} className="profile_info_itm_in">
                              <i>
                                <img src={Call} />
                              </i>
                              <div className="contactdetail_sec_itm_cn">
                                <span>Contact Number</span>
                                <p>{details?.mobile}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="profile_info_itm">
                            <Link to="#" onClick={(e) => e.preventDefault()} className="profile_info_itm_in">
                              <i>
                                <img src={Wts} />
                              </i>
                              <div className="contactdetail_sec_itm_cn">
                                <span>WhatsApp Number</span>
                                <p>{details?.whatsAppNumber}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="profile_info_itm">
                            <Link to="#" onClick={(e) => e.preventDefault()} className="profile_info_itm_in">
                              <i>
                                <img src={Email_ic} />
                              </i>
                              <div className="contactdetail_sec_itm_cn">
                                <span>Email Address</span>
                                <p>{details?.email}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile_info_con">
                      <div className="profile_info_itms">
                        <div className="row">
                          {/* <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>Position :</span>
                                  {details.live_status?.map((item) => <p>{item}</p>)}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>Location :</span>
                                  <p>{
                                    details?.country ? details?.city?.name + " " + details?.country?.name : ''
                                  }</p>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  {/* <span>Offered Salary (Monthly – AED) :</span> */}
                                  <span>Offered Salary :</span>
                                  <p>AED {details?.offer_salary}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>
                                    Any preference in terms of nationality ? :
                                  </span>
                                  <p>{(details?.prefer_nationality)?details?.prefer_nationality?.name:''}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="profile_info_itm">
                              <div className="profile_info_itm_in">
                                <div className="contactdetail_sec_itm_cn">
                                  <span>Other requirements :</span>
                                  <p dangerouslySetInnerHTML={{ __html: details?.other_requirement }}></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmployeeProfile;
