import React, { useState, useEffect } from "react";
import { blogListingApi, getCategoryApi } from './Action';
import blg1 from "../assets/img/blg1.jpg";
import blg2 from "../assets/img/blg2.jpg";
import blg3 from "../assets/img/blg3.jpg";
import left_ic from "../assets/img/left_ic.svg";
import right_ic from "../assets/img/right_ic.svg";
import search_ic2 from "../assets/img/search_ic2.svg";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Link } from "react-router-dom";
import RenderLoader from "../Components/RenderLoader";
import ReactPaginate from 'react-paginate';

const Blog = () => {
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [blogList, setBlogList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [totalProduct, setTotalProduct] = useState(1);



  //--fetch Blogs list----//
  const fetchBlogsListing = async (limit, page, search, category) => {
    let postObj = {
      page: page,
      limit: limit,
      search: search,
      category: category
    }
    setLoader(true);
    await blogListingApi(postObj).then(async response => {
      if (response.status) {
        setBlogList(response?.data);
        setTotalProduct(response?.total);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  //--fetch category list----//
  const fetchCategoryListing = async () => {
    setLoader(true);
    await getCategoryApi().then(async response => {
      if (response.status) {
        setCategoryList(response?.data);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }
  const handleSearch = () => {
    fetchBlogsListing(9, 1, search, category);
  }
  const handleCategory = (cate) => {
    setCategory(cate);
    fetchBlogsListing(9, 1, search, cate);
  }

  useEffect(() => {
    window.document.title="Blogs : Maids & Nanny";
    window.scroll(0, 0);
    fetchCategoryListing();
    fetchBlogsListing(9, 1, search, category);
  }, []);
  var divisor = (totalProduct / 9) >> 0;
  var remainder = totalProduct % 9;
  return (
    <>
      <section className="blog_Sec">
        <div className="container">
          <div className="blog_sec_in">
            <div className="blog_itms">
              <div className="row">

                {
                  loader ?
                    <>
                      <RenderLoader trans={true} />
                      {/* <Skeleton count={5} height={30} highlightColor="#D3D3D3" enableAnimation="true" /> */}
                    </>
                    :
                    <>
                    </>
                }
                {blogList?.length > 0 ?
                  blogList?.map((item, index) =>

                    <div className="col-md-4 col-sm-6" key={index}>
                      <div className="blog_itm">
                        <div className="blog_itm_img">
                          <img src={process.env.REACT_APP_URL + "/" + item?.image} alt="" />
                        </div>
                        <div className="blog_itm_con">
                          <h4>{item?.title}</h4>
                          <p dangerouslySetInnerHTML={{ __html: item?.description ? item?.description.substring(0, 100) : '' }}></p>
                          <Link to={"/blogdetails/" + item?._id} className="more_btn">
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>

                  ) :
                  <div className="col-md-12 col-sm-6">
                    <div className="blog_itm">
                      <div className="blog_itm_img">
                        {/* <img src={blg2} alt="" /> */}
                      </div>
                      <div className="blog_itm_con">
                        <h4>Unlock the Power of Knowledge: Stay Tuned for Our Upcoming Blogs......</h4>

                      </div>
                    </div>
                  </div>
                }

                <div className="pagination_sec">
                  {
                    totalProduct > 9 ?
                      <ReactPaginate
                        // previousLabel={"Prev"}
                        previousLabel={<img src={left_ic} />}
                        // nextLabel={"Next"}
                        nextLabel={<img src={right_ic} />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={remainder >= 1 ? divisor + 1 : divisor}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={async (e) => { setLoader(true); setPaginationIndex(e.selected + 1); fetchBlogsListing(9, e.selected + 1, search, category) }}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active pp"} />
                      :
                      null
                  }

                </div>


              </div>
            </div>
            <div className="blog_detail_form">
              <div className="blog_detail_form_in">
                {/* <h4>Search</h4> */}
                <div className="blog_search">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="Search for relevant topics…"
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                  <button type="button" className="blog_search_ic" onClick={handleSearch}>
                    <img src={search_ic2} alt="" />
                  </button>
                </div>
                <div className="blog_categories">
                  <h4>Categories</h4>
                  <ul className="blog_categories_itms">
                    <li className="blog_categories_itm">
                      <Link className={category == "" ? 'pp active' : 'pp'} to="#" onClick={() => { handleCategory("") }}>All</Link>
                    </li>
                    {categoryList?.length > 0 ?
                      categoryList?.map((item, index) =>

                        <li className="blog_categories_itm" key={index}>
                          <Link className={category == item?._id ? 'pp active' : 'pp'} to="#" onClick={() => { handleCategory(item?._id) }}>{item?.name}</Link>
                        </li>
                      ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default Blog;
