import React, { useState, useEffect, useContext } from "react";
import { getServiceApi, otherActionApi, getCategoryApi } from './Action';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { otherAction } from "../Utils/ValidatianSchema";
import RenderLoader from "../Components/RenderLoader";
import { toast } from "react-hot-toast";
import ContactUsSection from "./Home/ContactUsSection";
import { InputValidationContext } from "../Pages/Context/InputValidationContext";
import MobileField from "./CustomMobile";

const OtherAction = () => {

  const { handleAlphabetKeyPress, handleNumberKeyPress, handleAlphaNumKeyPress } =
    useContext(InputValidationContext);
  const [loader, setLoader] = useState(false);
  const [otherActionText, setOtherActionText] = useState({});
  const [jobCategoryList, setJobCategoryList] = useState([]);
  const [otherService, setOtherService] = useState(false);


  const serviceApi = async () => {
    await getServiceApi('other-action').then(response => {
      if (response.status) {
        setOtherActionText(response.data);
        setLoader(false);
      } else {
        setLoader(false)
      }
    })
  };

  const categoryList = async () => {
    setLoader(true);
    await getCategoryApi().then(async response => {
      if (response.status) {
        setJobCategoryList(response?.data);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  }

  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true);
    await otherActionApi(values).then(response => {
      if (response.status) {
        resetForm();
        toast.success("Your request sent successfully.");
        setLoader(false);
      } else {
        toast.error(response.message);
        setLoader(false)
      }
    })
    // console.log(values);
  };
  const handleOtherService = (service) => {
    if (service == "addOther") {
      setOtherService(true);
    } else {
      setOtherService(false);
    }
  }

  useEffect(() => {
    window.document.title = "Other Service : Maids & Nanny";
    window.scroll(0, 0);
    serviceApi();
    categoryList();
  }, []);

  return (
    <>
      <div className="main_sec">
        <section className="contact_sec">
          <div className="container">
            <div className="contact_sec_in">
              <div className="row">
                {
                  loader ?
                    <RenderLoader trans={true} />
                    : null
                }
                <div className="col-lg-6">
                  <div className="contact_sec_form">
                    <div className="heading_sec2">
                      <h3>{otherActionText?.title}</h3>
                      <p dangerouslySetInnerHTML={{ __html: otherActionText?.description }}></p>
                    </div>


                    <Formik
                      initialValues={{
                        first_name: "",
                        last_name: "",
                        mobile: "",
                        email: "",
                        other: "",
                        new_service: "",
                        message: "",
                      }}
                      validationSchema={otherAction}
                      onSubmit={handleSubmit}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="first_name">First Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="last_name">Last Name <red>*</red></label>
                                <Field
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="mobile">Mobile Number <red>*</red></label>
                                <Field
                                  component={MobileField}
                                  name="mobile"
                                  type="tel"
                                  required="true"
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="email">Email Address <red>*</red></label>
                                <Field
                                  type="text"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email Address"
                                  autoComplete="off"
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="other">Other Service <red>*</red></label>
                                <Field
                                  as="select"
                                  name="other"
                                  className="form-control"
                                  onChange={(e) => { handleChange(e), handleOtherService(e.target.value) }}
                                >
                                  <option value="">Select option</option>
                                  <option value="Electrician">Electrician</option>
                                  <option value="Plumbing">Plumbing</option>
                                  <option value="Carpentering">Carpentering</option>
                                  <option value="Painting,house decor">Painting & house decor</option>
                                  <option value="Fitting & Fixtures">Fitting & Fixtures</option>
                                  <option value="House, Lawn Carpeting & plantation">House, Lawn Carpeting & plantation</option>
                                  <option value="Other hourly work">Other hourly work</option>
                                  <option value="addOther">Others - Kindly mention your requirements below</option>

                                  {/* {jobCategoryList?.length > 0 ?
                                    jobCategoryList?.map((item, index) =>
                                      <option value={item?._id} key={index}>{item?.name}</option>
                                    )
                                    : ""} */}
                                </Field>
                                <ErrorMessage
                                  name="other"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            {otherService ?
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="new_service">Kindly mention your requirements here <red>*</red></label>
                                  <Field
                                    type="text"
                                    name="new_service"
                                    className="form-control"
                                    placeholder="Enter service here"
                                    autoComplete="off"
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="new_service"
                                    component="div"
                                    className="error"
                                  />
                                </div>
                              </div>
                              : ''}

                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="message">Your Message <red>*</red></label>
                                <Field
                                  as="textarea"
                                  name="message"
                                  className="form-control"
                                  rows="4"
                                  placeholder="Tell us about you"
                                  autoComplete="off"
                                  onKeyDown={handleAlphabetKeyPress}
                                />
                                <ErrorMessage
                                  name="message"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group mb-0">
                                <button type="submit" className="btn btn-type1">

                                  Send Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>

                  </div>
                </div>
                <ContactUsSection />
              </div>
            </div>
          </div>
        </section >
      </div >
    </>
  );
};

export default OtherAction;
