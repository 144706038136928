import { useField } from "formik";
import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function MobileField(props) {
    const editorRef = useRef(null);
    const [field, state, { setValue, setTouched }] = useField(props.field.name);


    return (
        <Editor
            {...props}
            value={state?.value}
            className='editorStyle'
            outputFormat='text'
            onInit={(evt, editor) => editorRef.current = editor}
            onEditorChange={(evt, editor) => {
                setValue(editorRef.current.getContent())
            }}
            apiKey={process.env.REACT_APP_TINMCY_KEY}
            init={
                {
                    width: '100%',
                    height: 200,
                    skin: "naked",
                    menubar: false,
                    selector: "textarea",
                    resize: 'both',
                    branding: false,
                    draggable_modal: false,
                    statusbar: false,
                    theme: 'silver',
                    draggable_modal: false,
                    toolbar_mode: 'floating',
                    toolbar_location: 'bottom',
                    placeholder: 'Enter your other requirments here.',
                    plugins: [],
                    toolbar: 'fontsizeselect fontDecoration alignment forecolor emoticons',
                    toolbar_groups: {
                        fontDecoration: {
                            icon: 'bold',
                            tooltip: 'Font Decoration',
                            items: 'bold italic underline'
                        },
                        alignment: {
                            icon: 'align-left',
                            tooltip: 'Alignment | Line Height',
                            items: 'alignleft aligncenter alignright alignjustify lineheight'
                        }
                    },
                    textcolor_map: [
                        '#000000', 'Black',
                        '#FF0000', 'red',
                        '#008000', 'green',
                        '#0000FF', 'blue',
                        '#FC0FC0', 'pink',
                        '#B200ED', 'violet',
                        '#FF0090', 'magenta',
                        '#2E8B57', 'sea',
                        '#FCA3B7', 'flamingo',
                        '#2B1700', 'chocolate',
                        '#A020F0', 'purple',
                        '#FC6600', 'orange',
                        '#FF6347', 'tomato'
                    ],
                    color_map: [
                        '#000000', 'Black',
                        '#FF0000', 'red',
                        '#008000', 'green',
                        '#0000FF', 'blue',
                        '#FC0FC0', 'pink',
                        '#B200ED', 'violet',
                        '#FF0090', 'magenta',
                        '#2E8B57', 'sea',
                        '#FCA3B7', 'flamingo',
                        '#2B1700', 'chocolate',
                        '#A020F0', 'purple',
                        '#FC6600', 'orange',
                        '#FF6347', 'tomato'
                    ],
                    custom_colors: true,
                    fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                    lineheight_formats: '1.1 1.2 1.3 1.4 1.5 2',
                }}
        />
    );
}
